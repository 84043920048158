import { BusLogicCore } from "../../buslogic/BusLogicCore";
import { Logger } from "../../utils/Logger";
import { I_ClientServerSignalingProvider } from "./I_ClientServerSignalingProvider";
import { I_MessageListenerFunctionClientServerMessage } from "./I_MessageListenerFunctionClientServerMessage";
import { I_RoomSignalingInterface } from "../../buslogic/meetingRoom/I_RoomSignalingInterface";
import { I_IncomingRoomMessageHandler } from "../../buslogic/meetingRoom/I_IncomingRoomMessageHandler";
import { RoomVisitorRegistrationMessage } from "../../buslogic/meetingRoom/messages/RoomVisitorRegistrationMessage";
import { RoomOwnerRegistrationMessage } from "../../buslogic/meetingRoom/messages/RoomOwnerRegistrationMessage";
import { RoomUnregisterUserMessage } from "../../buslogic/meetingRoom/messages/RoomUnregisterUserMessage";
import { RoomUnregisterUserFromAllRoomsMessage } from "../../buslogic/meetingRoom/messages/RoomUnregisterUserFromAllRoomsMessage";
import { RoomClosureRequestMessage } from "../../buslogic/meetingRoom/messages/RoomClosureRequestMessage";
import { RoomRemoveVisitorRequestMessage } from "../../buslogic/meetingRoom/messages/RoomRemoveVisitorRequestMessage";
import { RoomVisitorRegistrationConfirmationByServerMessage } from "../../buslogic/meetingRoom/messages/RoomVisitorRegistrationConfirmationByServerMessage";
import { RoomOwnerRegistrationConfirmationByServerMessage } from "../../buslogic/meetingRoom/messages/RoomOwnerRegistrationConfirmationByServerMessage";
import { RoomHandlingRejectionByServerMessageNotAnOwner } from "../../buslogic/meetingRoom/messages/RoomHandlingRejectionByServerMessageNotAnOwner";
import { RoomHandlingRejectionByServerMessageNotAVisitor } from "../../buslogic/meetingRoom/messages/RoomHandlingRejectionByServerMessageNotAVisitor";
import { RoomHandlingRejectionByServerMessagePeerIsNotAVisitor } from "../../buslogic/meetingRoom/messages/RoomHandlingRejectionByServerMessagePeerIsNotAVisitor";
import { RoomYouWereKickedMessage } from "../../buslogic/meetingRoom/messages/RoomYouWereKickedMessage";
import { RoomClosureConfirmationMessage } from "../../buslogic/meetingRoom/messages/RoomClosureConfirmationMessage";
import { RoomPleaseOpenVCMessage } from "../../buslogic/meetingRoom/messages/RoomPleaseOpenVCMessage";
import { RoomPleaseAddPeerToVCIfHostMessage } from "../../buslogic/meetingRoom/messages/RoomPleaseAddPeerToVCIfHostMessage";

export class SignalingRoom implements I_RoomSignalingInterface {
  private logger: Logger;
  private signaling: I_ClientServerSignalingProvider;
  private busLogic: BusLogicCore;

  private roomMessageHandler: I_IncomingRoomMessageHandler;

  constructor(_logger: Logger) {
    this.logger = _logger;
  }

  initialize = (_signaling: I_ClientServerSignalingProvider) => {
    this.signaling = _signaling;

    this.signaling.registerClientServerMessageListener(
      "roomVisitorRegistrationConfirmed",
      this.processRoomMessageVisitorRegistrationConfirmed
    );
    this.signaling.registerClientServerMessageListener(
      "roomOwnerRegistrationConfirmed",
      this.processRoomMessageOwnerRegistrationConfirmed
    );
    this.signaling.registerClientServerMessageListener(
      "noOwnerAccessToRoom",
      this.processRoomMessageServerRejectionNotAnOwnwer
    );
    this.signaling.registerClientServerMessageListener(
      "noVisitorAccessToRoom",
      this.processRoomMessageServerRejectionNotAVisitor
    );
    this.signaling.registerClientServerMessageListener(
      "visitorNotInRoom",
      this.processRoomMessageServerRejectionPeerIsNotAVisitor
    );

    this.signaling.registerClientServerMessageListener(
      "meetingRoomClosed",
      this.processRoomMessageRoomClosed
    );
    this.signaling.registerClientServerMessageListener(
      "youWereKickedOutOfRoom",
      this.processRoomMessageYouWereKicked
    );

    this.signaling.registerClientServerMessageListener(
      "pleaseOpenVC",
      this.processRoomMessagePleaseOpenVC
    );
    this.signaling.registerClientServerMessageListener(
      "pleaseAddPersonToVCIfYouAreHost",
      this.processRoomMessagePleaseAddToVCIfHost
    );
  };

  setBusLogicReference(_busLogic: BusLogicCore) {
    this.busLogic = _busLogic;
  }

  //RoomMgr instance registers itself as recipient of room-related messages
  registerIncomingMessageHandler = (
    queueHandler: I_IncomingRoomMessageHandler
  ): void => {
    this.roomMessageHandler = queueHandler;
  };

  requestToServerToJoinRoomAsVisitor = (
    joinRequestMsg: RoomVisitorRegistrationMessage
  ): void => {
    this.signaling.sendClientServerMessage("registerAsRoomVisitor", {
      roomID: joinRequestMsg.getRoomIDHash(),
      sessionPass: joinRequestMsg.getSessionPass(),
    });
  };

  requestToServerToJoinRoomAsOwner = (
    joinRequestMsg: RoomOwnerRegistrationMessage
  ): void => {
    this.signaling.sendClientServerMessage("registerAsRoomOwner", {
      roomName: joinRequestMsg.getRoomIDClearText(),
    });
  };

  requestToServerToCloseRoom = (
    closeRequestMsg: RoomClosureRequestMessage
  ): void => {
    this.signaling.sendClientServerMessage("closeMeetingRoom", {
      roomID: closeRequestMsg.getRoomIDHash(),
    });
  };

  requestToServerToLeaveRoom = (
    unregisterRequestMsg: RoomUnregisterUserMessage
  ): void => {
    this.signaling.sendClientServerMessage("leaveRoom", {
      roomID: unregisterRequestMsg.getRoomIDHash(),
    });
  };

  requestToServerToLeaveAllRooms = (
    unregisterRequestMsg: RoomUnregisterUserFromAllRoomsMessage
  ): void => {
    this.signaling.sendClientServerMessage("leaveAllRooms", {});
  };

  requestToServerToRemoveVisitorFromRoom = (
    kickRequestMsg: RoomRemoveVisitorRequestMessage
  ): void => {
    this.signaling.sendClientServerMessage("removeVisitorFromRoom", {
      roomID: kickRequestMsg.getRoomIDHash(),
      visitorUID: kickRequestMsg.getVisitorUID(),
    });
  };

  private processRoomMessageVisitorRegistrationConfirmed: I_MessageListenerFunctionClientServerMessage =
    (messageAttributes: any): void => {
      try {
        this.roomMessageHandler.handleIncomingVisitorRegistrationConfirmationMessage(
          new RoomVisitorRegistrationConfirmationByServerMessage(
            messageAttributes.roomID
          )
        );
      } catch (error) {
        this.logger.logError([
          "Error processing incoming room message - visitor registration confirmed",
          error.toString(),
        ]);
      }
    };

  private processRoomMessageOwnerRegistrationConfirmed: I_MessageListenerFunctionClientServerMessage =
    (messageAttributes: any): void => {
      try {
        this.roomMessageHandler.handleIncomingOwnerRegistrationConfirmationMessage(
          new RoomOwnerRegistrationConfirmationByServerMessage(
            messageAttributes.roomName,
            messageAttributes.roomID
          )
        );
      } catch (error) {
        this.logger.logError([
          "Error processing incoming room message - owner registration confirmed",
          error.toString(),
        ]);
      }
    };

  private processRoomMessageServerRejectionNotAnOwnwer: I_MessageListenerFunctionClientServerMessage =
    (messageAttributes: any): void => {
      try {
        this.roomMessageHandler.handleIncomingHandlingRejectionByServerMessageNotARoomOwner(
          new RoomHandlingRejectionByServerMessageNotAnOwner(
            messageAttributes.queueID
          )
        );
      } catch (error) {
        this.logger.logError([
          "Error processing incoming room message - server rejection no owner access",
          error.toString(),
        ]);
      }
    };

  private processRoomMessageServerRejectionNotAVisitor: I_MessageListenerFunctionClientServerMessage =
    (messageAttributes: any): void => {
      try {
        this.roomMessageHandler.handleIncomingHandlingRejectionByServerMessageNotARoomVisitor(
          new RoomHandlingRejectionByServerMessageNotAVisitor(
            messageAttributes.queueID
          )
        );
      } catch (error) {
        this.logger.logError([
          "Error processing incoming room message - server rejection not a visitor",
          error.toString(),
        ]);
      }
    };

  private processRoomMessageServerRejectionPeerIsNotAVisitor: I_MessageListenerFunctionClientServerMessage =
    (messageAttributes: any): void => {
      try {
        this.roomMessageHandler.handleIncomingHandlingRejectionByServerMessagePeerIsNotARoomVisitor(
          new RoomHandlingRejectionByServerMessagePeerIsNotAVisitor(
            messageAttributes.queueID,
            messageAttributes.visitorUID
          )
        );
      } catch (error) {
        this.logger.logError([
          "Error processing incoming room message - server rejection visitor not in room",
          error.toString(),
        ]);
      }
    };

  private processRoomMessageYouWereKicked: I_MessageListenerFunctionClientServerMessage =
    (messageAttributes: any): void => {
      try {
        this.roomMessageHandler.handleIncomingYouWereKickedFromMeetingRoomMessage(
          new RoomYouWereKickedMessage(messageAttributes.roomID)
        );
      } catch (error) {
        this.logger.logError([
          "Error processing incoming room message re you were kicked out of room",
          error.toString(),
        ]);
      }
    };

  private processRoomMessageRoomClosed: I_MessageListenerFunctionClientServerMessage =
    (messageAttributes: any): void => {
      try {
        this.roomMessageHandler.handleIncomingMeetingRoomClosureConfirmation(
          new RoomClosureConfirmationMessage(messageAttributes.roomID)
        );
      } catch (error) {
        this.logger.logError([
          "Error processing incoming room message re room closure",
          error.toString(),
        ]);
      }
    };

  private processRoomMessagePleaseOpenVC: I_MessageListenerFunctionClientServerMessage =
    (messageAttributes: any): void => {
      try {
        this.roomMessageHandler.handleIncomingPleaseOpenVCRoomMessage(
          new RoomPleaseOpenVCMessage(
            messageAttributes.roomID,
            messageAttributes.peerID
          )
        );
      } catch (error) {
        this.logger.logError([
          "Error processing incoming room message - request for local client to open VC",
          error.toString(),
        ]);
      }
    };

  private processRoomMessagePleaseAddToVCIfHost: I_MessageListenerFunctionClientServerMessage =
    (messageAttributes: any): void => {
      try {
        this.roomMessageHandler.handleIncomingPleaseAddPeerToVCIfHostRoomMessage(
          new RoomPleaseAddPeerToVCIfHostMessage(
            messageAttributes.roomID,
            messageAttributes.peerID
          )
        );
      } catch (error) {
        this.logger.logError([
          "Error processing incoming room message - request for host to add peer to VC",
          error.toString(),
        ]);
      }
    };
}
