export class RoomRemoveVisitorRequestMessage {
  private roomIDHash: string;
  private visitorUID: string;

  constructor(_roomIDHash: string, _visitorUID: string) {
    this.roomIDHash = _roomIDHash;
    this.visitorUID = _visitorUID;
  }

  initialize = (): void => {};

  getRoomIDHash = (): string => this.roomIDHash;

  getVisitorUID = (): string => this.visitorUID;
}
