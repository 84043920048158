export class RoomPleaseOpenVCMessage {
  private roomIDHash: string;
  private peerID: string;

  constructor(_roomIDHash: string, _peerID: string) {
    this.roomIDHash = _roomIDHash;
    this.peerID = _peerID;
  }

  initialize = (): void => {};

  getRoomID = (): string => this.roomIDHash;

  getPeerID = (): string => this.peerID;
}
