export class RoomHandlingRejectionByServerMessagePeerIsNotAVisitor {
  roomID: string;
  peerUID: string;

  constructor(_roomID: string, _peerUID: string) {
    this.roomID = _roomID;
    this.peerUID = _peerUID;
  }

  initialize = (): void => {};

  getRoomID = (): string => this.roomID;

  getPeerUID = (): string => this.peerUID;
}
