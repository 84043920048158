export class RoomVisitorRegistrationConfirmationByServerMessage {
  private hashedRoomID: string;

  constructor(_hashedRoomID: string) {
    this.hashedRoomID = _hashedRoomID;
  }

  initialize = (): void => {};

  getHashedRoomID = (): string => this.hashedRoomID;
}
