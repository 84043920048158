import { Socket } from "socket.io-client";

export class SocketData {
  private localSocket: Socket = null; //stores local socket as used to connect to the signaling server
  private localSocketID: string = null; //stores local socket ID as received on connecting to the signaling server - allows to check whether ID changed after a temporary loss of the server connection

  constructor() {}

  initialize = (socket: Socket) => {
    this.setLocalSocket(socket);
  };

  setLocalSocketID = (id: string): void => {
    this.localSocketID = id;
  };

  getLocalSocketID = (): string => {
    return this.localSocketID;
  };

  private setLocalSocket = (socket: Socket): void => {
    if (this.getLocalSocket() !== null) {
      this.getLocalSocket().disconnect();
    } // if for whatever reason 2nd socket is created / stored in dataMgmt_localSocket, close old one
    this.localSocket = socket;
    if (socket.id !== undefined) {
      this.setLocalSocketID(socket.id);
    }
  };

  getLocalSocket = (): Socket => {
    return this.localSocket;
  };
}
