export class RoomClosureConfirmationMessage {
  private roomIDHash: string;

  constructor(_roomIDHash: string) {
    this.roomIDHash = _roomIDHash;
  }

  initialize = (): void => {};

  getRoomIDHash = (): string => this.roomIDHash;
}
