export class Room {
  private hashedID: string;

  //private roomVisitors: Set<string>;
  //private roomOwners: Set<string>;

  constructor(_hashedID: string) {
    this.hashedID = _hashedID;
  }

  getHashedID = (): string => {
    return this.hashedID;
  };

  /*setVisitors = (visitors: Set<string>): void => {
    this.roomVisitors = visitors;
  };

  getVisitors = (): Set<string> => {
    return this.roomVisitors;
  };*/
}
