export class QueueHandlingRejectionByServerMessageNotAVisitor {
  queueID: string;
  visitorUID: string;

  constructor(_queueID: string, _visitorUID: string) {
    this.queueID = _queueID;
    this.visitorUID = _visitorUID;
  }

  initialize = (): void => {};

  getQueueID = (): string => this.queueID;

  getVisitorUID = (): string => this.visitorUID;
}
