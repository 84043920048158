export class RoomVisitorRegistrationMessage {
  private roomIDHash: string;
  private sessionPass: string;

  constructor(_roomIDHash: string, _sessionPass: string) {
    this.roomIDHash = _roomIDHash;
    this.sessionPass = _sessionPass;
  }

  initialize = (): void => {};

  getRoomIDHash = (): string => this.roomIDHash;

  getSessionPass = (): string => this.sessionPass;
}
