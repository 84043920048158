import { Config } from "../config/Config";
import { AppContextHandler } from "../infra/AppContextHandler";
import { Helpers } from "../utils/Helpers";
import { Logger } from "../utils/Logger";
import { APP_CONTEXT } from "./enums/APP_CONTEXT";
import { ROOM_CONTEXT } from "./enums/ROOM_CONTEXT";
import { Room } from "./Room";
import { SettingStorage } from "./SettingStorage";

export class RoomDataManager {
  private help: Helpers;
  private settings: SettingStorage;
  private logger: Logger;
  private context: AppContextHandler;
  private config: Config;

  //private rooms: Map<string, Room>;
  private mainRoom: Room; // contains reference to main room
  private activeMeetingRoom: Room; // only if there is dedicated meeting room, this contains a reference to the same - if no dedicated meeting room exists, the main room is returned upon requests for the activeMeetingRoom

  constructor(
    _help: Helpers,
    _context: AppContextHandler,
    _settings: SettingStorage,
    _logger: Logger,
    _config: Config
  ) {
    this.help = _help;
    this.logger = _logger;
    this.context = _context;
    this.settings = _settings;
    this.config = _config;

    //this.rooms = new Map();
    this.mainRoom = null;
    this.activeMeetingRoom = null;
  }

  initialize = (): void => {
    this.createMainRoom(this.getMainRoomName());
  };

  /*private ensureRoomExists = (
    roomID: string,
    roomContext: ROOM_CONTEXT = ROOM_CONTEXT.MAIN_ROOM
  ) => {
    let roomIDStandard = this.help.standardizeString(roomID);
    if (roomContext == ROOM_CONTEXT.MAIN_ROOM) {
      if (this.mainRoom == null || this.mainRoom.getID() != roomIDStandard) {
        this.mainRoom = new Room(roomIDStandard, roomContext);
      }
    } else if (roomContext == ROOM_CONTEXT.MEETING_ROOM) {
      if (
        (
          this.mainRoom != null &&
          this.getMainRoomID() != roomID
        ) && (
          this.activeMeetingRoom == null ||
          this.activeMeetingRoom.getID() != roomIDStandard
        )
      ) {
        //what if already in another room?
        this.activeMeetingRoom = new Room(roomIDStandard, roomContext);
      }
    }
  };*/

  getRoom = (roomContext: ROOM_CONTEXT = ROOM_CONTEXT.MAIN_ROOM): Room => {
    if (roomContext == ROOM_CONTEXT.MAIN_ROOM) return this.mainRoom;
    else if (roomContext == ROOM_CONTEXT.MEETING_ROOM)
      return this.activeMeetingRoom;
  };

  getRoomByID = (_roomID: string): Room => {
    if (this.mainRoom != null && this.mainRoom.getID() == _roomID)
      return this.mainRoom;
    else if (
      this.activeMeetingRoom != null &&
      this.activeMeetingRoom.getID() == _roomID
    )
      return this.activeMeetingRoom;
  };

  /*existsRoom = (roomID: string): boolean => this.rooms.has(roomID);

  removeRoom = (roomID: string): void => {
    this.rooms.get(roomID).resetPeerData();
    this.rooms.delete(roomID);
  };*/

  private createMainRoom = (roomID: string): void => {
    let roomIDStandard = this.help.standardizeString(roomID);
    if (this.mainRoom == null || this.mainRoom.getID() != roomIDStandard) {
      this.mainRoom = new Room(roomIDStandard /*, ROOM_CONTEXT.MAIN_ROOM*/);
    }
  };

  getMainRoom = (): Room => {
    return this.mainRoom;
  };

  getMainRoomID = (): string => {
    return this.getMainRoom().getID();
  };

  /*private createMeetingRoom = (roomID: string): void => {
    this.ensureRoomExists(roomID, ROOM_CONTEXT.MEETING_ROOM);
  };*/

  setActiveMeetingRoom = (roomID: string): void => {
    let roomIDStandard = this.help.standardizeString(roomID);
    if (
      this.mainRoom != null &&
      this.getMainRoomID() != roomID &&
      (this.activeMeetingRoom == null ||
        this.activeMeetingRoom.getID() != roomIDStandard)
    ) {
      //what if already in another room?
      this.activeMeetingRoom = new Room(
        roomIDStandard /*, ROOM_CONTEXT.MEETING_ROOM*/
      );
    }
  };

  resetActiveMeetingRoom = (): void => {
    this.activeMeetingRoom = null;
  };

  resetMainRoom = (): void => {
    this.mainRoom = null;
  };

  getActiveMeetingRoom = (): Room => {
    if (this.activeMeetingRoom != null) return this.activeMeetingRoom;
    else return this.getMainRoom();
  };

  getActiveMeetingRoomID = (): string => {
    return this.getActiveMeetingRoom().getID();
  };

  dedicatedMeetingRoomIsRegistered = (): boolean => {
    return this.activeMeetingRoom != null;
  };

  fullResetOfConnectedPeers = (): void => {
    if (this.mainRoom != null) this.mainRoom.resetPeerData();
    if (this.activeMeetingRoom != null) this.activeMeetingRoom.resetPeerData();
    this.mainRoom = null;
    this.activeMeetingRoom = null;
    this.initialize();
  };

  /*registerPeerThatSentReadyToConnectOrWelcomeSignalOrBCOfferInRoom = (
    _peerID: string,
    roomContext: ROOM_CONTEXT = ROOM_CONTEXT.MAIN_ROOM
  ): void => {
    this.getRoom(roomContext).registerPeerInRoom(_peerID);
  };*/

  registerPeerInRoom = (_peerID: string, _roomID: string): void => {
    if (this.getMainRoomID() == _roomID)
      this.getMainRoom().registerPeerInRoom(_peerID);
    else if (this.getActiveMeetingRoomID() == _roomID)
      this.getActiveMeetingRoom().registerPeerInRoom(_peerID);
  };

  /*unregisterPeerFromRoom = (_peerID: string, _roomID: string) : void => {
    if (this.getMainRoomID() == _roomID)
      this.getMainRoom().removePeer(_peerID);
    else if (this.getActiveMeetingRoomID() == _roomID)
      this.getActiveMeetingRoom().removePeer(_peerID);
  }*/

  unregisterPeerFromActiveMeetingRoomUnlessItsMainRoom = (
    _peerID: string
  ): void => {
    if (this.mainRoom == null || this.activeMeetingRoom == null) return;
    this.getActiveMeetingRoom().removePeer(_peerID);
  };

  unregisterPeerFromAllRooms = (_peerID: string): void => {
    this.getMainRoom().removePeer(_peerID);
    this.getActiveMeetingRoom().removePeer(_peerID);
  };

  /*unregisterPeerWhoLeftFromRoom = (
    _peerIDThatLeft: string,
    roomContext: ROOM_CONTEXT = ROOM_CONTEXT.MAIN_ROOM
  ): void => {
    this.getRoom(roomContext).removePeer(_peerIDThatLeft);
  };*/

  /*peerIsRegisteredInRoom = (
    _peerID: string,
    roomContext: ROOM_CONTEXT = ROOM_CONTEXT.MAIN_ROOM
  ): boolean => {
    return this.getRoom(roomContext).peerIsInRoom(_peerID);
  };*/

  peerIsRegisteredInAtLeastOneRoom = (_peerID: string): boolean => {
    if (this.mainRoom != null && this.mainRoom.peerIsInRoom(_peerID))
      return true;
    if (
      this.activeMeetingRoom != null &&
      this.activeMeetingRoom.peerIsInRoom(_peerID)
    )
      return true;
    return false;
  };

  /*getRegisteredPeersFromRoom = (
    roomContext: ROOM_CONTEXT = ROOM_CONTEXT.MAIN_ROOM
  ): Array<string> => {
    return Array.from(this.getRoom(roomContext).getPeersInRoom());
  };*/

  getRegisteredPeersFromAllRooms = (): Array<string> => {
    let peers: Set<string> = new Set();
    if (this.mainRoom != null) peers = this.mainRoom.getPeersInRoom();
    if (this.activeMeetingRoom != null)
      peers = new Set<string>([
        ...peers,
        ...this.activeMeetingRoom.getPeersInRoom(),
      ]);
    //console.warn("retrieving peers from all rooms - return value is: " + Array.from(peers));
    return Array.from(peers);
  };

  getRegisteredPeersFromActiveMeetingRoom = (): Array<string> => {
    let peers: Set<string> = new Set();
    if (this.getActiveMeetingRoom() != null)
      peers = this.getActiveMeetingRoom().getPeersInRoom();
    console.warn(
      "retrieving peers from active meeting room - return value is: " +
        Array.from(peers)
    );
    return Array.from(peers);
  };

  getValidRoomForPeer = (peerID: string): string => {
    if (this.mainRoom != null && this.mainRoom.peerIsInRoom(peerID))
      return this.getMainRoomID();
    else if (
      this.activeMeetingRoom != null &&
      this.activeMeetingRoom.peerIsInRoom(peerID)
    )
      return this.getActiveMeetingRoomID();
    else return null;
  };

  private getMainRoomName = (): string => {
    this.logger.logLocal(["Determining room to be used by local client ..."]);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const locallyStoredRoom = this.settings.getMainRoom();
    let defaultRoomName = this.config.getDefaultRoomName();

    if (
      this.context.getCurrentContext() ==
      APP_CONTEXT.WEB_MEETING_CLIENT_VIA_BROWSER_OKAY
    ) {
      //web meetings must have mandatory url parameter 'meetid'
      if (urlParams.has("meetid")) {
        //const room_para = urlParams.get("meetid");
        //this.logger.logLocal(["Using meetid URL parameter"]);
        this.logger.logLocal(["Using random room name for web meet upser"]);
        let randRoomNameForWebMeetUser =
          "randombaseroom_" + Math.random() * 100000000000000000008632;
        return randRoomNameForWebMeetUser;
        //return room_para;
      } else {
        //TODO: error - must not happen / needs to show error message on UI via CSS adjustments
        this.logger.logError([
          "Meeting web client accessed without meetid parameter",
        ]);
        return null;
      }
    } else if (
      this.context.getCurrentContext() ==
        APP_CONTEXT.WEB_GO_CLIENT_VIA_BROWSER_OKAY &&
      !this.settings.existsWebClientAutoLoginFlag()
    ) {
      //web meetings must have mandatory url parameter 'room' - unless using auto login
      if (urlParams.has("room")) {
        let roomReturnValue = urlParams.get("room");
        this.logger.logLocal(["Using room URL parameter"]);
        if (urlParams.has("sizing")) {
          let passPara: string = urlParams.get("sizing");
          let saltyDelta: number = 7 * 81761087;
          let defaultPassPhrase: string = "webgo-teamRoom.html";
          if (
            "" + passPara !=
            "" +
              (this.help.hash(this.help.standardizeString(defaultPassPhrase)) +
                saltyDelta)
          ) {
            //only if pass phrase is not default placeholder
            let passNumber: number =
              (passPara as unknown as number) - saltyDelta;
            roomReturnValue = roomReturnValue + "____" + passNumber;
            this.logger.logLocal(["applying provided room pass"]);
          }
        }
        return roomReturnValue;
      } else {
        //error - must not happen / needs to show error message on UI via CSS adjustments
        this.logger.logError(["webGO client accessed without room parameter"]);
        return null;
      }
    } else if (locallyStoredRoom != null && locallyStoredRoom != "") {
      //if user stored room on local client, choose this one
      let roomReturnValue: string = locallyStoredRoom;
      if (
        this.settings.existsMainRoomPass() &&
        this.settings.getMainRoomPass() != ""
      ) {
        let pwHash = this.settings.getMainRoomPass();
        roomReturnValue = roomReturnValue + "____" + pwHash;
      }
      this.logger.logLocal([
        "Using locally stored room - ignoring URL parameters if any",
      ]);
      return roomReturnValue;
    } else if (urlParams.has("room")) {
      // if no room stored by user, use room parameter from URL if any
      const room_para = urlParams.get("room");
      this.logger.logLocal(["Using URL room parameter"]);
      return room_para;
    } else {
      // else, use default room
      this.logger.logLocal(["Using default room as no other input provided"]);
      return defaultRoomName;
    }
  };
}
