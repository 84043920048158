export class QueueVisitorRegistrationMessage {
  private queueIDHash: string;
  private userLabel: string;

  constructor(_queueIDHash: string, _userLabel: string) {
    this.queueIDHash = _queueIDHash;
    this.userLabel = _userLabel;
  }

  initialize = (): void => {};

  getQueueIDHash = (): string => this.queueIDHash;

  getUserLabel = (): string => this.userLabel;
}
