export class Queue {
  private hashedID: string;

  private queuedVisitors: Set<string>;
  private queuedVisitorsTime: Map<string, number>;

  constructor(_hashedID: string) {
    this.hashedID = _hashedID;
  }

  getHashedID = (): string => {
    return this.hashedID;
  };

  setQueuedVisitors = (visitors: Set<string>): void => {
    this.queuedVisitors = visitors;
  };

  setQueuedVisitorsTime = (visitorsTime: Map<string, number>): void => {
    this.queuedVisitorsTime = visitorsTime;
  };

  getQueuedVisitors = (): Set<string> => {
    return this.queuedVisitors;
  };

  getQueuedVisitorsJoinTime = (): Map<string, number> => {
    return this.queuedVisitorsTime;
  };
}
