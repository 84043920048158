export class QueueVisitorInfoToOwnerMessage {
  private hashedQueueID: string;
  private visitors: Set<string>;
  private visitorsUserLabels: Map<string, string>;
  private visitorsJoinTime: Map<string, number>;

  constructor(
    _hashedID: string,
    _visitors: Set<string>,
    _visitorsUserLabels: Map<string, string>,
    _visitorsJoinTime: Map<string, number>
  ) {
    this.hashedQueueID = _hashedID;
    this.visitors = _visitors;
    this.visitorsUserLabels = _visitorsUserLabels;
    this.visitorsJoinTime = _visitorsJoinTime;
  }

  initialize = (): void => {};

  getHashedQueueID = (): string => this.hashedQueueID;

  getVisitors = (): Set<string> => this.visitors;

  getVisitorsUserLabels = (): Map<string, string> => this.visitorsUserLabels;

  getVisitorsJoinTime = (): Map<string, number> => this.visitorsJoinTime;
}
