export class RoomHandlingRejectionByServerMessageNotAnOwner {
  roomID: string;

  constructor(_roomID: string) {
    this.roomID = _roomID;
  }

  initialize = (): void => {};

  getRoomID = (): string => this.roomID;
}
