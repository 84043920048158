export class MobileWakeLock {
  private lockMechanism = null;

  private isWakeLockEnabled : boolean = false; //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! DISABLED ?

  constructor() {}

  initialize = (): void => {
    this.preventScreenSleep();
  };

  preventScreenSleep = (): boolean => {
    if (!this.isWakeLockEnabled) return false;
    //@ts-ignore
    if (navigator.wakeLock && this.lockMechanism == null) {
      //@ts-ignore
      navigator.wakeLock.request("screen").then((lock) => {
        this.lockMechanism = lock;
        console.log("Deactivated screen sleep on inactivity on mobile devices");
      });
      return true;
    } else return false;
  };

  enableScreenSleep = (): boolean => {
    if (!this.isWakeLockEnabled) return false;
    //@ts-ignore
    if (this.lockMechanism != null) {
      //@ts-ignore
      this.lockMechanism.release();
      this.lockMechanism = null;
      console.log("Reactivated screen sleep on inactivity on mobile devices");
      return true;
    } else return false;
  };
}
