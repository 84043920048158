export class QueueVisitorInfoRequestMessage {
  private queueIDHash: string;

  constructor(_queueIDHash: string) {
    this.queueIDHash = _queueIDHash;
  }

  initialize = (): void => {};

  getQueueIDHash = (): string => this.queueIDHash;
}
