import { Logger } from "../../utils/Logger";
import { UIHandler } from "../../frontend/UIHandler";
import { SoundEffects } from "../../media/SoundEffects";
import { SignalingCore } from "../../signaling/SignalingCore";
import { UIMessenger } from "../../utils/UIMessenger";
import { I_IncomingRemoteMuteMessageHandler } from "./I_IncomingRemoteMuteMessageHandler";
import { LocalMuteStateInfoMessage } from "./LocalMuteStateInfoMessage";
import { RemoteMuteRequestMessage } from "./RemoteMuteRequestMessage";
import { ROOM_CONTEXT } from "data/enums/ROOM_CONTEXT";

export class RemoteMute implements I_IncomingRemoteMuteMessageHandler {
  private logger: Logger;
  private signaling: SignalingCore;
  private uiMessenger: UIMessenger;
  private uiHandler: UIHandler;
  private sound: SoundEffects;

  private mutedPeers: Set<string>;

  private allowPeersToMuteLocalAudio: boolean;

  constructor(
    _logger: Logger,
    _signaling: SignalingCore,
    _uiMessenger: UIMessenger,
    _uiHandler: UIHandler,
    _sound: SoundEffects
  ) {
    this.logger = _logger;
    this.signaling = _signaling;
    this.uiMessenger = _uiMessenger;
    this.uiHandler = _uiHandler;
    this.sound = _sound;

    this.allowPeersToMuteLocalAudio = true;
  }

  initialize = () => {
    this.mutedPeers = new Set<string>();
    this.signaling
      .getRemoteMuteSignaling()
      .registerIncomingMessageHandler(this);
  };

  requestPeerToBeMuted = (peerID: string): void => {
    this.signaling
      .getRemoteMuteSignaling()
      .shareRemoteMuteRequestWithPeer(
        new RemoteMuteRequestMessage(this.signaling.getUniqueClientID(), peerID)
      );
    this.uiMessenger.informUser("trying to mute your teammate's microphone");
  };

  informPeersAboutLocalMuteStateChange = (newMuteState: boolean): void => {
    //TODO: should inform only peers in VC
    this.signaling
      .getRooms()
      .getRegisteredPeersFromAllRooms()
      .forEach((peerID) => {
        this.informSinglePeerAboutLocalMuteStateChange(peerID, newMuteState);
      });
  };

  informSinglePeerAboutLocalMuteStateChange = (
    peerID: string,
    newMuteState: boolean
  ): void => {
    let peersRoom: string = this.signaling
      .getRooms()
      .getValidRoomForPeer(peerID);
    if (peersRoom != null)
      this.signaling
        .getRemoteMuteSignaling()
        .shareLocalMuteStateInfoWithPeer(
          new LocalMuteStateInfoMessage(
            this.signaling.getUniqueClientID(),
            peerID,
            newMuteState
          ),
          peersRoom
        );
    //this.setRaiseHandStatusForPeerOnUI(newMuteState, handToBeRaised);
  };

  handleIncomingRemoteMuteRequestMessage = (
    incomingMsg: RemoteMuteRequestMessage
  ): void => {
    if (
      !this.signaling
        .getRooms()
        .getRegisteredPeersFromActiveMeetingRoom()
        .includes(incomingMsg.getSenderID())
    ) {
      this.logger.logError([
        "Remote client requested local client to mute audio, but client is not a connected peer: ",
        incomingMsg.getSenderID(),
      ]);
    } else if (
      !(this.signaling.getUniqueClientID() == incomingMsg.getRecipientID())
    ) {
      this.logger.logError([
        "Local client received requested to mute audio, but request addressed to wrong peerID - sender/recipient is: ",
        incomingMsg.getSenderID(),
        "/",
        incomingMsg.getRecipientID(),
      ]);
    } else {
      if (
        this.allowPeersToMuteLocalAudio &&
        this.uiHandler.isLocalAudioActive()
      ) {
        this.uiHandler.muteLocalMicrophoneButtonClick(true);
        this.informPeersAboutLocalMuteStateChange(true);
        this.uiMessenger.warningToUserSticky(
          "Note that your microphone has just been muted by one of your teammates"
        );
        //this.sound.playRemoteMuteSound();
      } else {
        this.logger.logWarning([
          "Local client dropped request from peer to mute local audio because either muted already or remote mute has been disabled - muted/disabled is: ",
          !this.uiHandler.isLocalAudioActive(),
          "/",
          !this.allowPeersToMuteLocalAudio,
        ]);
      }
    }
  };

  handleIncomingLocalMuteStateInfoMessage = (
    incomingMsg: LocalMuteStateInfoMessage
  ): void => {
    if (
      !this.signaling
        .getRooms()
        .peerIsRegisteredInAtLeastOneRoom(incomingMsg.getSenderID())
    ) {
      this.logger.logWarning([
        "Remote client informed local client about its mute status, but client is not a connected peer: ",
        incomingMsg.getSenderID(),
      ]);
    } else if (
      !(this.signaling.getUniqueClientID() == incomingMsg.getRecipientID())
    ) {
      this.logger.logError([
        "Local client received mute info from peer, but request addressed to wrong peerID - sender/recipient is: ",
        incomingMsg.getSenderID(),
        "/",
        incomingMsg.getRecipientID(),
      ]);
    } else {
      if (incomingMsg.getSenderMuteState()) {
        this.mutedPeers.add(incomingMsg.getSenderID());
      } else {
        this.mutedPeers.delete(incomingMsg.getSenderID());
      }
      this.setMutedStatusForPeerOnUI(
        incomingMsg.getSenderID(),
        incomingMsg.getSenderMuteState()
      );
    }
  };

  private setMutedStatusForPeerOnUI = (
    peerID: string,
    mutedStatus: boolean
  ) => {
    //TODO: add mute buttons to VC UI and include them in mute remotely and mute status info logic
    this.uiHandler.markButtonsAsPressed(
      [
        this.uiHandler
          .getAppUIHTML()
          .getSideBarHTML()
          .getPeerButtonRemoteMuteAtUIPos(
            this.uiHandler
              .getAppUIHTML()
              .getSideBarHTML()
              .getUISectionForPeer(peerID)
          ),
      ],
      mutedStatus
    );
    if (
      this.uiHandler
        .getAppUIHTML()
        .getVCScreenHTML()
        .hasRegisteredPeerVSVC(peerID)
    ) {
      this.uiHandler.markButtonsAsPressed(
        [
          this.uiHandler
            .getAppUIHTML()
            .getVCScreenHTML()
            .getPeerButtonRemoteMuteAtUIPos(
              this.uiHandler
                .getAppUIHTML()
                .getVCScreenHTML()
                .getUISectionForPeer(peerID)
            ),
        ],
        mutedStatus
      );
    }
  };

  refreshMutedStatusForPeerOnUI = (peerID: string): void => {
    if (this.mutedPeers.has(peerID)) {
      this.setMutedStatusForPeerOnUI(peerID, true);
    } else {
      this.setMutedStatusForPeerOnUI(peerID, false);
    }
  };

  removeMuteSignThatHasBecomeObsolete = (peerID: string): void => {
    //suboptimal: simulate incoming message as simple workaround to no longer mark a peer as muted (e.g. when peer left)
    this.handleIncomingLocalMuteStateInfoMessage(
      new LocalMuteStateInfoMessage(
        peerID,
        this.signaling.getUniqueClientID(),
        false
      )
    );
  };

  isPeerMuted = (peerID: string): boolean => {
    return this.mutedPeers.has(peerID);
  };
}
