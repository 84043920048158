/* COPYRIGHT 2021 Michael Maur - any form of reuse requires written consent by the author */

import { BusLogicCore } from "./BusLogicCore";
import { Config } from "config/Config";
import { DataMgmt } from "data/DataMgmt";
import { SettingStorage } from "data/SettingStorage";
import { SignalingVC } from "signaling/SignalingVC";
import { SoundEffects } from "media/SoundEffects";
import { UIHandler } from "frontend/UIHandler";
import { Logger } from "utils/Logger";
import { UIMessenger } from "utils/UIMessenger";
import { SIGNALING_PURPOSE } from "data/enums/SIGNALING_PURPOSE";
import { VCDataManager } from "../data/VCDataManager";
import { Helpers } from "utils/Helpers";
import { BrowserMediaHandler } from "media/BrowserMediaHandler";
import { Chat } from "./chat/Chat";
import { AppContextHandler } from "infra/AppContextHandler";
import { MobileWakeLock } from "infra/MobileWakeLock";

export class BusLogicVC extends BusLogicCore {
  protected signaling: SignalingVC;
  private vcChat: Chat;

  constructor(
    _help: Helpers,
    _context: AppContextHandler,
    _config: Config,
    _settings: SettingStorage,
    _logger: Logger,
    _uiMessenger: UIMessenger,
    _data: DataMgmt,
    _signaling: SignalingVC,
    _sound: SoundEffects,
    _uiHandler: UIHandler,
    _wakeLocker: MobileWakeLock
  ) {
    super(
      _help,
      _context,
      _config,
      _settings,
      _logger,
      _uiMessenger,
      _data,
      _signaling,
      _sound,
      _uiHandler,
      _wakeLocker
    );
    console.log("Local client loading module: busLogicVC");

    this.vcData = new VCDataManager(
      this.logger,
      this.signaling,
      this.uiHandler,
      this
    );
    this.vcChat = new Chat(
      _signaling,
      _uiMessenger,
      _uiHandler,
      this,
      this.userNameLabel
    );
  }

  initialize = (_media: BrowserMediaHandler) => {
    this.initializeCore(_media);
    this.vcData.initialize();
    this.vcChat.initialize();
    this.roomMgr.setBusLogicReference(this);
  };

  getVCDataMgr = (): VCDataManager => {
    return this.vcData;
  };

  //////////////////////////////////////////////////////////////////
  // VC connection handling
  //////////////////////////////////////////////////////////////////

  connectViaVC = (peerID: string, targetPurpose: SIGNALING_PURPOSE) => {
    //ignore click on connect method if connection with this user is already being established
    if (this.vcData.isVCInvitePending(peerID)) {
      this.uiMessenger.informUser("Currently connecting ...");
      this.logger.logWarning([
        "local client ",
        this.signaling.getUniqueClientID(),
        " dropped a local UI click/request to add a peer ",
        peerID,
        " to VC as there is already an invite being progressed",
      ]);
      return false; //!! ends the execution of this method / no more actions in relation to clicking the button on the UI
    }

    /*/ BACKWARD COMPATIBILITY ONLY - if VC already open, check whether it requires upgrade from audio only to dedicated window
      if (this.vcData.isVCOpen() && this.vcData.getVCCurrentType() === this.data.__Purpose_AudioOnly && (targetPurpose === this.data.__Purpose_HRVideo || targetPurpose === this.data.__Purpose_MRVideo || targetPurpose === this.data.__Purpose_LRVideo)) {
        upgradeVCFromSideBarToVCUI(targetPurpose);
      }*/
    //if no VC open, open VC locally (data structures only - no interaction with peers in this first step)
    if (!this.vcData.isVCOpen()) {
      this.initiateVC(this.signaling.getUniqueClientID(), targetPurpose);
    }
    // add selected peer to VC if not part of VC yet
    if (!this.vcData.isPartOfVC(peerID)) {
      this.vcData.registerPendingVCInvite(
        peerID,
        this.signaling.getUniqueClientID()
      );
      if (this.vcData.localClientIsVCCurrentHost()) {
        /*if (!(this.data.isVCInvitePending(peerID))) {
            this.vcData.registerPendingVCInvite(peerID,this.signaling.getSocketData().getLocalSocketID());*/
        this.addPeerToExistingVC(peerID); // if local client is the VC host, add/invite new peer
        /*} else {
            this.logger.logWarning(['local host cancelled a local request to add a peer to VC as a duplicative request is already being progressed']);
            this.uiMessenger.informUser('Request dropped: ' + __rationale_AboutToJoinAVC);
            //informRequestorOfDuplicativeRequestToJoinVC121(this.signaling.getSocketData().getLocalSocketID(), peerID);
          }*/
      } else {
        this.signaling.suggestToInviteToVC121(peerID); //if local client is not the VC host, suggest to VC host to invite peer
      }
    }
  };

  upgradeVCFromSideBarToVCUI = (
    targetPurpose: SIGNALING_PURPOSE = SIGNALING_PURPOSE.HR_VIDEO
  ): void => {
    // if VC already open, check whether it requires upgrade from audio only to dedicated window
    if (
      this.vcData.isVCOpen() &&
      this.vcData.getVCCurrentType() === SIGNALING_PURPOSE.AUDIO_ONLY &&
      (targetPurpose === SIGNALING_PURPOSE.HR_VIDEO ||
        targetPurpose === SIGNALING_PURPOSE.MR_VIDEO ||
        targetPurpose === SIGNALING_PURPOSE.LR_VIDEO)
    ) {
      if (this.vcData.localClientIsVCCurrentHost()) {
        this.signaling.informParticipantsOfVCUpgradeToDedicatedWindowRequest(
          targetPurpose
        );
        this.processIncomingVCTypeChange(targetPurpose);
      } else {
        this.signaling.requestVCUpgradeFromAudioToDedicatedWindowFromVCHost121(
          targetPurpose
        );
      }
    }
  };

  downgradeVCFromVCUIToSideBar = (
    targetPurpose: SIGNALING_PURPOSE = SIGNALING_PURPOSE.AUDIO_ONLY
  ): void => {
    if (
      this.vcData.isVCOpen() &&
      targetPurpose == SIGNALING_PURPOSE.AUDIO_ONLY &&
      (this.vcData.getVCCurrentType() === SIGNALING_PURPOSE.HR_VIDEO ||
        this.vcData.getVCCurrentType() === SIGNALING_PURPOSE.MR_VIDEO ||
        this.vcData.getVCCurrentType() === SIGNALING_PURPOSE.LR_VIDEO)
    ) {
      if (this.vcData.localClientIsVCCurrentHost()) {
        this.signaling.informParticipantsOfVCDowngradeToSidebarRequest(
          targetPurpose
        );
        this.processIncomingVCTypeChange(targetPurpose);
      } else {
        this.signaling.requestVCDowngradeFromDedicatedWindowToSideBarFromVCHost121(
          targetPurpose
        );
      }
    }
  };

  initiateVC = (hostID: string, targetPurpose: SIGNALING_PURPOSE) => {
    try {
      this.logger.logLocal([
        "Local client ",
        this.signaling.getUniqueClientID(),
        " initiating VC with host ",
        hostID,
        " and targetPurpose ",
        targetPurpose,
      ]);
      //if target purpose suggests dedicated VC window, assign local stream to VC labeled video component
      if (
        this.help.appIsActive() &&
        (targetPurpose === SIGNALING_PURPOSE.HR_VIDEO ||
          targetPurpose === SIGNALING_PURPOSE.MR_VIDEO ||
          targetPurpose === SIGNALING_PURPOSE.LR_VIDEO)
      ) {
        //always show local HR stream - no point in showing lower quality locally
        this.appUI.getVCScreenHTML().getLocalHTMLVideoElementVC().srcObject =
          this.data.getLocalMSByQuality("H");
        this.uiHandler.showVCLocalVideoSectionOnUI();
      }
      this.vcChat.resetChatInstance();
      this.vcData.setVCOpen(true);
      this.vcData.setVCCurrentHost(hostID);
      this.vcData.setVCCurrentType(targetPurpose);
      this.vcData.resetPendingVCInvites();
      this.remoteMute.informPeersAboutLocalMuteStateChange(
        this.media.isLocalAudioMuted()
      );
    } catch (err) {
      console.error(
        "Failed to initiate video conference on local UI. >> error: ",
        err.toString()
      );
      this.uiMessenger.warningToUserSticky(
        "Failed to initiate video conference. Please try again. If the problem persists, reload the browser window. Error:" +
          err.toString()
      );
      this.closeVC(false); //hide VC elements on UI and reset variables
    }
  };

  processIncomingVCTypeChange = (incomingPCPurpose: SIGNALING_PURPOSE) => {
    if (
      this.vcData.isVCOpen() &&
      (this.uiHandler.VCUIIsActive() || !this.help.appIsActive()) &&
      incomingPCPurpose === SIGNALING_PURPOSE.AUDIO_ONLY
    ) {
      //VC in dedicated window to be downgraded to VC on sidebar
      this.logger.logLocal([
        "Local client ",
        this.signaling.getUniqueClientID(),
        " downgrading VC from dedicated window to sidebar",
      ]);
      //this.uiHandler.resetVCUI(); //no resetting of VCUI as this is always in use while VC is active, even if VC on sideBar - it may also be in use if remote screen share is active - thus must not be resetted only because of VC downgrade to sidebar
      this.uiHandler.hideVCUI();
      this.vcData.setVCCurrentType(incomingPCPurpose);
      this.updateAllPeerConnectionTypesInVC(incomingPCPurpose);
    } else if (
      this.vcData.isVCOpen() &&
      !this.uiHandler.VCUIIsActive() &&
      (incomingPCPurpose === SIGNALING_PURPOSE.HR_VIDEO ||
        incomingPCPurpose === SIGNALING_PURPOSE.MR_VIDEO ||
        incomingPCPurpose === SIGNALING_PURPOSE.LR_VIDEO)
    ) {
      //VC on sidebar to be upgraded to VC in dedicated window
      this.logger.logLocal([
        "Local client ",
        this.signaling.getUniqueClientID(),
        " upgrading VC from sidebar to dedicated window",
      ]);
      if (this.help.appIsActive()) {
        this.appUI.getVCScreenHTML().getLocalHTMLVideoElementVC().srcObject =
          this.data.getLocalMSByQuality("H");
        this.uiHandler.showVCLocalVideoSectionOnUI();
        this.uiHandler.showVCUI();
      }
      this.vcData.setVCCurrentType(incomingPCPurpose);
      this.updateAllPeerConnectionTypesInVC(incomingPCPurpose);
      //watch going forward: button status may need to be set according to VC status - for now, only screen share button has no status / might not be relevant here
    } else {
      console.error(
        "Failed to process incoming VC type change - unknown incoming PCPurpose for VC context: ",
        incomingPCPurpose
      );
      //warnUser('Failed to ...');
    }
  };

  updateAllPeerConnectionTypesInVC = (incomingPCPurpose: SIGNALING_PURPOSE) => {
    for (let VCPeer of this.vcData.getVCPeers()) {
      if (
        this.help.localPeerIDIsTheOneToInitiateConnection(
          this.signaling.getUniqueClientID(),
          VCPeer
        )
      ) {
        // string comparison avoids stream creation to be called by both peers - this way, the method will trigger only on one of the peers (no matter which one)
        this.establishSuitableStreams(VCPeer, incomingPCPurpose); //this causes problems if triggered by both peers in parallel as afterwards, there should be only one connection
      }
    }
  };

  switchVCToDedicatedWindow = () => {
    if (
      this.uiHandler
        .getCSSClassModifier()
        .containsClassOnEntireApp("remoteScreenShareActive") ||
      this.uiHandler
        .getCSSClassModifier()
        .containsClassOnEntireApp("localScreenShareActive")
    ) {
      this.uiMessenger.informUser(
        "Cannot open enlarged VC window while screen is shared"
      );
    } else {
      this.upgradeVCFromSideBarToVCUI(SIGNALING_PURPOSE.HR_VIDEO);
    }
  };

  switchVCToSideBar = () => {
    this.downgradeVCFromVCUIToSideBar(SIGNALING_PURPOSE.AUDIO_ONLY);
  };

  addPeerToExistingVC = (peerID) => {
    if (!this.vcData.isVCOpen()) {
      console.error(
        "Client " +
          peerID +
          " could not be added to the video conference as no active video conference was found. Try re-initiating a video conference first. Reload the browser window / try again later if the problem persists."
      );
    } else if (
      this.vcData.localClientIsVCCurrentHost() &&
      (this.vcData.isPartOfVC(peerID) ||
        peerID === this.signaling.getUniqueClientID())
    ) {
      //this catches 1) duplicate peers, 2) requests to local client to add itself as a peer, and 3) suggestions to the Host to add himself to the VC
      this.signaling.informParticipantsOfUnsuccessfulInviteVC121(
        peerID,
        this.signaling.__rationale_AlreadyInThisVC,
        false,
        this.vcData.getVCCurrentHost(),
        this.vcData.getVCPeers().length + 1
      );
      if (
        this.signaling.getUniqueClientID() ===
        this.vcData.getPendingVCInviteOriginator(peerID)
      ) {
        this.uiMessenger.informUser(
          "User is already part of the conversation - cannot join twice"
        );
      }
      this.vcData.unregisterPendingVCInvite(peerID);
    } else if (
      this.vcData.localClientIsVCCurrentHost() &&
      this.vcData.getVCPeers().length + 1 >= this.vcData.getVCMaxUserLimit()
    ) {
      this.signaling.informParticipantsOfUnsuccessfulInviteVC121(
        peerID,
        this.signaling.__rationale_VCFull,
        false,
        this.vcData.getVCCurrentHost(),
        this.vcData.getVCPeers().length + 1
      );
      if (
        this.signaling.getUniqueClientID() ===
        this.vcData.getPendingVCInviteOriginator(peerID)
      ) {
        this.uiMessenger.informUser(
          "Conversation has reached max limit of " +
            (this.vcData.getVCPeers().length + 1) +
            " users - user cannot be added"
        );
      }
      this.vcData.unregisterPendingVCInvite(peerID);
    } else {
      this.getUserNameLabel().updateUserNameLabelOnUIForPeerTimeout(peerID);
      /*this.remoteMute.informSinglePeerAboutLocalMuteStateChange(
        peerID,
        this.media.isLocalAudioMuted()
      );*/
      //if VC up and running and client not part of it yet ... add client to VC
      if (
        this.vcData.localClientIsVCCurrentHost() &&
        peerID != this.vcData.getVCCurrentHost()
      ) {
        if (!this.vcData.isPartOfVC(peerID)) {
          this.signaling.inviteToJoinVC121(peerID); //host only sends invite in the first place - new peer then adds host to local VC and thereby initiates PC connection
        } else {
          this.establishSuitableStreams(peerID, this.vcData.getVCCurrentType());
        }
      } else if (
        this.signaling.getUniqueClientID() != this.vcData.getVCCurrentHost()
      ) {
        this.establishSuitableStreams(peerID, this.vcData.getVCCurrentType());
      } //peers other then the host initiate PC when told to add new peer to their VC
      if (this.signaling.getRooms().dedicatedMeetingRoomIsRegistered()) {
        this.getUserNameLabel().informSinglePeerAboutLocalUserNameLabelTimeout(
          peerID
        );
        this.getRemoteMute().informSinglePeerAboutLocalMuteStateChange(
          peerID,
          this.media.isLocalAudioMuted()
        );
      }
    }
  };

  removePeerFromExistingVCIfOnThere(
    peerID: string,
    suppressAllPeerInteractionForSilentLocalClosureOnly: boolean = false,
    noNewConnectionAsEitherLocalOrRemotePeersDisconnecting: boolean = false
  ): void {
    if (this.vcData.isVCOpen() && this.vcData.isPartOfVC(peerID))
      this.removePeerFromExistingVC(
        peerID,
        suppressAllPeerInteractionForSilentLocalClosureOnly,
        noNewConnectionAsEitherLocalOrRemotePeersDisconnecting
      );
  }

  removePeerFromExistingVC(
    peerID: string,
    suppressAllPeerInteractionForSilentLocalClosureOnly: boolean = false,
    noNewConnectionAsEitherLocalOrRemotePeersDisconnecting: boolean = false
  ): void {
    this.logger.logLocal(["removing Peer from existing VC: ", peerID]);
    if (!this.vcData.isVCOpen()) {
      this.logger.logError([
        "Client " +
          peerID +
          " could not be removed from the video conference as no active video conference was found.",
      ]);
    } else if (!this.vcData.isPartOfVC(peerID)) {
      this.logger.logWarning([
        "Client " +
          peerID +
          " could not be removed from the video conference as it is not part of the same.",
      ]);
    } else {
      //if VC up and running and client is part of it ... remove client
      if (
        !this.data.existsPMS(peerID, SIGNALING_PURPOSE.BASE_CONNECTION) &&
        this.help.localPeerIDIsTheOneToInitiateConnection(
          this.signaling.getUniqueClientID(),
          peerID
        )
      ) {
        // string comparison avoids stream creation to be called by both peers - this way, the method will trigger only on one of the peers (no matter which one)
        if (
          !suppressAllPeerInteractionForSilentLocalClosureOnly &&
          !noNewConnectionAsEitherLocalOrRemotePeersDisconnecting &&
          this.signaling.getRooms().getMainRoom().peerIsInRoom(peerID) //no base connection to be established if meeting outside of main room / in dedicated meeting room only
        )
          this.establishSuitableStreams(
            peerID,
            SIGNALING_PURPOSE.BASE_CONNECTION
          ); //this causes problems if triggered by both peers in parallel as afterwards, there should be only one connection
      }
      this.signaling
        .getRooms()
        .unregisterPeerFromActiveMeetingRoomUnlessItsMainRoom(peerID);

      this.vcData.unregisterVCPeer(peerID);
      this.sound.playPeerLeavingSound();

      if (
        this.help.appIsActive() &&
        !this.localScreenShareIsActive() &&
        !this.remoteScreenShareIsActive()
      ) {
        this.uiHandler.updateVCWindowSizeBasedOnVCParticipantsAndChat();
        /*let requiredVCScreenSize = this.vcData.getVCPeers().length + 1;
        if (this.uiHandler.isVCChatActive()) requiredVCScreenSize++;
        this.uiHandler
          .getUINWJSHandler()
          .getVCWindowNWJS()
          .setVCWindowSizeForNumberOfParticipantsIfOnApp(
            requiredVCScreenSize,
            false
          );*/
      }

      this.appUI.markVideoSectionsAsPartOfActiveVCOnBothSidebarAndVCWindow(
        peerID,
        false
      );
      if (this.appUI.getVCScreenHTML().hasRegisteredPeerVSVC(peerID)) {
        this.uiHandler.hideVCPeerVideoSectionOnUI(peerID);
        this.appUI.getVCScreenHTML().unregisterPeerVSVC(peerID);
      }
      this.data.retireAllPCForPeerExclBC(peerID);
      this.data.retireAllPMSForPeerExclBC(peerID);
      if (
        this.help.appIsActive() &&
        !this.remoteScreenShareIsActive() &&
        this.uiHandler
          .getAppUIHTML()
          .getVCScreenShareHTML()
          .getRemoteScreenShareVSVC()
          .classList.contains("active")
      ) {
        //only if it should not be active, but is actually classed as active - hide it
        this.uiHandler.hideVCRemoteScreenShareVideoSectionOnUI();
      }
    }
    if (this.vcData.getVCPeers().length === 0) {
      this.endLocalScreenShare(
        suppressAllPeerInteractionForSilentLocalClosureOnly
      ); // ends local screen share, in case it is active
      this.resetAndCloseVCUIIfNoPeers();
      this.vcData.setVCCurrentHost(null);
      this.vcData.setVCCurrentCoHost(null);

      //reconfigure CoHost, in case leaving client held that role and current client is VC host
    } /*else if (this.vcData.localClientIsVCCurrentHost() && this.data.getVCCurrentCoHost() == peerID && this.vcData.getVCPeers().length > 0) {
        this.vcData.setVCCurrentCoHost(this.vcData.getVCPeers()[0]);
        adviseVCParticipantsOfNewHost121(this.signaling.getSocketData().getLocalSocketID(), this.vcData.getVCPeers()[0]);
        //if local client is CoHost, reconfigure host and coHost, in case the leaving client was still the host at the time of its departure (i.e. it did not reassign)
      } else if (this.data.localClientIsVCCurrentCoHost() && this.vcData.getVCCurrentHost() == peerID && this.vcData.getVCPeers().length > 0) {
        this.vcData.setVCCurrentHost(this.signaling.getSocketData().getLocalSocketID());
        this.vcData.setVCCurrentCoHost(this.vcData.getVCPeers()[0]);
        adviseVCParticipantsOfNewHost121(this.signaling.getSocketData().getLocalSocketID(), this.vcData.getVCPeers()[0]);
      }*/
  }

  leaveVC = (
    fullClosureWithoutParticipantsLeft: boolean = false,
    suppressAllPeerInteractionForSilentLocalClosureOnly: boolean = false,
    noNewConnectionAsEitherLocalOrRemotePeersDisconnecting: boolean = false,
    remainInExtMeetingRoomDespiteVCClosure: boolean = false
  ) => {
    if (this.vcData.isVCOpen()) {
      // can only leave a VC if there is an open one
      this.endLocalScreenShare(
        suppressAllPeerInteractionForSilentLocalClosureOnly
      ); // ends local screen share, in case it is active
      this.vcChat.resetChatInstance();
      if (
        this.vcData.localClientIsVCCurrentHost() &&
        !suppressAllPeerInteractionForSilentLocalClosureOnly
      ) {
        if (this.vcData.getVCPeers().length < 2) {
          this.closeVC(
            true,
            noNewConnectionAsEitherLocalOrRemotePeersDisconnecting,
            remainInExtMeetingRoomDespiteVCClosure
          );
        } else {
          //if current peer is the host and is leaving, assign host privileges to someone else
          this.signaling.adviseVCParticipantsOfNewHost121(
            this.vcData.getVCCurrentCoHost(),
            this.vcData.determineNextVCCoHost(
              this.signaling.getUniqueClientID(),
              this.vcData.getVCCurrentCoHost()
            )
          );
          this.vcData.setVCCurrentHost(this.vcData.getVCPeers()[0]);
          this.vcData.setVCCurrentCoHost(this.vcData.getVCPeers()[1]);
          this.signaling.confirmLocalClientLeftVC121(
            this.signaling.__rationale_UserChoseToLeave,
            noNewConnectionAsEitherLocalOrRemotePeersDisconnecting
          );
        }
      } else {
        if (
          !fullClosureWithoutParticipantsLeft &&
          !suppressAllPeerInteractionForSilentLocalClosureOnly
        )
          this.signaling.confirmLocalClientLeftVC121(
            this.signaling.__rationale_UserChoseToLeave
          );
      }
      if (!remainInExtMeetingRoomDespiteVCClosure) {
        this.roomMgr.leaveRoom(this.signaling.getActiveMeetingRoomID());
        this.signaling.getRooms().resetActiveMeetingRoom();
      }
      this.resetVCUIAndDataStructures(
        noNewConnectionAsEitherLocalOrRemotePeersDisconnecting
      );
    }
  };

  closeVC = (
    fullClosureWithoutParticipantsLeft: boolean = false,
    noNewConnectionAsEitherLocalOrRemotePeersDisconnecting: boolean = false,
    remainInExtMeetingRoomDespiteVCClosure: boolean = false
  ) => {
    //local client is host and chooses to shut down VC
    if (this.vcData.localClientIsVCCurrentHost()) {
      this.signaling.adviseVCParticipantsOfVCClosure121(
        noNewConnectionAsEitherLocalOrRemotePeersDisconnecting
      );
      this.endLocalScreenShare(); // ends local screen share, in case it is active
      this.resetVCUIAndDataStructures(
        noNewConnectionAsEitherLocalOrRemotePeersDisconnecting
      ); //TODO: check whether this is required - appears to be a duplicative call as already called above in leaveVC
    } else
      this.leaveVC(
        fullClosureWithoutParticipantsLeft,
        noNewConnectionAsEitherLocalOrRemotePeersDisconnecting,
        undefined,
        remainInExtMeetingRoomDespiteVCClosure
      ); //normal participants go to leaveVC which only closes own part, but informs host of departure
  };

  closeVCIfEmptyAndNothingPending = () => {
    if (
      this.vcData.isVCOpen() &&
      this.vcData.getVCPeers().length == 0 &&
      this.vcData.getConfirmedVCJoiners().length == 0 &&
      !this.vcData.isOutgoingMergeOngoing() &&
      !this.vcData.isIncomingMergeOngoing()
    ) {
      this.closeVC(true);
    }
  };

  //when trying to connect to someone who is already in a VC, try to join that VC - if possible, take your own VC with you to fully merge the two VCs into the peer's active VC
  tryToJoinPeersActiveVCOrMergeVCsIfPossible = (
    _rejectedPeer: string,
    _hostOfActiveVC: string,
    _participantCountActiveVC: number,
    _rejectionRationale: string
  ) => {
    this.logger.logLocal([
      "local client trying to join active peer VC or merge VCs if possible",
    ]);

    if (
      this.vcData.isOutgoingMergeOngoing() ||
      this.vcData.isIncomingMergeOngoingForPeer(_hostOfActiveVC)
    ) {
      //if (this.data.isJoinOrMergeInProgress()) {
      this.logger.logWarning([
        "local host rejected a requestToMergeWithOtherActiveVC due to parallel join or merge request that was registered earlier",
      ]);
      if (this.vcData.localClientIsVCCurrentHost()) {
        //todo: figure out how to inform requesting party..
      } else {
        this.uiMessenger.informUserSticky(
          "Request to join your colleague's conversation was dropped as already processing a similar request. Please try again in a few seconds."
        );
      }
      return false;
    }
    /*if (this.vcData.localClientIsVCCurrentHost() && this.data.isVCInvitePending(_hostOfActiveVC)) {
        this.logger.logWarning(['local host rejected a requestToMergeWithOtherActiveVC due to WIP request to add the other VC\'s host to local VC']);
        //informRequestorOfDuplicativeRequestToJoinVC121(peerID, _hostOfActiveVC);
        //TODO: inform requestor (if info available)
        return false;
      }*/
    //if VC host
    /*if (this.vcData.getVCPeers().length===0 //if local client is alone in a VC - would only happen if local client is host of freshly opened VC without active connection / peer could not be added
          && _participantCountActiveVC + 1 <= this.vcData.getVCMaxUserLimit()){
          //&& window.confirm('User is already in a conversation. To join that conversation, confirm with "OK".')) {
        if (this.vcData.isAuthorizedToJoinOrMergeOtherActiveVC(_rejectedPeer)) {
          //closeVC(); // close all local VC structures
          //requestAccessToActiveVC121(_hostOfActiveVC,[this.signaling.getSocketData().getLocalSocketID()]);
          initiatePreValidationAndRequestForAccessToActiveVC(_rejectedPeer,_hostOfActiveVC);
        } else {
          this.uiMessenger.warningToUserSticky('User is already in a conversation. To join that conversation, click again within 5 seconds.',5000);
          this.vcData.registerAuthorizationToJoinOrMergeOtherActiveVC(_rejectedPeer);
        }
      } else if (this.vcData.getVCPeers().length===1 //if there are only 2 clients in VC - no matter whether local client is host or not, closing connection will close VC / allow for it to be merged
          && _participantCountActiveVC + 2 <= this.vcData.getVCMaxUserLimit()){ 
          //&& window.confirm('User is already in a conversation. To join that conversation together with everyone on your call, confirm with "OK".')) {
        if (this.vcData.isAuthorizedToJoinOrMergeOtherActiveVC(_rejectedPeer)) {
          //let clientsInMyVC = this.vcData.getVCPeers().concat([this.signaling.getSocketData().getLocalSocketID()]);
          //closeVC(); // leave VC, implicitly closing it as user count = 2
          //requestAccessToActiveVC121(_hostOfActiveVC, clientsInMyVC);
          initiatePreValidationAndRequestForAccessToActiveVC(_rejectedPeer,_hostOfActiveVC);
        } else {
          this.uiMessenger.warningToUserSticky('User is already in a conversation. To join that conversation together with everyone on your call, click again within 5 seconds.',5000);
          this.vcData.registerAuthorizationToJoinOrMergeOtherActiveVC(_rejectedPeer);
        }
      } else*/ if (
      this.vcData.localClientIsVCCurrentHost() && //&& this.vcData.getVCPeers().length>0 //if local client !hosts! a VC and both VCs could be merged
      _participantCountActiveVC + this.vcData.getVCPeers().length + 1 <=
        this.vcData.getVCMaxUserLimit()
    ) {
      //&& window.confirm('User is already in a conversation. To join that conversation together with everyone on your call, confirm with "OK".')) {
      if (this.vcData.isAuthorizedToJoinOrMergeOtherActiveVC(_rejectedPeer)) {
        //let clientsInMyVC = this.vcData.getVCPeers().concat([this.signaling.getSocketData().getLocalSocketID()]);
        //closeVC();
        //requestAccessToActiveVC121(_hostOfActiveVC, clientsInMyVC);
        this.initiatePreValidationAndRequestForAccessToActiveVC(
          _rejectedPeer,
          _hostOfActiveVC
        );
      } else {
        if (this.vcData.getVCPeers().length == 0) {
          this.uiMessenger.warningToUserSticky(
            "User is already in a conversation. To join that conversation, click again within 5 seconds.",
            5000
          );
        } else {
          this.uiMessenger.warningToUserSticky(
            "User is already in a conversation. To join that conversation together with everyone on your call, click again within 5 seconds.",
            5000
          );
        }
        this.vcData.registerAuthorizationToJoinOrMergeOtherActiveVC(
          _rejectedPeer
        );
      }
    } else if (
      !this.vcData.localClientIsVCCurrentHost() && //&& this.vcData.getVCPeers().length>0 //if local client is !participant! of a VC and both VCs could be merged
      _participantCountActiveVC + this.vcData.getVCPeers().length + 1 <=
        this.vcData.getVCMaxUserLimit()
    ) {
      //&& window.confirm('User is already in a conversation. To join that conversation together with everyone on your call, confirm with "OK".')) {
      if (this.vcData.isAuthorizedToJoinOrMergeOtherActiveVC(_rejectedPeer)) {
        this.signaling.requestHostToMergeWithOtherActiveVC(
          _hostOfActiveVC,
          _participantCountActiveVC
        );
      } else {
        if (this.vcData.getVCPeers().length == 0) {
          this.uiMessenger.warningToUserSticky(
            "User is already in a conversation. To join that conversation, click again within 5 seconds.",
            5000
          );
        } else {
          this.uiMessenger.warningToUserSticky(
            "User is already in a conversation. To join that conversation together with everyone on your call, click again within 5 seconds.",
            5000
          );
        }
        this.vcData.registerAuthorizationToJoinOrMergeOtherActiveVC(
          _rejectedPeer
        );
      }
    } else if (
      this.vcData.getVCPeers().length > 1 && //if local client hosts a VC and VCs cannot be merged as max user limit is too low
      _participantCountActiveVC + this.vcData.getVCPeers().length + 1 >
        this.vcData.getVCMaxUserLimit() && //target VC cannot accomodate entire VC of local host
      _participantCountActiveVC + 1 <= this.vcData.getVCMaxUserLimit()
    ) {
      //target VC can still accomodate min 1 person
      //&& window.confirm('User is already in a conversation. To leave your current call and instead join that conversation, confirm with "OK". You cannot take everyone with you to that conversation due to max user limits.')) {
      this.uiMessenger.informUserSticky(
        "User is already in a conversation. In order to join the user's conversation, please leave the current conversation first as it cannot be merged into the user's conversation in light of max user limits."
      );
      /*if (this.vcData.isAuthorizedToJoinOrMergeOtherActiveVC(_rejectedPeer)) {
          leaveVC(); // VC remains open thereafter if sufficient participants
          requestAccessToActiveVC121(_hostOfActiveVC,[this.signaling.getSocketData().getLocalSocketID()]);
        } else {
          this.uiMessenger.warningToUserSticky('User is already in a conversation. To leave your current call and instead join that conversation, click again within 5 seconds. You cannot take everyone with you to that conversation due to max user limits.',5000);
          this.vcData.registerAuthorizationToJoinOrMergeOtherActiveVC(_rejectedPeer);
        }*/
    } else
      this.uiMessenger.informUser("User not joining: " + _rejectionRationale); // is this case even possible?
  };

  initiatePreValidationAndRequestForAccessToActiveVC = (
    _rejectedPeer,
    _hostOfActiveVC
  ) => {
    this.logger.logLocal([
      "initiating prevalidation / request for access to an active VC of host ",
      _hostOfActiveVC,
    ]);
    if (
      !this.vcData.isOutgoingMergeOngoing() &&
      !this.vcData.isIncomingMergeOngoingForPeer(_hostOfActiveVC)
    ) {
      let _clientsInMyVC = this.vcData
        .getVCPeers()
        .concat([this.signaling.getUniqueClientID()])
        .concat(this.vcData.getConfirmedVCJoiners());
      let _originator = this.vcData.getPendingVCInviteOriginator(_rejectedPeer);
      this.signaling.requestPreValidationOfJoiningActiveVC121(
        _originator,
        _hostOfActiveVC,
        _clientsInMyVC
      );
    } else {
      this.logger.logWarning([
        "local host rejected an 121requestToMergeWithOtherActiveVC as a conflicting outgoing merge request appears to be WIP",
      ]);
      let _originator = this.vcData.getPendingVCInviteOriginator(_rejectedPeer);
      this.signaling.informRequestorOfDuplicativeRequestToJoinVC121(
        _originator,
        _hostOfActiveVC
      );
      //todo: more individualized denial-states/messaging
    }
  };

  //access stream reflecting local screen and add it to the VC
  async startLocalScreenShare() {
    return new Promise((successCallback, failureCallback) => {
      if (this.vcData.isVCOpen()) {
        // can only leave screen share if there is an open VC
        // marking local screenshare as active on ui before it happens hides the VC window - this is helpful as on app, VC window lies in front of screen selection dialogue - hiding it allows for more intuitive usage of system dialogue

        /*let backgroundURL = null;
        if (this.help.isDevEnv())
          backgroundURL = this.config.devEnvBaseURL + "empty.html";
        else backgroundURL = this.config.prodEnvBaseURL + "empty.html";*/

        this.uiHandler.registerActiveScreenShareDialogue(true);
        this.uiHandler
          .getCSSClassModifier()
          .addClassOnEntireApp("localScreenShareActive");
        this.uiHandler
          .getUITechHandler()
          .getVCWindow()
          .tmpHideDedicatedVCWindowForScSDialogueIfOnApp(
            this.uiHandler.VCUIIsActive() || this.remoteScreenShareIsActive()
          );
        this.uiHandler
          .getUITechHandler()
          .getDialogueBackgroundWindow()
          .createMaximizedScreenShareDialogueBackgroundWindow()
          .then(this.media.getAndRegisterLocalScreenShareStream)
          .then(() => {
            //do final checks to confirm situation is still adequate to start screen share
            if (!this.vcData.isVCOpen() || this.remoteScreenShareIsActive()) {
              this.stopBrowserScreenShare();
              this.data.setLocalMSScreenShare(null);
              if (this.remoteScreenShareIsActive())
                this.uiMessenger.informUser(
                  "screen could not be shared as remote screen share started in the interim"
                );
              else
                this.uiMessenger.informUser(
                  "screen could not be shared as VC closed in the interim"
                );
              throw new Error(
                "Local screen share could not be started as either VC closed in the interim or peer started to share screen in the interim"
              );
            }
            //handleObtainedScreenStream(); // display local stream on local UI
            this.uiHandler
              .getUITechHandler()
              .getDialogueBackgroundWindow()
              .hideMaximizedScreenShareBackgroundWindow();
            //this.uiHandler.getUINWJSHandler().restoreSideBarWindowIfOnApp();
            this.uiHandler.markButtonsAsPressed(
              [
                this.appUI
                  .getVCScreenHTML()
                  .getUILocalClientShareScreenButton(),
              ],
              true
            );
            this.uiMessenger.confirmToUser("You are now sharing your screen");
            this.uiHandler
              .getUITechHandler()
              .getVCWindow()
              .unhideDedicatedVCWindowAfterTMPHideForScSDialogueIfOnApp(false);
            this.uiHandler.registerActiveScreenShareDialogue(false);
            if (!this.vcData.localClientIsVCCurrentHost()) {
              this.signaling.offerScreenSharingToVCHost121();
            } else {
              this.signaling.informParticipantsOfScreenSharingOffer(
                this.signaling.getUniqueClientID()
              );
              this.downgradeVCToSidebarIfOnDedicatedWindow();
            }
            successCallback(() => {}); //TODO: Check whether this still works
          })
          .catch((e) => {
            this.uiHandler
              .getCSSClassModifier()
              .removeClassOnEntireApp("localScreenShareActive");
            this.uiHandler
              .getUITechHandler()
              .getDialogueBackgroundWindow()
              .closeMaximizedScreenShareBackgroundWindow();
            //this.uiHandler.getUINWJSHandler().restoreSideBarWindowIfOnApp();
            this.uiHandler
              .getUITechHandler()
              .getVCWindow()
              .unhideDedicatedVCWindowAfterTMPHideForScSDialogueIfOnApp(
                this.uiHandler.VCUIIsActive() ||
                  this.remoteScreenShareIsActive()
              ); //shows vc window as long as vcui is active
            this.uiHandler.registerActiveScreenShareDialogue(false);
            this.logger.logWarning([
              "Error processing screen share request / error returned by function getAndRegisterLocalScreenShareStream or peer action prevented start of screen share: " +
                e.message,
            ]);
            failureCallback(e); /* do nothing*/
          });
      } else {
        failureCallback(
          new Error("Attempting to share screen while VC not active")
        );
      }
    });
  }

  downgradeVCToSidebarIfOnDedicatedWindow = () => {
    if (
      this.vcData.getVCCurrentType() == SIGNALING_PURPOSE.HR_VIDEO ||
      this.vcData.getVCCurrentType() == SIGNALING_PURPOSE.MR_VIDEO ||
      this.vcData.getVCCurrentType() == SIGNALING_PURPOSE.LR_VIDEO
    ) {
      this.signaling.informParticipantsOfVCDowngradeToSidebarRequest();
      this.processIncomingVCTypeChange(SIGNALING_PURPOSE.AUDIO_ONLY);
    }
  };

  endLocalScreenShare = (
    suppressAllPeerInteractionForSilentLocalClosureOnly: boolean = false
  ) => {
    if (this.vcData.isVCOpen()) {
      // can only leave screen share if there is an open VC
      if (this.localScreenShareIsActive()) {
        //close any active screenshare dialogue and respective connections
        this.uiHandler
          .getCSSClassModifier()
          .removeClassOnEntireApp("localScreenShareActive");

        this.logger.logLocal(["Ending screen share"]);
        //informUser('Closing screen share');
        this.uiHandler.hideVCLocalScreenShareVideoSectionOnUI(); //if at all displayed, hide local screen share section (currently not in use)
        if (!suppressAllPeerInteractionForSilentLocalClosureOnly)
          this.signaling.informParticipantsOfScreenSharingEnd(
            this.signaling.getUniqueClientID()
          );
        this.stopBrowserScreenShare();

        this.data.setLocalMSScreenShare(null);
        this.data.retireAllScreenSharePCs();
        this.uiHandler.markButtonsAsPressed(
          [this.appUI.getVCScreenHTML().getUILocalClientShareScreenButton()],
          false
        );
        this.uiMessenger.confirmToUser("You are no longer sharing your screen");
      }
    }
  };

  stopBrowserScreenShare = () => {
    //ends submission of screen media and implicitly closes respective browser dialogue in chrome
    if (
      this.data.getLocalMSScreenShare() !== null &&
      this.data.getLocalMSScreenShare() !== undefined
    ) {
      this.data
        .getLocalMSScreenShare()
        .getTracks()
        .forEach((track) => track.stop());
    }
  };

  localScreenShareIsActive = () => {
    if (this.vcData.isVCOpen()) {
      // can only leave screen share if there is an open VC
      if (
        this.data.getLocalMSScreenShare() != null &&
        this.data.getLocalMSScreenShare() != undefined
      ) {
        return true;
      }
    }
    return false;
  };

  remoteScreenShareIsActive = () => {
    if (this.vcData.isVCOpen()) {
      // can only leave screen share if there is an open VC
      if (
        this.data.countPMSs(SIGNALING_PURPOSE.SCREEN_SHARE) != 0 &&
        (!this.help.appIsActive() ||
          this.uiHandler
            .getAppUIHTML()
            .getVCScreenShareHTML()
            .getRemoteScreenShareVSVC()
            .classList.contains("active"))
      ) {
        return true;
      }
    }
    return false;
  };

  getCurrentScreenSharePeerID = () => {
    if (this.localScreenShareIsActive()) {
      return this.signaling.getUniqueClientID();
    }
    if (this.remoteScreenShareIsActive()) {
      return this.data.getPeerMSScreenShare().keys().next().value;
    }
    return null;
  };

  includeNewPeerInScreenShareIfScreenShareActive(peerID: string) {
    //this is a function defined in parent class that is overwritten here - signature must stay in synch
    if (
      this.vcData.localClientIsVCCurrentHost() &&
      (this.localScreenShareIsActive() || this.remoteScreenShareIsActive())
    ) {
      this.signaling.informSingleParticipantsOfScreenSharingOffer(
        peerID,
        this.getCurrentScreenSharePeerID()
      );
    }
  }

  //resets VC UI and data structures - for full closure OR for the local client leaving the VC
  resetVCUIAndDataStructures = (
    noNewConnectionAsEitherLocalOrRemotePeersDisconnecting: boolean = false
  ) => {
    let VCPs = this.vcData.getVCPeers();
    Array.from(VCPs).forEach((VCP) => {
      this.removePeerFromExistingVC(
        VCP,
        false,
        noNewConnectionAsEitherLocalOrRemotePeersDisconnecting
      ); //note:removing the last participant triggers VC UI reset and closure
    });
    //this.vcData.setVCOpen(false);//is happening when last peer gets removed
    this.uiHandler.resetVCUI();
    this.vcData.setVCOpen(false);
    this.vcData.setVCCurrentHost(null);
    this.vcData.setVCCurrentCoHost(null);
    this.vcData.resetPendingVCInvites();
    this.vcData.resetIncomingMerges();
    this.vcData.unregisterOutgoingMerge();
    this.vcData.resetVCPeers();
  };

  resetAndCloseVCUIIfNoPeers = () => {
    if (this.vcData.getVCPeers().length === 0) {
      this.uiHandler.resetVCUI();
      this.vcData.setVCOpen(false);
      this.vcData.setVCCurrentHost(null);
      this.vcData.setVCCurrentCoHost(null);
    }
  };

  processStreamChangeRequest = (peer, messageAttributes) => {
    // - if pc and stream for target usage exists, assign it to UI and close streams / pcs for other target usages
    // - else, enqueue new peer connection for target usage, but do not close anything else - this happens when new streams are received / in remoteTrackAdded procedure

    //let _serial : Serializer = new Serializer();
    let _targetUsage: SIGNALING_PURPOSE =
      /*_serial.deserializeSignalingPurpose(*/ messageAttributes.targetUsage; /*)*/

    this.signaling.cancelIncompleteConnectionsForIncompatiblePurposes(
      peer,
      _targetUsage
    );

    this.logger.logLocal([
      "Processing <ProcessStreamChangeRequest> with purpose:",
      _targetUsage,
    ]);
    if (_targetUsage == SIGNALING_PURPOSE.SCREEN_SHARE) {
      // terminate any existing connections, if any
      if (this.data.existsPC(peer, SIGNALING_PURPOSE.SCREEN_SHARE))
        this.data.retirePC(peer, SIGNALING_PURPOSE.SCREEN_SHARE);
      if (this.data.existsPMS(peer, SIGNALING_PURPOSE.SCREEN_SHARE))
        this.data.retirePMS(peer, SIGNALING_PURPOSE.SCREEN_SHARE);
      this.signaling
        .getPeerConnectionsQueue()
        .cancelPendingPeerConnectionsForPeer(
          peer,
          this.signaling.getRooms().getMainRoomID(),
          SIGNALING_PURPOSE.SCREEN_SHARE,
          true
        );
      this.signaling
        .getPeerConnectionsQueue()
        .cancelPendingPeerConnectionsForPeer(
          peer,
          this.signaling.getRooms().getActiveMeetingRoomID(),
          SIGNALING_PURPOSE.SCREEN_SHARE,
          true
        );

      this.signaling
        .getPeerConnectionsQueue()
        .enqueuePeerConnectionCreation(
          peer,
          this.signaling.getRooms().getActiveMeetingRoomID(),
          "offer",
          null,
          _targetUsage
        );
      this.signaling.processPendingPccIfReady();
    } else {
      // for all connection types except screenshare
      if (
        this.data.existsPC(peer, _targetUsage) &&
        this.data.existsPMS(peer, _targetUsage)
      ) {
        // if everything that's required is already there - would typically not be the case
        this.appUI.getSideBarHTML().getPeerHTMLVideoElement(peer).srcObject =
          this.data.getPMS(peer, _targetUsage); //always assign sidebar video
        if (_targetUsage !== SIGNALING_PURPOSE.BASE_CONNECTION) {
          if (!this.appUI.getVCScreenHTML().hasRegisteredPeerVSVC(peer)) {
            this.appUI.getVCScreenHTML().registerUnusedPeerVSVC(peer);
            this.appUI.markVideoSectionsAsPartOfActiveVCOnBothSidebarAndVCWindow(
              peer,
              true
            );
            this.remoteMute.refreshMutedStatusForPeerOnUI(peer);
          }
          this.appUI
            .getVCScreenHTML()
            .getPeerHTMLVideoElementVC(peer).srcObject = this.data.getPMS(
            peer,
            _targetUsage
          );
        } else if (
          _targetUsage === SIGNALING_PURPOSE.BASE_CONNECTION &&
          this.appUI.getVCScreenHTML().hasRegisteredPeerVSVC(peer)
        ) {
          // if baseconnection
          this.appUI
            .getVCScreenHTML()
            .getPeerHTMLVideoElementVC(peer).srcObject = null;
          this.appUI.markVideoSectionsAsPartOfActiveVCOnBothSidebarAndVCWindow(
            peer,
            false
          );
          this.appUI.getVCScreenHTML().unregisterPeerVSVC(peer);
        }
        //retire pms and pc for purposes other than PCPurpose if those exist (note this excludes things like screen share purposes)
        this.retireAllPeersMSAndPCNotMatchingTargetPurposeExceptSS(
          peer,
          _targetUsage
        );
      } else {
        // main scenario: if PC and MS do not yet exist for that combination of peer and _targetUsage, create a new PC for that
        this.signaling
          .getPeerConnectionsQueue()
          .enqueuePeerConnectionCreation(
            peer,
            this.signaling.getRooms().getActiveMeetingRoomID(),
            "offer",
            null,
            _targetUsage
          );
        this.signaling.processPendingPccIfReady();
      }
    }
  };

  assignHostOfVC = (peerID: string) => {
    /* this function is future extension that is intended to allow for manual change of host - automatic reassignment of host priviledges on VC exit is already implemented */
    //TODO:
    //tell other peer that it's now the host
    //tell all clients that other peer is now the host
    //un-host local client
  };

  cohostToReassignHostPrivilegesIfHostLeaves = (peerIDWhichLeft: string) => {
    if (
      this.vcData.getVCCurrentHost() == peerIDWhichLeft &&
      this.vcData.localClientIsVCCurrentCoHost()
    ) {
      let localID = this.signaling.getUniqueClientID();
      this.vcData.setVCCurrentHost(localID);
      this.vcData.setVCCurrentCoHost(
        this.vcData.determineNextVCCoHost(peerIDWhichLeft, localID)
      );
      this.signaling.adviseVCParticipantsOfNewHost121(
        this.vcData.getVCCurrentHost(),
        this.vcData.getVCCurrentCoHost()
      );
    }
  };

  fullLocalHangupOnExit(reason: string) {
    this.logger.logLocal([
      "Local client exiting / exiting VC before leaving rest...",
    ]);
    this.leaveVC(false, false, true, false);
    super.fullLocalHangupOnExit(reason);
  }

  handleRemoteHangup(
    peerID: string,
    room: string,
    suppressAllPeerInteractionForSilentLocalClosureOnly: boolean = false
  ) {
    super.handleRemoteHangup(
      peerID,
      room,
      suppressAllPeerInteractionForSilentLocalClosureOnly
    );
    if (!suppressAllPeerInteractionForSilentLocalClosureOnly)
      this.cohostToReassignHostPrivilegesIfHostLeaves(peerID);
  }

  getUIHandler = (): UIHandler => {
    return this.uiHandler;
  };

  getVCChat = (): Chat => {
    return this.vcChat;
  };

  public localCleanUpToDealWithLocalConnectionFailure() {
    super.localCleanUpToDealWithLocalConnectionFailure();
    this.leaveVC(false, true, true, false);
    this.logger.logLocal([
      "Disconnecting from VC as local client lost connection to peers and server",
    ]);
  }

  public localCleanUpToDealWithRemoteButNotLocalConnectionFailure(
    peerID: string
  ) {
    super.localCleanUpToDealWithRemoteButNotLocalConnectionFailure(peerID);
    this.signaling.triggerHostReassignmentIfHostDisconnected(peerID);
    //if (this.vcData.isVCOpen()) {
    this.logger.logLocal([
      "Disconnecting peer ",
      peerID,
      " based on connection having been disrupted for too long - closing all active conversations and removing peer from sidebar",
    ]);
    this.handleRemoteHangup(
      peerID,
      this.signaling.getRooms().getActiveMeetingRoomID()
    );
    //this.removePeerFromExistingVC(peerID);
    //}
  }

  warnUserStickyIfOnVC = (
    message: string,
    displayDurationInMS: number = 10000,
    warnOnlyIfOnVC: boolean = true
  ): void => {
    if (!(warnOnlyIfOnVC && !this.vcData.isVCOpen())) {
      this.uiMessenger.warningToUserSticky(message, displayDurationInMS);
    }
  };
}
