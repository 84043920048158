import { Logger } from "../../utils/Logger";
import { BusLogicCore } from "../../buslogic/BusLogicCore";
import { SIGNALING_PURPOSE } from "../../data/enums/SIGNALING_PURPOSE";
import { I_MessageListenerFunction121PeerMessage } from "./I_MessageListenerFunction121PeerMessage";
import { I_OneToOneSignalingProvider } from "./I_OneToOneSignalingProvider";
import { I_RemoteMuteSignalingInterface } from "../../buslogic/remoteMute/I_RemoteMuteSignalingInterface";
import { I_IncomingRemoteMuteMessageHandler } from "../../buslogic/remoteMute/I_IncomingRemoteMuteMessageHandler";
import { RemoteMuteRequestMessage } from "../../buslogic/remoteMute/RemoteMuteRequestMessage";
import { LocalMuteStateInfoMessage } from "../../buslogic/remoteMute/LocalMuteStateInfoMessage";

export class SignalingRemoteMute implements I_RemoteMuteSignalingInterface {
  private logger: Logger;
  private signaling: I_OneToOneSignalingProvider;
  private busLogic: BusLogicCore;

  private remoteMuteMessageHandler: I_IncomingRemoteMuteMessageHandler;

  constructor(_logger: Logger) {
    this.logger = _logger;
  }

  initialize = (_signaling: I_OneToOneSignalingProvider) => {
    this.signaling = _signaling;
    this.signaling.register121MessageListener(
      "121RemoteMuteRequestMessage",
      this.process121MessageRemoteMuteRequestMessageToLocalClient
    );
    this.signaling.register121MessageListener(
      "121LocalMuteStateInfoMessage",
      this.process121MessageLocalMuteStateInfoToLocalClient
    );
  };

  setBusLogicReference(_busLogic: BusLogicCore) {
    this.busLogic = _busLogic;
  }

  shareRemoteMuteRequestWithPeer = (
    muteRequestMsg: RemoteMuteRequestMessage
  ): void => {
    let localID: string = this.signaling.getUniqueClientID();
    if (
      muteRequestMsg.getSenderID() == null ||
      muteRequestMsg.getSenderID() != localID
    )
      throw new Error(
        "Error processing mute request message to be shared with peer - invalid sender information"
      );
    this.signaling.send121Message(
      muteRequestMsg.getRecipientID(),
      this.signaling.getActiveMeetingRoomID(),
      {
        type: "121RemoteMuteRequestMessage",
        remoteMuteRequestSender: muteRequestMsg.getSenderID(),
        remoteMuteRequestRecipient: muteRequestMsg.getRecipientID(),
      }
    );
  };

  shareLocalMuteStateInfoWithPeer = (
    muteStateMsg: LocalMuteStateInfoMessage,
    peersRoom: string
  ): void => {
    let localID: string = this.signaling.getUniqueClientID();
    //let raiseHandMsg = new RaiseHandMessage(localID, recipientID, handRaised);
    if (
      muteStateMsg.getSenderID() == null ||
      muteStateMsg.getSenderID() != localID
    )
      throw new Error(
        "Error processing local mute state info message to be shared with peer - invalid sender information"
      );
    this.signaling.send121Message(muteStateMsg.getRecipientID(), peersRoom, {
      type: "121LocalMuteStateInfoMessage",
      muteStateInfoSender: muteStateMsg.getSenderID(),
      muteStateInfoRecipient: muteStateMsg.getRecipientID(),
      muteStateInfoStatus: muteStateMsg.getSenderMuteState(),
    });
    setTimeout(() => {
      //send same thing again after 3 seconds to allow adequate updates in web meeting settings
      this.signaling.send121Message(muteStateMsg.getRecipientID(), peersRoom, {
        type: "121LocalMuteStateInfoMessage",
        muteStateInfoSender: muteStateMsg.getSenderID(),
        muteStateInfoRecipient: muteStateMsg.getRecipientID(),
        muteStateInfoStatus: muteStateMsg.getSenderMuteState(),
      });
    }, 3000);
  };

  private process121MessageRemoteMuteRequestMessageToLocalClient: I_MessageListenerFunction121PeerMessage =
    (
      senderID: string,
      room: string,
      message: any,
      PCSigP: SIGNALING_PURPOSE
    ): void => {
      if (message.type != "121RemoteMuteRequestMessage")
        throw new Error(
          "Error processing incoming remote mute request message - client received wrong message type: " +
            message.messageType
        );
      let localID: string = this.signaling.getUniqueClientID();
      if (senderID != message.remoteMuteRequestSender)
        throw new Error(
          "Error processing incoming remote mute request message - incorrect sender information"
        );
      if (localID != message.remoteMuteRequestRecipient)
        throw new Error(
          "Error processing incoming remote mute request message - incorrect recipient information"
        );
      if (!this.busLogic.peerConnectionExists(senderID)) {
        this.logger.logWarning([
          "Remote mute request message ignored as sender is no longer part of room: ",
          senderID,
        ]);
        return;
      }
      this.remoteMuteMessageHandler.handleIncomingRemoteMuteRequestMessage(
        new RemoteMuteRequestMessage(
          message.remoteMuteRequestSender,
          message.remoteMuteRequestRecipient
        )
      );
    };

  private process121MessageLocalMuteStateInfoToLocalClient: I_MessageListenerFunction121PeerMessage =
    (
      senderID: string,
      room: string,
      message: any,
      PCSigP: SIGNALING_PURPOSE
    ): void => {
      if (message.type != "121LocalMuteStateInfoMessage")
        throw new Error(
          "Error processing incoming mute state info message - client received wrong message type: " +
            message.messageType
        );
      let localID: string = this.signaling.getUniqueClientID();
      if (senderID != message.muteStateInfoSender)
        throw new Error(
          "Error processing incoming mute state info message - incorrect sender information"
        );
      if (localID != message.muteStateInfoRecipient)
        throw new Error(
          "Error processing incoming mute state info message - incorrect recipient information"
        );
      if (!this.busLogic.peerConnectionExists(senderID)) {
        this.logger.logWarning([
          "Mute state info message ignored as sender is no longer part of room: ",
          senderID,
        ]);
        return;
      }
      this.remoteMuteMessageHandler.handleIncomingLocalMuteStateInfoMessage(
        new LocalMuteStateInfoMessage(
          message.muteStateInfoSender,
          message.muteStateInfoRecipient,
          message.muteStateInfoStatus
        )
      );
    };

  //RaiseHand instance registers itself as recipient of any chat messages
  registerIncomingMessageHandler = (
    remoteMuteHandler: I_IncomingRemoteMuteMessageHandler
  ): void => {
    this.remoteMuteMessageHandler = remoteMuteHandler;
  };
}
