export class QueueVisitorRegistrationConfirmationByServerMessage {
  private hashedQueueID: string;

  constructor(_hashedQueueID: string) {
    this.hashedQueueID = _hashedQueueID;
  }

  initialize = (): void => {};

  getHashedQueueID = (): string => this.hashedQueueID;
}
