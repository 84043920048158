export class RoomOwnerRegistrationMessage {
  private roomIDClearText: string;

  constructor(_roomIDClear: string) {
    this.roomIDClearText = _roomIDClear;
  }

  initialize = (): void => {};

  getRoomIDClearText = (): string => this.roomIDClearText;
}
