import { DataMgmt } from "../data/DataMgmt";
import { BusLogicVC } from "../buslogic/BusLogicVC";
import { BrowserMediaHandler } from "../media/BrowserMediaHandler";
import { Helpers } from "./Helpers";
import { UIMessenger } from "./UIMessenger";
import { SIGNALING_PURPOSE } from "../data/enums/SIGNALING_PURPOSE";
import { VRTCPeerConnection } from "../signaling/VRTCPeerConnection";
import { UINwjsHandler } from "../frontend/nwjs/UINwjsHandler";
import { UIHandler } from "frontend/UIHandler";
import { I_UITechHandler } from "frontend/decoupler/I_UITechHandler";
//import * as AutoLaunch from "easy-auto-launch";
//var AutoLaunch = require("easy-auto-launch");
//var AutoLaunch = require('autorun');
//import * as AutoLaunch from 'auto-launch';

export class Tester {
  private help: Helpers;
  private data: DataMgmt;
  private media: BrowserMediaHandler;
  private uiMessenger: UIMessenger;
  private busLogic: BusLogicVC;
  private nwjsHandler: I_UITechHandler;
  private uiHandler: UIHandler;

  //@ts-ignore
  //notifier = require("node-notifier");

  constructor(
    _help: Helpers,
    _data: DataMgmt,
    _uiM: UIMessenger,
    _media: BrowserMediaHandler,
    _busLogic: BusLogicVC,
    _nwjsHandler: I_UITechHandler,
    _uiHandler: UIHandler
  ) {
    this.help = _help;
    this.data = _data;
    this.media = _media;
    this.uiMessenger = _uiM;
    this.busLogic = _busLogic;
    this.nwjsHandler = _nwjsHandler;
    this.uiHandler = _uiHandler;
  }

  //private tray = null;

  //private repeatID = null;

  //private launcher: any = null;
  //private autoLaunchActive: boolean = false;

  testFeatureClick = () => {
    this.busLogic.getQueueMgr().copyPersonalQueueLinkToClipboard();

    if (!this.busLogic.getVCDataMgr().isVCOpen())
      this.busLogic.getRoomMgr().joinPersonalMeetingRoom();
    else
      this.uiMessenger.warningToUser(
        "Please leave current conversation prior to joining your personal meeting room"
      );

    /*  if (this.launcher == null) {
      this.launcher = new AutoLaunch({
        name: 'visav.is',
      });
    }

    if (this.autoLaunchActive) {
      this.launcher.disable();
      this.autoLaunchActive = false;
    } else {
      this.launcher.enable();
      this.autoLaunchActive = true;
    }

    /*try {
      //if (global.gc) {
      global.gc();
      //}
    } catch (e) {
      console.error(" error calling global.gc() " + e);
    }

    /*if (this.help.appIsActive()) {
      try {
        //@ts-ignore
        nw.Window.getAll((wins: nw.Window[]) => {
          wins.forEach((curWin) => {
            curWin.show();
            curWin.showDevTools();
          });
        });
      } catch (e) {
        console.error("error trying to show all nwjs windows, " + e);
      }
    }

    /*@ts-ignore
    Bugsnag.notify(new Error("This is my test error"), (event) => {
      //event.context = argumentsX[0];
      let msg: string = "This is my test error";
      event.groupingHash = msg;
      //event.addMetadata("allArgs", argumentsX);
      //event.addMetadata('room', 'name', XXX.getmainroom());
    });

    //this.uiHandler.getAppUIHTML().getConfHTML().openConfWindow();
    /*    setTimeout(() => {
      this.nwjsHandler
        .getConfWindowNWJS()
        .getConfWindowHandleForTestPurposes()
        .setTransparent(true);
    }, 3000);*/
    /*this.data
      .getAllPCsOfSignalingPurpose(SIGNALING_PURPOSE.BASE_CONNECTION)
      .forEach((peerRTCPC: VRTCPeerConnection, peerID: string, map: any) => {
        peerRTCPC
          .getReceivers()
          .forEach(
            (
              receiver: RTCRtpReceiver,
              index: number,
              array: RTCRtpReceiver[]
            ) => {
              if (receiver.track.kind == "video") {
                receiver.getStats().then((myStatsReport: RTCStatsReport) => {
                  console.log(myStatsReport);
                  myStatsReport.forEach(
                    (statValue: any, key: string, parent: RTCStatsReport) => {
                      if (
                        statValue.type == "inbound-rtp"
                        //statValue.type == "framesPerSecond" ||
                        //statValue.type == "voiceActivityFlag" ||
                        //statValue.type == "audioLevel"
                      ) {
                        console.log(
                          "Parsing the PC's stats object for peer " +
                            peerID +
                            " and purpose " +
                            peerRTCPC.getSignalingPurpose() +
                            " under statValue.type " +
                            statValue.type +
                            ", the key " +
                            key +
                            " returned the framesPerSecond value " +
                            statValue["framesPerSecond"]
                          //" returned the value " +
                          //JSON.stringify(statValue)
                        );
                      }
                    }
                  );
                });
              }
            }
          );
        //let curPMS = this.data.getPMS(peerID, peerRTCPC.getSignalingPurpose()).
      });*/
    /*
    try {
      //@ts-ignore
      nw.Window.getAll((wins: nw.Window[]) => {
        wins.forEach((curWin) => {
          //curWin.setVisibleOnAllWorkspaces(true);
          //curWin.setShowInTaskbar(true);
          //curWin.show();
          //curWin.setAlwaysOnTop(false);
          curWin.showDevTools();
          console.log("configuring njws window " + curWin.title);
        });
      });
    } catch (e) {
      console.error(
        "error trying to close orphaned windows from previous app starts, " + e
      );
    }

    /*if (this.repeatID == null) {
      //start stats polling
      this.repeatID = setInterval(() => {
        /*myPeerConnection.getStats(null).then(stats => {
          var statsOutput = "";
      }, 1000);
    } else {
      //stop stats polling
      clearInterval(this.repeatID);
      this.repeatID = null;
    }
    //chrome.contentSettings.
    //@ts-ignore
    /*chrome.permissions.request({
      permissions: ["contentSettings"],
      origins: ["https://*.visav.is/"],
    });*/
    /*let pre = "";
    //@ts-ignore
    chrome.contentSettings.camera.get(
      { primaryUrl: "https://*.visav.is" },
      function (details) {
        pre += "Camera: " + details.setting + "<br>";
      }
    );
    //@ts-ignore
    chrome.contentSettings.microphone.get(
      { primaryUrl: "https://*.visav.is" },
      function (details) {
        pre += "Microphone: " + details.setting + "<br>";
      }
    );
    setTimeout(function () {
      alert("Content Settings...<br><br>" + pre);
    }, 1500);

    //@ts-ignore
    chrome.contentSettings.camera.set(
      { primaryPattern: "https://*.visav.is/*", setting: "allow" },
      function () {
        console.log("Camera access requested");
      }
    );
    //@ts-ignore
    chrome.contentSettings.microphone.set(
      { primaryPattern: "https://*.visav.is/*", setting: "allow" },
      function () {
        console.log("Microphone access requested");
      }
    );
    let s = "";
    //@ts-ignore
    chrome.contentSettings.camera.get(
      { primaryUrl: "https://*.visav.is" },
      function (details) {
        s += "Camera: " + details.setting + "<br>";
      }
    );
    //@ts-ignore
    chrome.contentSettings.microphone.get(
      { primaryUrl: "https://*.visav.is" },
      function (details) {
        s += "Microphone: " + details.setting + "<br>";
      }
    );
    setTimeout(function () {
      alert("Content Settings...<br><br>" + s);
    }, 2500);

    //this.uiMessenger.informUser("no feature mapped to test button");
    //let chatMsg = window.prompt("pls enter chat message to be sent to others:");
    //this.busLogic.getVCChat().shareChatMessage(chatMsg);

    //@ts-ignore
    //Bugsnag.notify(new Error("Test error"));

    /*  try {
      //@ts-ignore
      this.tray = new nw.Tray({
        title: "vis_main_screen",
        icon: "https://visav.is/favicon.ico",
        tooltip: "myTrayIsGreat",
      });
    } catch (e) {
      console.error("Error creating tray icon, " + e);
    }

    //this.media.getVideoDownScaler().stopCompressionOfLocalVideoStream();
    /*
    Notification.requestPermission().then(function (result) {
      console.log(result);
    });

    const title = "Simple Title";
    const options = {
      dir: "rtl",
      //icon: "https://visav.is/favicon.ico",
      //badge: "https://visav.is/favicon.ico",
      body: "Simple piece of body text.\nSecond line of body text :)",
      //tag: "this is my tag",
      requireInteraction: false,
      reNotify: false,
      silent: true,
      data: null,
    };

    /*navigator.serviceWorker
      .getRegistration()
      .then((reg) => reg.showNotification("Hi there - persistent!"))
      .catch((err) => alert("Service Worker registration error: " + err));*/
    /*//@ts-ignore
    let notif = new Notification(title, options);
    setTimeout(() => {
      notif.close();
    }, 3000);*/
  };

  /*private tryCommsBetweenNWJSWindows = () => {
    //a dedicated test button on the UI has been introduced - it can be temporarily link to new features to test the same
    if (this.help.appIsActive()) {
      let targetURL =
        "https://app-main-dev.visav.is/vh.html?rand=" + Math.random() * 10000;

      // var returnWin;

      //@ts-ignore
      nw.Window.open(
        targetURL,
        {
          title: "visavis feedback",
          position: "center",
          icon: "favicon.icns",
          show: true,
          //new_instance: true,
          //mixed_context: true,
        },
        (win) => {
          //returnWin = win;
          win.on("loaded", () => {
            this.initializeNewWindow(win);
          });
        }
      );
    }
  };

  private VCWin = null;

  initializeNewWindow = (win) => {
    this.VCWin = win;
    win.window.document.getElementById("entireApp").classList.add("VCOnVCUI");
  };*/
}
