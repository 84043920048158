export class RoomOwnerRegistrationConfirmationByServerMessage {
  private roomName: string;
  private hashedRoomID: string;

  constructor(_roomName: string, _hashedRoomID: string) {
    this.roomName = _roomName;
    this.hashedRoomID = _hashedRoomID;
  }

  initialize = (): void => {};

  getHashedRoomID = (): string => this.hashedRoomID;

  getRoomName = (): string => this.roomName;
}
