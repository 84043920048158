import { Helpers } from "utils/Helpers";
import { ROOM_CONTEXT } from "./enums/ROOM_CONTEXT";

export class Room {
  private roomID: string;
  private ownedLocally: boolean;
  //private ownerID: string; //multiple owners on team room or shared meeting room? room owner not the same as VC owner
  private peersInRoom: Set<string>;
  private context: ROOM_CONTEXT;

  //private help : Helpers;

  constructor(_roomID: string /*, _context: ROOM_CONTEXT*/) {
    this.roomID = _roomID;
    this.ownedLocally = false;
    //this.ownerID = null;
    this.peersInRoom = new Set();
    //this.context = _context;
  }

  initialize = (/*_help: Helpers*/) => {
    //this.help = _help;
  };

  getID = (): string => {
    return this.roomID;
  };

  //isMainRoom = (): boolean => this.context == ROOM_CONTEXT.MAIN_ROOM;
  //isMeetingRoom = (): boolean => this.context == ROOM_CONTEXT.MEETING_ROOM;

  /*    setOwnerID = (_ownerID: string) : void => {
      this.ownerID = _ownerID;
    }*/

  setLocalUserAsOwner = (isOwner: boolean = false): void => {
    this.ownedLocally = isOwner;
  };

  localUserIsOwner = (): boolean => {
    return this.ownedLocally;
  };

  /*isOwnerOfRoom = (_peerUniqueID: string) : boolean => {
      return this.ownerID == _peerUniqueID;
    };*/

  peerIsInRoom = (_peerUniqueID: string): boolean => {
    return this.peersInRoom.has(_peerUniqueID);
  };

  registerPeerInRoom = (_peerUniqueID: string): void => {
    this.peersInRoom.add(_peerUniqueID);
  };

  removePeer = (_peerUniqueID: string): void => {
    this.peersInRoom.delete(_peerUniqueID);
  };

  getPeersInRoom = (): Set<string> => {
    //return Array.from(this.peersInRoom);
    return this.peersInRoom;
  };

  /*getOwner = () : Set<string> => {
      return this.roomOwners;
    }*/

  isEmpty = (): boolean => {
    return this.peersInRoom.size == 0;
  };

  resetPeerData = (): void => {
    this.peersInRoom.clear();
  };
}
