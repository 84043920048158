export class QueueAllowVisitorInMessage {
  private queueIDHash: string;
  private roomName: string;
  private peerUID: string;

  constructor(_queueIDHash: string, _roomName: string, _peerUID: string) {
    this.queueIDHash = _queueIDHash;
    this.roomName = _roomName;
    this.peerUID = _peerUID;
  }

  initialize = (): void => {};

  getQueueIDHash = (): string => this.queueIDHash;
  getRoomName = (): string => this.roomName;
  getPeerUID = (): string => this.peerUID;
}
