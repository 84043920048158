import { io, Socket } from "socket.io-client";
import { DataMgmt } from "../data/DataMgmt";
import { UIMessenger } from "../utils/UIMessenger";
import { Config } from "../config/Config";
import { SettingStorage } from "../data/SettingStorage";
import { SocketData } from "../data/SocketData";
import { Logger } from "../utils/Logger";
import { Helpers } from "../utils/Helpers";
import { SERVER_REGION } from "../data/enums/SERVER_REGION";
import { BOOL } from "../data/enums/BOOL";
import { SIGNALING_PURPOSE } from "../data/enums/SIGNALING_PURPOSE";
//import { WakeUpDetector } from "../infra/WakeUpDetector";
import { I_StateMgr } from "../infra/I_StageMgr";

export class ServerConnector {
  private help: Helpers;
  private logger: Logger;
  private config: Config;
  private data: DataMgmt;
  private settings: SettingStorage;
  private uiMessenger: UIMessenger;
  //private wakeUp: WakeUpDetector;

  private soDa: SocketData;

  private stateMgr: I_StateMgr;

  private readonly _signalingServerURL: string;

  private uniqueClientID: string;
  private isConnected: boolean;
  private idIsRegisteredWithServer: boolean;
  private serverConnectionConfirmedSinceStartOfCheck: boolean;

  private callBackForActionsRightAfterSocketReconnect: () => void;
  private storedCallbacksForConnectionCheckPos: Set<() => void>;
  private storedCallbacksForConnectionCheckNeg: Set<() => void>;

  constructor(
    _help: Helpers,
    _logger: Logger,
    _config: Config,
    _data: DataMgmt,
    _settings: SettingStorage,
    _uiMessenger: UIMessenger
    //_wakeUp: WakeUpDetector
  ) {
    this.help = _help;
    this.logger = _logger;
    this.config = _config;
    this.data = _data;
    this.settings = _settings;
    this.uiMessenger = _uiMessenger;
    //this.wakeUp = _wakeUp;

    this.soDa = new SocketData();

    this._signalingServerURL = this.config.getSignalingServerURL(
      SERVER_REGION.EUROPE
    );
    if (this.help.isDevEnv())
      this._signalingServerURL = this.config.getSignalingServerURL(
        SERVER_REGION.ASIA
      );

    this.uniqueClientID = null;
    this.isConnected = false;
    this.idIsRegisteredWithServer = false;
    this.serverConnectionConfirmedSinceStartOfCheck = null;
    this.storedCallbacksForConnectionCheckPos = new Set<() => void>();
    this.storedCallbacksForConnectionCheckNeg = new Set<() => void>();
  }

  initialize = (roomName: string, _stateMgr: I_StateMgr) => {
    this.stateMgr = _stateMgr;
    let _socket: Socket = io(this._signalingServerURL, {
      autoConnect: false,
      closeOnBeforeunload: false,
    });

    this.soDa.initialize(_socket);
    this.configureSocketOnResponsesForSocketServerConnectionHandling();
  };

  registerReconnectCallback = (callbackFunct: () => void): void => {
    this.callBackForActionsRightAfterSocketReconnect = callbackFunct;
  };

  connectToSignalingServer = (): void => {
    this.logger.logLocal(["Connecting to signaling server"]);
    this.soDa.getLocalSocket().connect();
    //this.soDa.getLocalSocket().autoConnect = true;
    //NOTE: parameters to pass on creation of this._socket to be reviewed should multiple connections be required - there's more parameters!!
  };

  getUniqueClientID = (): string => {
    return this.uniqueClientID;
  };

  isConnectedToServer = (): boolean => {
    return this.isConnected;
  };

  getSocketData = () => {
    return this.soDa;
  };

  disconnectAndResetServer = (): void => {
    this.getSocketData().getLocalSocket().disconnect();
    this.getSocketData().setLocalSocketID(null);
  };

  private registerLocalPeerWithServerAfterFreshConnect = () => {
    if (
      !this.settings.existsUIDSetting() ||
      !this.settings.existsUIDPassSetting() ||
      !this.settings.existsUIDVersionSetting()
    ) {
      this.logger.logLocal([
        "Sending request to signaling server to obtain new unique ID",
      ]);
      this.soDa.getLocalSocket().emit("registerNewPeerViaUID");
    } else {
      this.logger.logLocal([
        "Sending request to signaling server to register stored unique ID",
      ]);
      let peerUniqueIDFromStorage = this.settings.getUID();
      let peerPassFromStorage = this.settings.getUIDPass();
      let passVersionFromStorage = this.settings.getUIDVersion();
      this.soDa
        .getLocalSocket()
        .emit(
          "registerPeerViaUID",
          peerUniqueIDFromStorage,
          peerPassFromStorage,
          passVersionFromStorage
        );
    }
    //this.requestIceServers(); //once connection to signaling server exists, ask signaling server to provide ICE servers for firewall traversal
  };

  shareUserInfoForMailings = () => {
    if (this.settings.wasLastUserInfoPingToServerToday()) return;
    let uemail: string = "";
    let ulabel: string = "";
    if (this.settings.existsUserEmail()) uemail = this.settings.getUserEmail();
    if (this.settings.existsUserUILabel())
      ulabel = this.settings.getUserUILabel();

    this.shareLoggingInfo(
      "email-signup-info - #email: " + uemail + " #label: " + ulabel
    );
    this.settings.setLastUserInfoPingToServerDate();
  };

  shareLoggingInfo = (logTxt: string) => {
    if (logTxt != null && logTxt.length < 256) {
      this.soDa.getLocalSocket().emit("slViaUID", logTxt);
    }
  };

  handleFirstConnectInSession = () => {
    this.isConnected = true;
    this.registerLocalPeerWithServerAfterFreshConnect();
  };

  handleReconnectWithNewSocketIDAfterConnectionLoss = () => {
    this.uiMessenger.informUser("connection reestablished");
    this.isConnected = true;
    this.registerLocalPeerWithServerAfterFreshConnect();
    //this.callBackForActionsRightAfterSocketReconnect();
    //TODO any other cleanup from prev session - potentially before above registration request
    //todo any other activities to check for broken connections + reconnect ?!
  };

  private configureSocketOnResponsesForSocketServerConnectionHandling = () => {
    let localSocket: Socket = this.soDa.getLocalSocket();

    localSocket.on("connectionConfirmed", () => {
      this.isConnected = true;
      /*if (this.serverConnectionConfirmedSinceStartOfCheck == false) {
        this.serverConnectionConfirmedSinceStartOfCheck = true;
        this.performConnectivityCheckCallbacksPos();
      }*/
    });
  };

  connectorActionOnConnect = () => {
    this.logger.logLocal([
      "Connected to signaling server - client was assigned the socket ID: ",
      this.soDa.getLocalSocket().id,
    ]);
    if (
      this.soDa.getLocalSocketID() !== null &&
      this.soDa.getLocalSocketID() !== this.soDa.getLocalSocket().id
    ) {
      this.logger.logError([
        "Change in socket ID detected from/to: ",
        this.soDa.getLocalSocketID(),
        "/",
        this.soDa.getLocalSocket().id,
      ]);
      /*if (this.wakeUp.hasWakeUpBeenDetectedSinceLastServerConnect()) {
        //if (this.wakeUp.hasWakeUpBeenDetectedInLastFiveteenSeconds()) {
        this.logger.logWarning([
          "Reloading app to restore functionality as wakeup has been detected",
        ]);
        this.stateMgr.reloadVISAVIS(
          "ReloadingPostSystemWakeupFromSleepOrHybernate",
          "app is reloading as wakeup from sleep or hybernate mode has been detected - reconnecting you to your team",
          2000
        );
        return;
      }*/
      /*if (
        this.data.getLocalMS().active &&
        this.data.getLocalMS().getVideoTracks()[0].readyState == "ended"
      ) {
        //if (this.wakeUp.hasWakeUpBeenDetectedInLastFiveteenSeconds()) {
        this.logger.logWarning([
          "Reloading app as local mediastream has been detected to be broken",
        ]);
        this.stateMgr.reloadVISAVIS(
          "ReloadingAppOnReconnectWithBrokenLocalMediaStream",
          "app is reloading to reestablish local video access after wakeup from sleep or hybernate mode - reconnecting you to your team",
          2000
        );
        return;
      }*/
      //this.wakeUp.registerServerReconnect();
      //this.logger.logError(["Change in socket ID detected from/to: ",this.soDa.getLocalSocketID(),"/",this.soDa.getLocalSocket().id,". Thus re-entering room ",this.soDa.getMainRoom()]);
      //this.joinRoom(this.soDa.getMainRoom());
      this.updateLocalSocketIDAfterConnect();
      this.handleReconnectWithNewSocketIDAfterConnectionLoss();
      //todo: inform peers on existing peer connections about changed IDs (i.e. maps in their data mgmt still hold old IDs.. any other ID dependencies?)
    } else {
      this.updateLocalSocketIDAfterConnect();
      this.handleFirstConnectInSession();
    }
  };

  updateLocalSocketIDAfterConnect = (): void => {
    this.soDa.setLocalSocketID(this.soDa.getLocalSocket().id);
  };

  connectorActionOnSuccessfulClientRegistration = (
    peerUniqueIDConfirmed: string,
    peerPassConfirmed: string,
    passVersionConfirmed: string,
    newIDAndPassAssigned: string
  ): void => {
    this.logger.logLocal([
      "successful registration with server after connect - peerUID, newIDAndPassAssigned: ",
      peerUniqueIDConfirmed,
      ", ",
      newIDAndPassAssigned,
    ]);

    this.settings.setUID(peerUniqueIDConfirmed);
    this.settings.setUIDPass(peerPassConfirmed);
    this.settings.setUIDVersion(passVersionConfirmed);

    this.uniqueClientID = peerUniqueIDConfirmed;
    this.idIsRegisteredWithServer = true;

    //note: next step, i.e. requesting ICE servers happens in calling function

    //TODO - store stuff in any relevant data structures - anything else?
  };

  connectorActionOnDisconnect = () => {
    this.isConnected = false;
    this.idIsRegisteredWithServer = false;
    //this.soDa.setLocalSocketID(null);
  };

  /*private connectivityRecheck = () => {
    if (this.serverConnectionConfirmedSinceStartOfCheck)
      this.performConnectivityCheckCallbacksPos();
    else {
      this.connectorActionOnDisconnect();
      this.performConnectivityCheckCallbacksNeg();
    }
    this.serverConnectionConfirmedSinceStartOfCheck = null;
  };

  private performConnectivityCheckCallbacksPos = () => {
    this.logger.logLocal([
      "Connectivity check with pos result - performing registred pos callbacks",
    ]);
    this.storedCallbacksForConnectionCheckPos.forEach((cbf) => {
      cbf();
      this.storedCallbacksForConnectionCheckPos.delete(cbf);
    });
  };
  private performConnectivityCheckCallbacksNeg = () => {
    this.logger.logLocal([
      "Connectivity check with neg result - performing registred neg callbacks",
    ]);
    this.storedCallbacksForConnectionCheckNeg.forEach((cbf) => {
      cbf();
      this.storedCallbacksForConnectionCheckPos.delete(cbf);
    });
  };

  /*initiateServerConnectionCheck = (
    callBackIfConnected: () => void,
    callBackIfNotConnected: () => void,
    recheckTimeoutMS: number = 1500
  ): void => {
    if (this.isConnectedToServer) {
      this.logger.logLocal(["Initiating connection check with Server"]);
      this.storedCallbacksForConnectionCheckPos.add(callBackIfConnected);
      this.storedCallbacksForConnectionCheckNeg.add(callBackIfNotConnected);
      if (this.serverConnectionConfirmedSinceStartOfCheck == null) {
        this.serverConnectionConfirmedSinceStartOfCheck = false;
        this.soDa.getLocalSocket().emit("connectionCheck");
        setTimeout(() => {
          this.connectivityRecheck();
        }, recheckTimeoutMS);
      } else if (this.serverConnectionConfirmedSinceStartOfCheck == true) {
        //this.serverConnectionConfirmedSinceStartOfCheck = null;
        this.performConnectivityCheckCallbacksPos();
      }
    } else {
      this.logger.logLocal([
        "Connection check with server not initiated as not connected to server",
      ]);
      callBackIfNotConnected();
    }
  };*/

  emitClientReadyToConnect = (room: string, localClientStartedFresh: BOOL) => {
    this.getSocketData()
      .getLocalSocket()
      .emit(
        "clientReadyToConnectViaUID",
        this.getUniqueClientID(),
        room,
        localClientStartedFresh
      );
  };

  emitWillDisconnect = (room: string, reason: string) => {
    this.getSocketData()
      .getLocalSocket()
      .volatile.emit("willDisconnectViaUID", room, reason);
  };

  emitWelcomePleaseConnect = (
    peerID: string,
    room: string,
    localClientStartedFresh: BOOL
  ) => {
    this.getSocketData()
      .getLocalSocket()
      .emit(
        "welcomePleaseConnectViaUID",
        peerID,
        room,
        localClientStartedFresh
      );
  };

  emitCreateOrJoin = (room: string) => {
    this.getSocketData().getLocalSocket().emit("create or joinViaUID", room);
  };

  emitGetIceServers = () => {
    this.getSocketData().getLocalSocket().emit("getIceServersViaUID");
  };

  emitClientServerMessage = (messageType: string, messageAttributes: any) => {
    this.getSocketData().getLocalSocket().emit(
      //"ClientServerMessage",
      messageType,
      messageAttributes
    );
  };

  emit121Message = (
    peerID: string,
    room: string,
    message: string,
    PCSigP: SIGNALING_PURPOSE
  ) => {
    this.getSocketData()
      .getLocalSocket()
      .emit("121messageViaUID", peerID, room, message, PCSigP);
  };

  emit121VCMessage = (
    peerIDRecipient: string,
    room: string,
    peerIDVCHost: string,
    messageType: string,
    messageAttributes: any
  ) => {
    this.getSocketData()
      .getLocalSocket()
      .emit(
        "121VCmessageViaUID",
        peerIDRecipient,
        room,
        peerIDVCHost,
        messageType,
        messageAttributes
      );
  };

  emitActivePing = () => {
    this.getSocketData().getLocalSocket().volatile.emit("activePingViaUID");
  };

  emitReadyToReconnectQuestion = (
    peerID: string,
    room: string,
    pcSigP: SIGNALING_PURPOSE
  ) => {
    this.getSocketData()
      .getLocalSocket()
      .volatile.emit("areYouReadyToReconnectViaUID", peerID, room, pcSigP);
  };

  emitReadyToReconnectAnswer = (
    peerID: string,
    room: string,
    pcSigP: SIGNALING_PURPOSE
  ) => {
    this.getSocketData()
      .getLocalSocket()
      .volatile.emit("iAmReadyToReconnectViaUID", peerID, room, pcSigP);
  };

  isClientRegisteredWithServer = (): boolean => {
    return this.idIsRegisteredWithServer;
  };
}
