export class QueueHandlingRejectionByServerMessageNotAnOwner {
  queueID: string;

  constructor(_queueID: string) {
    this.queueID = _queueID;
  }

  initialize = (): void => {};

  getQueueID = (): string => this.queueID;
}
