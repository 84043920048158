import { BusLogicCore } from "../../buslogic/BusLogicCore";
import { I_IncomingQueueMessageHandler } from "../../buslogic/roomQueue/I_IncomingQueueMessageHandler";
import { I_QueueSignalingInterface } from "../../buslogic/roomQueue/I_QueueSignalingInterface";
import { QueueAllowVisitorInMessage } from "../../buslogic/roomQueue/messages/QueueAllowVisitorInMessage";
import { QueueOwnerRegistrationMessage } from "../../buslogic/roomQueue/messages/QueueOwnerRegistrationMessage";
import { QueueVisitorInfoRequestMessage } from "../../buslogic/roomQueue/messages/QueueVisitorInfoRequestMessage";
import { QueueVisitorRegistrationMessage } from "../../buslogic/roomQueue/messages/QueueVisitorRegistrationMessage";
import { Logger } from "../../utils/Logger";
import { I_ClientServerSignalingProvider } from "./I_ClientServerSignalingProvider";
import { QueueUnregisterUserMessage } from "../../buslogic/roomQueue/messages/QueueUnregisterUserMessage";
import { QueueUnregisterUserFromAllQueuesMessage } from "../../buslogic/roomQueue/messages/QueueUnregisterUserFromAllQueuesMessage";
import { I_MessageListenerFunctionClientServerMessage } from "./I_MessageListenerFunctionClientServerMessage";
import { QueueVisitorRegistrationConfirmationByServerMessage } from "../../buslogic/roomQueue/messages/QueueVisitorRegistrationConfirmationByServerMessage";
import { QueueOwnerRegistrationConfirmationByServerMessage } from "../../buslogic/roomQueue/messages/QueueOwnerRegistrationConfirmationByServerMessage";
import { QueueVisitorPassToRoomMessage } from "../../buslogic/roomQueue/messages/QueueVisitorPassToRoomMessage";
import { QueueVisitorInfoToOwnerMessage } from "../../buslogic/roomQueue/messages/QueueVisitorInfoToOwnerMessage";
import { QueueHandlingRejectionByServerMessageNotAnOwner } from "../../buslogic/roomQueue/messages/QueueHandlingRejectionByServerMessageNotAnOwner";
import { QueueHandlingRejectionByServerMessageNotAVisitor } from "../../buslogic/roomQueue/messages/QueueHandlingRejectionByServerMessageNoOwnerAccess copy";

export class SignalingQueue implements I_QueueSignalingInterface {
  private logger: Logger;
  private signaling: I_ClientServerSignalingProvider;
  private busLogic: BusLogicCore;

  private queueMessageHandler: I_IncomingQueueMessageHandler;

  constructor(_logger: Logger) {
    this.logger = _logger;
  }

  initialize = (_signaling: I_ClientServerSignalingProvider) => {
    this.signaling = _signaling;
    this.signaling.registerClientServerMessageListener(
      "visitorRegistrationConfirmed",
      this.processQueueMessageVisitorRegistrationConfirmed
    );
    this.signaling.registerClientServerMessageListener(
      "ownerRegistrationConfirmed",
      this.processQueueMessageOwnerRegistrationConfirmed
    );
    this.signaling.registerClientServerMessageListener(
      "noOwnerAccessToQueue",
      this.processQueueMessageServerRejectionNotAnOwnwer
    );
    this.signaling.registerClientServerMessageListener(
      "visitorPassToRoom",
      this.processQueueMessageVisitorPassToRoom
    );
    this.signaling.registerClientServerMessageListener(
      "visitorNotInQueue",
      this.processQueueMessageServerRejectionNotAVisitor
    );
    this.signaling.registerClientServerMessageListener(
      "visitorInfoOfQueue",
      this.processQueueMessageVisitorInfoOfQueue
    );
  };

  setBusLogicReference(_busLogic: BusLogicCore) {
    this.busLogic = _busLogic;
  }

  //QueueMgr instance registers itself as recipient of queue-related messages
  registerIncomingMessageHandler = (
    queueHandler: I_IncomingQueueMessageHandler
  ): void => {
    this.queueMessageHandler = queueHandler;
  };

  requestToServerToJoinQueueAsVisitor = (
    joinRequestMsg: QueueVisitorRegistrationMessage
  ): void => {
    this.signaling.sendClientServerMessage("registerAsQueueVisitor", {
      queueID: joinRequestMsg.getQueueIDHash(),
      userLabel: joinRequestMsg.getUserLabel(),
    });
  };

  requestToServerToJoinQueueAsOwner = (
    joinRequestMsg: QueueOwnerRegistrationMessage
  ): void => {
    this.signaling.sendClientServerMessage("registerAsQueueOwner", {
      queueName: joinRequestMsg.getQueueIDClearText(),
      userLabel: joinRequestMsg.getUserLabel(),
    });
  };

  instructServerToAllowVisitorIn = (
    allowInMsg: QueueAllowVisitorInMessage
  ): void => {
    this.signaling.sendClientServerMessage("allowVisitorIn", {
      queueID: allowInMsg.getQueueIDHash(),
      visitorUID: allowInMsg.getPeerUID(),
      roomName: allowInMsg.getRoomName(),
    });
  };

  requestVisitorInfoFromServer = (
    visitorInfoRequestMsg: QueueVisitorInfoRequestMessage
  ): void => {
    this.signaling.sendClientServerMessage("getVisitorInfoFromQueue", {
      queueID: visitorInfoRequestMsg.getQueueIDHash(),
    });
  };

  requestToServerToUnregisterUserFromQueue = (
    unregisterRequestMsg: QueueUnregisterUserMessage
  ): void => {
    this.signaling.sendClientServerMessage("leaveQueue", {
      queueID: unregisterRequestMsg.getQueueIDHash(),
    });
  };

  requestToServerToUnregisterUserFromAllQueues = (
    unregisterRequestMsg: QueueUnregisterUserFromAllQueuesMessage
  ): void => {
    this.signaling.sendClientServerMessage("leaveAllQueues", {});
  };

  private processQueueMessageVisitorRegistrationConfirmed: I_MessageListenerFunctionClientServerMessage =
    (messageAttributes: any): void => {
      try {
        this.queueMessageHandler.handleIncomingVisitorRegistrationConfirmationMessage(
          new QueueVisitorRegistrationConfirmationByServerMessage(
            messageAttributes.queueID
          )
        );
      } catch (error) {
        this.logger.logError([
          "Error processing incoming queue message - visitor registration confirmed",
          error.toString(),
        ]);
      }
    };

  private processQueueMessageOwnerRegistrationConfirmed: I_MessageListenerFunctionClientServerMessage =
    (messageAttributes: any): void => {
      try {
        this.queueMessageHandler.handleIncomingOwnerRegistrationConfirmationMessage(
          new QueueOwnerRegistrationConfirmationByServerMessage(
            messageAttributes.queueName,
            messageAttributes.queueID
          )
        );
      } catch (error) {
        this.logger.logError([
          "Error processing incoming queue message - owner registration confirmed",
          error.toString(),
        ]);
      }
    };

  private processQueueMessageServerRejectionNotAnOwnwer: I_MessageListenerFunctionClientServerMessage =
    (messageAttributes: any): void => {
      try {
        this.queueMessageHandler.handleIncomingHandlingRejectionByServerMessageNotAnOwner(
          new QueueHandlingRejectionByServerMessageNotAnOwner(
            messageAttributes.queueID
          )
        );
      } catch (error) {
        this.logger.logError([
          "Error processing incoming queue message - server rejection no access",
          error.toString(),
        ]);
      }
    };

  private processQueueMessageServerRejectionNotAVisitor: I_MessageListenerFunctionClientServerMessage =
    (messageAttributes: any): void => {
      try {
        this.queueMessageHandler.handleIncomingHandlingRejectionByServerMessageNotAVisitor(
          new QueueHandlingRejectionByServerMessageNotAVisitor(
            messageAttributes.queueID,
            messageAttributes.visitorUID
          )
        );
      } catch (error) {
        this.logger.logError([
          "Error processing incoming queue message - server rejection visitor not in queue",
          error.toString(),
        ]);
      }
    };

  private processQueueMessageVisitorPassToRoom: I_MessageListenerFunctionClientServerMessage =
    (messageAttributes: any): void => {
      try {
        this.queueMessageHandler.handleIncomingVisitorPassToRoomMessage(
          new QueueVisitorPassToRoomMessage(
            messageAttributes.queueID,
            messageAttributes.roomID,
            messageAttributes.sessionPass
          )
        );
      } catch (error) {
        this.logger.logError([
          "Error processing incoming queue message - visitor pass to room",
          error.toString(),
        ]);
      }
    };

  private processQueueMessageVisitorInfoOfQueue: I_MessageListenerFunctionClientServerMessage =
    (messageAttributes: any): void => {
      try {
        this.logger.logLocal([
          "Received visitor information from server: ",
          messageAttributes,
          messageAttributes.visitors,
        ]);
        this.queueMessageHandler.handleIncomingVisitorInfoToOwnerMessage(
          new QueueVisitorInfoToOwnerMessage(
            messageAttributes.queueID,
            new Set(JSON.parse(messageAttributes.visitors)),
            new Map(Object.entries(messageAttributes.userLabels)),
            new Map(Object.entries(messageAttributes.joinTimes))
          )
        );
      } catch (error) {
        this.logger.logError([
          "Error processing incoming queue message - visitor info of queue",
          error.toString(),
        ]);
      }
    };
}
