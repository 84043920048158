import { ROOM_CONTEXT } from "data/enums/ROOM_CONTEXT";
import { SettingStorage } from "data/SettingStorage";
import { UIHandler } from "frontend/UIHandler";
import { SignalingCore } from "signaling/SignalingCore";
import { Helpers } from "utils/Helpers";
import { UIMessenger } from "utils/UIMessenger";
import { I_IncomingUserNameLabelMessageHandler } from "./I_IncomingUserNameLabelMessageHandler";
import { UserNameLabelMessage } from "./UserNameLabelMessage";

export class UserNameLabel implements I_IncomingUserNameLabelMessageHandler {
  private settings: SettingStorage;
  private help: Helpers;
  private signaling: SignalingCore;
  private uiMessenger: UIMessenger;
  private uiHandler: UIHandler;

  private userNameLabels: Map<string, string>;

  constructor(
    _settings: SettingStorage,
    _help: Helpers,
    _signaling: SignalingCore,
    _uiMessenger: UIMessenger,
    _uiHandler: UIHandler
  ) {
    this.settings = _settings;
    this.help = _help;
    this.signaling = _signaling;
    this.uiMessenger = _uiMessenger;
    this.uiHandler = _uiHandler;
  }

  initialize = () => {
    this.userNameLabels = new Map<string, string>();
    this.signaling
      .getUserNameLabelSignaling()
      .registerIncomingMessageHandler(this);
  };

  private informAllPeersAboutLocalUserNameLabel = (): void => {
    this.signaling
      .getRooms()
      .getRegisteredPeersFromAllRooms()
      .forEach((peerID) => {
        this.informSinglePeerAboutLocalUserNameLabel(peerID);
      });
  };

  private informSinglePeerAboutLocalUserNameLabel = (peerID: string): void => {
    if (!this.signaling.getRooms().peerIsRegisteredInAtLeastOneRoom(peerID))
      return; //do not send user name message if peer no longer connected
    let localLabel = this.getLocalUserNameLabel();
    let peersRoom: string = this.signaling
      .getRooms()
      .getValidRoomForPeer(peerID);

    if (localLabel != "" && peersRoom != null) {
      this.signaling
        .getUserNameLabelSignaling()
        .shareUserNameLabelWithPeer(
          new UserNameLabelMessage(
            this.signaling.getUniqueClientID(),
            peerID,
            localLabel
          ),
          peersRoom
        );
    }
  };

  getLocalUserNameLabel = (fallBackLabel: string = "..."): string => {
    let localLabel = fallBackLabel;
    if (this.help.isWebApp() && this.help.URLParameterPresent("username")) {
      localLabel = this.help.getURLParameter("username");
    } else if (
      this.settings.existsUserUILabel() &&
      this.settings.getUserUILabel() != ""
      //not loading web meet specific setting UserUILabelMeet at this point, as web meeting client always pulls this setting via URL parameter above
    ) {
      localLabel = this.settings.getUserUILabel();
    }
    return localLabel;
  };

  handleIncomingUserNameLabelMessage = (
    incomingMsg: UserNameLabelMessage
  ): void => {
    this.userNameLabels.set(
      incomingMsg.getSenderID(),
      incomingMsg.getUserNameLabel()
    );
    this.updateUserNameLabelOnUIForPeer(incomingMsg.getSenderID());
  };

  hasUserNameLabelForPeer = (peerID: string): boolean => {
    return this.userNameLabels != null && this.userNameLabels.has(peerID);
  };

  getUserNameLabelForPeer = (peerID: string): string => {
    if (this.userNameLabels.has(peerID)) return this.userNameLabels.get(peerID);
    else return ". . .";
  };

  updateUserNameLabelOnUIForPeer = (peerID: string) => {
    let userNameLabel = this.getUserNameLabelForPeer(peerID);
    if (
      this.uiHandler.getAppUIHTML().getSideBarHTML().hasRegisteredPeerVS(peerID)
    ) {
      this.uiHandler
        .getAppUIHTML()
        .getSideBarHTML()
        .getPeerHTMLLabelElement(peerID).innerText = userNameLabel;
    }
    if (
      this.uiHandler
        .getAppUIHTML()
        .getVCScreenHTML()
        .hasRegisteredPeerVSVC(peerID)
    ) {
      this.uiHandler
        .getAppUIHTML()
        .getVCScreenHTML()
        .getPeerHTMLLabelElementVC(peerID).innerText = userNameLabel;
    }
  };

  updateUserNameLabelOnUIForLocalClient = () => {
    let localLabel: string = this.getLocalUserNameLabel("me");
    this.uiHandler
      .getAppUIHTML()
      .getSideBarHTML()
      .getLocalHTMLLabelElement().innerText = localLabel;
    if (this.help.appIsActive())
      this.uiHandler
        .getAppUIHTML()
        .getVCScreenHTML()
        .getLocalHTMLLabelElementVC().innerText = localLabel;
  };

  informAllPeersAboutLocalUserNameLabelTimeout = (
    timeout1: number = 2000,
    timeout2: number = 6000
  ): void => {
    //delayed submission of user label to peers - timeout 1 to submit - timeout 2 to catch exceptions if a connection had not been ready by the time of timeout 1
    if (timeout1 != null) {
      setTimeout(() => {
        this.informAllPeersAboutLocalUserNameLabel();
      }, timeout1);
    }
    if (timeout2 != null) {
      setTimeout(() => {
        this.informAllPeersAboutLocalUserNameLabel();
      }, timeout2);
    }
  };

  informSinglePeerAboutLocalUserNameLabelTimeout = (
    peerID: string,
    timeout1: number = 2000,
    timeout2: number = 6000
  ): void => {
    //delayed submission of user label to peers - timeout 1 to submit - timeout 2 to catch exceptions if a connection had not been ready by the time of timeout 1
    if (timeout1 != null) {
      setTimeout(() => {
        this.informSinglePeerAboutLocalUserNameLabel(peerID);
      }, timeout1);
    }
    if (timeout2 != null) {
      setTimeout(() => {
        this.informSinglePeerAboutLocalUserNameLabel(peerID);
      }, timeout2);
    }
  };

  updateUserNameLabelOnUIForPeerTimeout = (
    peerID: string,
    timeout1: number = 1000,
    timeout2: number = 3000
  ): void => {
    //delayed submission of user label to peers - timeout 1 to submit - timeout 2 to catch exceptions if a connection had not been ready by the time of timeout 1
    if (timeout1 != null) {
      setTimeout(() => {
        this.updateUserNameLabelOnUIForPeer(peerID);
      }, timeout1);
    }
    if (timeout2 != null) {
      setTimeout(() => {
        this.updateUserNameLabelOnUIForPeer(peerID);
      }, timeout2);
    }
  };
}
