/* COPYRIGHT 2021 Michael Maur - any form of reuse requires written consent by the author */
import { BusLogicVC } from "../buslogic/BusLogicVC";
import { Config } from "../config/Config";
import { DataMgmt } from "../data/DataMgmt";
import { SettingStorage } from "../data/SettingStorage";
import { SignalingCore } from "./SignalingCore";
import { UIHandler } from "../frontend/UIHandler";
import { Logger } from "../utils/Logger";
import { UIMessenger } from "../utils/UIMessenger";
import { SIGNALING_PURPOSE } from "../data/enums/SIGNALING_PURPOSE";
import { SignalingChat } from "./featureSignalingVC/SignalingChat";
import { Helpers } from "../utils/Helpers";
import { BOOL } from "../data/enums/BOOL";
import { AppContextHandler } from "infra/AppContextHandler";
import { RoomDataManager } from "data/RoomDataManager";
//import { Serializer } from "./Serializer";

export class SignalingVC extends SignalingCore {
  //private logger: Logger;
  //private uiMessenger: UIMessenger;
  //private data: DataMgmt;
  //private signalingCore: Signaling;

  protected busLogic: BusLogicVC;
  private uiHandler: UIHandler;
  private chatSig: SignalingChat;

  constructor(
    _help: Helpers,
    _context: AppContextHandler,
    _config: Config,
    _settings: SettingStorage,
    _logger: Logger,
    _uiMessenger: UIMessenger,
    _data: DataMgmt,
    _rooms: RoomDataManager
  ) {
    super(
      _help,
      _context,
      _config,
      _settings,
      _logger,
      _uiMessenger,
      _data,
      _rooms
    );
    console.log("Local client loading module: signalingVC");
    this.chatSig = new SignalingChat();
  }

  initialize = (_busLogic: BusLogicVC, _uiHandler: UIHandler) => {
    this.initializeCore(_uiHandler);

    this.setBusLogicReference(_busLogic);
    this.setUIHandlerReference(_uiHandler);

    this.configureSocketOnResponsesVC();

    this.chatSig.initialize(this, _busLogic);
  };

  protected setBusLogicReference(_busLogic: BusLogicVC) {
    super.setBusLogicReference(_busLogic);
    //this.busLogic = _busLogic;
  }

  private setUIHandlerReference = (_uiHandler: UIHandler) => {
    this.uiHandler = _uiHandler;
  };

  //////////////////////////////////////////////////////////////////
  // VC Signaling between VC host and participants
  //////////////////////////////////////////////////////////////////

  // this sends a message to a peer - if no direct data stream with this peer is established, it sends the message via the signaling server
  sendVC121Message = (
    peerID: string,
    messageType: string,
    messageAttributes
  ) => {
    //@TODO: signaling to only go via server if no direct p2p connection available
    //logLocal(['Local client ', this.soDa.getLocalSocketID(), ' sending VC 121 message for client ', peerID, ' via signaling server >> ', this.busLogic.getVCDataMgr().getVCCurrentHost(), messageType, messageAttributes]);
    this.serverConnector.emit121VCMessage(
      peerID,
      //this.getMainRoomID(),
      this.rooms.getActiveMeetingRoomID(), //enable ext meeting rooms in addition to meetings inside team room
      this.busLogic.getVCDataMgr().getVCCurrentHost(),
      messageType,
      messageAttributes
    );
  };

  // when the local client opens a VC, it invites the person with whom it opens the VC to join
  inviteToJoinVC121 = (peerID: string) => {
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending <121VCInvite> to client ",
      peerID,
      " regarding VC hosted by ",
      this.getUniqueClientID(),
      " with VCType ",
      this.busLogic.getVCDataMgr().getVCCurrentType(),
    ]);
    //sendVC121Message(peerID, '121VCInvite', null);
    this.sendVC121Message(peerID, "121VCInvite", {
      type: this.busLogic.getVCDataMgr().getVCCurrentType(),
    });
  };

  // when a VC participant other than the host of the VC invites a peer, this request is sent to the host for (automated) confirmation / forwarding
  suggestToInviteToVC121 = (peerID: string) => {
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending <121VCInviteSuggestion> to VC host (",
      this.busLogic.getVCDataMgr().getVCCurrentHost(),
      ") >> ",
      peerID,
      this.getUniqueClientID(),
    ]);
    this.sendVC121Message(
      this.busLogic.getVCDataMgr().getVCCurrentHost(),
      "121VCInviteSuggestion",
      {
        peerToInvite: peerID,
      }
    );
  };

  // when the local client joined a VC which it was invited to, it confirms back to the host
  confirmLocalClientJoinedVC121 = () => {
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending <121VCClientJoined> to VC host (",
      this.busLogic.getVCDataMgr().getVCCurrentHost(),
      ") >> ",
      this.getUniqueClientID(),
    ]);
    this.sendVC121Message(
      this.busLogic.getVCDataMgr().getVCCurrentHost(),
      "121VCClientJoined",
      null
    );
  };

  // when the local client left a VC, it confirms this back to the host
  confirmLocalClientLeftVC121 = (
    rationale: string,
    noNewConnectionAsEitherLocalOrRemotePeersDisconnecting: boolean = false
  ) => {
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending <121VCClientLeft> to VC host (",
      this.busLogic.getVCDataMgr().getVCCurrentHost(),
      ") >> ",
      this.getUniqueClientID(),
      rationale,
    ]);
    let tmpSkipNewConnection = this.help.boolean2BOOL(
      noNewConnectionAsEitherLocalOrRemotePeersDisconnecting
    );
    this.sendVC121Message(
      this.busLogic.getVCDataMgr().getVCCurrentHost(),
      "121VCClientLeft",
      {
        leaverRationale: rationale,
        skipNewConnection: tmpSkipNewConnection,
      }
    );
  };

  __rationale_InOtherVC = "User is already in a conversation";
  __rationale_AlreadyInThisVC =
    "User has already joined this conversation / cannot join twice";
  __rationale_VCFull = "This conversation has reached its max user limit";
  __rationale_UserChoseToLeave = "User left video conference";
  __rationale_AdditionalUsersExceedVCLimit =
    "The two conversations cannot be merged as this would exceed the max user limit";
  __rationale_AboutToJoinAVC =
    "User could not be added as it is in the process of joining a VC - you may want to try again in a couple of seconds";
  __rationale_DuplicativeRequest = this.__rationale_AboutToJoinAVC; // user not interested in "duplicate request" story
  __notApplicableToVCHost = "message type not applicable to VC host";
  __notApplicableToVCParticipant =
    "message type not applicable to client that is participating in a VC (non-host)";
  __notApplicableToClientsNotInVC =
    "message type not applicable to clients that are not part of a VC";
  __notAuthorizedToChangeVCHost = "sender not authorized to change VC host";

  // when a local client is not joining a VC which it was invited to, it sends a respective response back to the VC host - incl a reason (e.g. already in another VC);
  respondLocalClientNotJoiningVC121 = (hostID: string, rationale: string) => {
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending <121VCClientNotJoining> to VC host (",
      this.busLogic.getVCDataMgr().getVCCurrentHost(),
      ") >> ",
      this.getUniqueClientID(),
      rationale,
    ]);
    if (this.busLogic.getVCDataMgr().isVCOpen()) {
      this.sendVC121Message(hostID, "121VCClientNotJoining", {
        rejectionRationale: rationale,
        hostOfActiveVC: this.busLogic.getVCDataMgr().getVCCurrentHost(),
        participantCountActiveVC:
          this.busLogic.getVCDataMgr().getVCPeers().length + 1,
      });
    } else {
      this.sendVC121Message(hostID, "121VCClientNotJoining", {
        rejectionRationale: rationale,
      });
    }
  };

  // when host received feedback from an invited client that it will not be joining, it informs all peers (or only invite originator) in VC to ensure transparency
  informParticipantsOfUnsuccessfulInviteVC121 = (
    peerID: string,
    rationale: string,
    _informAll: boolean = true,
    _hostOfActiveVC: string = "noActiveVC",
    _participantCountActiveVC: number = 0
  ) => {
    let inviteOrig = this.busLogic
      .getVCDataMgr()
      .getPendingVCInviteOriginator(peerID);
    let VCPs = null;
    if (_informAll) {
      this.logger.logLocal([
        "Local client ",
        this.getUniqueClientID(),
        " sending <121VCUnsuccessfulInvite> wrt client ",
        peerID,
        " to all participants of VC hosted by ",
        this.getUniqueClientID(),
        " >> rationale: ",
        rationale,
      ]);
      VCPs = this.busLogic
        .getVCDataMgr()
        .getVCPeers()
        .concat(this.busLogic.getVCDataMgr().getConfirmedVCJoiners());
    } else {
      this.logger.logLocal([
        "Local client ",
        this.getUniqueClientID(),
        " sending <121VCUnsuccessfulInvite> wrt client ",
        peerID,
        " to invite oritinator ",
        inviteOrig,
        " >> rationale: ",
        rationale,
      ]);
      VCPs = [inviteOrig];
    }
    for (let i = 0; i < VCPs.length; i++) {
      if (VCPs[i] != this.getUniqueClientID() && VCPs[i] != peerID) {
        this.sendVC121Message(VCPs[i], "121VCUnsuccessfulInvite", {
          rejectedPeer: peerID,
          inviteOriginator: inviteOrig,
          rejectionRationale: rationale,
          hostOfActiveVC: _hostOfActiveVC,
          participantCountActiveVC: _participantCountActiveVC,
        });
      }
    }
  };

  // if the local client is the host of a VC, it advises current participants of new-joiners
  adviseVCParticipantsOfNewParticipant121 = (peerID: string) => {
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending <121VCNewPeerToBeAdded> wrt client ",
      peerID,
      " to all participants of VC hosted by ",
      this.getUniqueClientID(),
    ]);
    let VCPs = this.busLogic
      .getVCDataMgr()
      .getVCPeers()
      .concat(this.busLogic.getVCDataMgr().getConfirmedVCJoiners());
    for (let i = 0; i < VCPs.length; i++) {
      if (VCPs[i] != this.getUniqueClientID() && VCPs[i] != peerID) {
        this.sendVC121Message(VCPs[i], "121VCNewPeerToBeAdded", {
          newPeer: peerID,
        });
        //sendVC121Message(peerID, '121VCNewPeerToBeAdded', {newPeer: VCPs[i]}); //also advise new peer of existing peers / advise him to add peers
      }
    }
  };

  // if the local client is the host of a VC, it advises current participants of peers that left the VC
  adviseVCParticipantsOfRemovedParticipant121 = (
    peerID: string,
    rationale: string,
    noNewConnectionAsEitherLocalOrRemotePeersDisconnecting: boolean = false
  ) => {
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending <121VCPeerToBeRemoved> wrt client ",
      peerID,
      " to all participants of VC hosted by ",
      this.getUniqueClientID(),
      " >> ",
      rationale,
    ]);
    let VCPs = this.busLogic
      .getVCDataMgr()
      .getVCPeers()
      .concat(this.busLogic.getVCDataMgr().getConfirmedVCJoiners());
    let tmpSkipNewConnection: BOOL = this.help.boolean2BOOL(
      noNewConnectionAsEitherLocalOrRemotePeersDisconnecting
    );
    for (let i = 0; i < VCPs.length; i++) {
      if (VCPs[i] != this.getUniqueClientID() && VCPs[i] != peerID) {
        this.sendVC121Message(VCPs[i], "121VCPeerToBeRemoved", {
          peerToBeRemoved: peerID,
          removalRationale: rationale,
          skipNewConnection: tmpSkipNewConnection,
        });
      }
    }
  };

  // if the local client is the host of a VC, it informs current participants when the VC gets closed by the host
  adviseVCParticipantsOfVCClosure121 = (
    noNewConnectionAsEitherLocalOrRemotePeersDisconnecting: boolean = false
  ) => {
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending <121VCToBeClosed> to all participants of VC hosted by ",
      this.getUniqueClientID(),
    ]);
    let VCPs = this.busLogic
      .getVCDataMgr()
      .getVCPeers()
      .concat(this.busLogic.getVCDataMgr().getConfirmedVCJoiners());
    let tmpSkipNewConnection = this.help.boolean2BOOL(
      noNewConnectionAsEitherLocalOrRemotePeersDisconnecting
    );
    for (let i = 0; i < VCPs.length; i++) {
      if (VCPs[i] != this.getUniqueClientID()) {
        this.sendVC121Message(VCPs[i], "121VCToBeClosed", {
          skipNewConnection: tmpSkipNewConnection,
        });
      }
    }
  };

  // when leaving a VC or when handing over host entitlements, the host can nominate a new host - a co-host nominated at the same time serves as a back-up - i.e. participants will acceppt new host nominations from his end should the host have dropped off without nominating a new host
  adviseVCParticipantsOfNewHost121 = (newHost: string, newCoHost: string) => {
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending <121newHost> to all participants of VC >> New host and co-host are: ",
      newHost,
      newCoHost,
    ]);
    let VCPs = this.busLogic
      .getVCDataMgr()
      .getVCPeers()
      .concat(this.busLogic.getVCDataMgr().getConfirmedVCJoiners());
    for (let i = 0; i < VCPs.length; i++) {
      if (VCPs[i] != this.getUniqueClientID()) {
        this.sendVC121Message(VCPs[i], "121NewHost", {
          host: newHost,
          coHost: newCoHost,
        });
      }
    }
  };

  // when new peer joins VC, it is informed about host and coHost
  adviseIndividualVCParticipantOfHost121 = (
    _peerIDToBeInformed: string,
    newHost: string,
    newCoHost: string
  ) => {
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending <121newHost> to peer ",
      _peerIDToBeInformed,
      " >> New host and co-host are: ",
      newHost,
      newCoHost,
    ]);
    this.sendVC121Message(_peerIDToBeInformed, "121NewHost", {
      host: newHost,
      coHost: newCoHost,
    });
  };

  // prior actually performing a VC merge, ask other party whether it will wait / can accomodate the incoming crowd
  requestPreValidationOfJoiningActiveVC121 = (
    _mergeRequestOriginator: string,
    _activeVCHost: string,
    _peersRequestingAccess: string[] = this.busLogic
      .getVCDataMgr()
      .getVCPeers()
      .concat(this.busLogic.getVCDataMgr().getConfirmedVCJoiners())
      .concat([this.getUniqueClientID()])
  ) => {
    this.busLogic
      .getVCDataMgr()
      .registerOutgoingMerge(
        _mergeRequestOriginator,
        _activeVCHost,
        _peersRequestingAccess
      );
    let _host = this.busLogic.getVCDataMgr().getVCCurrentHost();
    if (_host != _activeVCHost) {
      this.logger.logLocal([
        "Local client ",
        this.getUniqueClientID(),
        " sending <121preValidationOfRequestToJoinActiveVC> to active VC host: ",
        _activeVCHost,
        " >> access is requested for the following clients: ",
        _peersRequestingAccess,
      ]);
      this.sendVC121Message(
        _activeVCHost,
        "121preValidationOfRequestToJoinActiveVC",
        {
          peersRequestingAccess: _peersRequestingAccess,
        }
      );
    } else {
      this.logger.logWarning([
        "local client is dropping request to access another active VC - current VC and active VC both appear to be under the same host - this can occur when two VC merges happen at the same time, i.e. when a client is about to join a VC which at the same time wants to merge with the client's VC.",
      ]);
    }
  };

  // prior actually performing a VC merge, ask other party whether it will wait / can accomodate the incoming crowd
  confirmPreValidationOfJoiningActiveVC121 = (
    _externalVCHostRequestingToJoin: string,
    _peersRequestingAccess: string[],
    _confirmedNumberOfNewPeers: number
  ) => {
    this.busLogic
      .getVCDataMgr()
      .registerIncomingMerge(
        _externalVCHostRequestingToJoin,
        _peersRequestingAccess
      );

    let _host = this.busLogic.getVCDataMgr().getVCCurrentHost();
    if (_host != _externalVCHostRequestingToJoin) {
      this.logger.logLocal([
        "Local client ",
        this.getUniqueClientID(),
        " sending <121preAuthorizationGrantedToMergeWithActiveVC> to active VC host: ",
        _externalVCHostRequestingToJoin,
        " >> access is requested for the following clients: ",
        _peersRequestingAccess,
      ]);
      this.sendVC121Message(
        _externalVCHostRequestingToJoin,
        "121preAuthorizationGrantedToMergeWithActiveVC",
        {
          peersRequestingAccess: _peersRequestingAccess,
          confirmedNumberOfNewPeers: _confirmedNumberOfNewPeers,
        }
      );
    } else {
      this.logger.logWarning([
        "local client is dropping request to confirm pre-validation of an incoming merge request - current VC and external VC both appear to be under the same host - this can occur when two VC merges happen at the same time, i.e. when a client is about to join a VC which at the same time wants to merge with the client's VC.",
      ]);
    }
  };

  // if client is not part of active VC, it asks the host whether it may join
  requestAccessToActiveVC121 = (
    _activeVCHost: string,
    _peersRequestingAccess: string[] = this.busLogic
      .getVCDataMgr()
      .getVCPeers()
      .concat(this.busLogic.getVCDataMgr().getConfirmedVCJoiners())
      .concat([this.getUniqueClientID()])
  ) => {
    //this.data.registerJoinOrMergeRequest();
    let _host = this.busLogic.getVCDataMgr().getVCCurrentHost();
    if (_host !== _activeVCHost) {
      this.logger.logLocal([
        "Local client ",
        this.getUniqueClientID(),
        " sending <121requestAccessToActiveVC> to host of this VC: ",
        _activeVCHost,
        " >> access is requested for the following clients: ",
        _peersRequestingAccess,
      ]);
      this.sendVC121Message(_activeVCHost, "121requestAccessToActiveVC", {
        peersRequestingAccess: _peersRequestingAccess,
      });
    } else {
      this.logger.logWarning([
        "local client is dropping request to access another active VC - current VC and active VC both appear to be under the same host - this can occur when two VC merges happen at the same time, i.e. when a client is about to join a VC which at the same time wants to merge with the client's VC.",
      ]);
    }
  };

  // if host of active VC needs to reject the request of an external client (not part of VC yet) to join the VC
  informRequestorOfUnsuccessfulRequestToJoinActiveVC121 = (
    _requestor: string,
    _rationale: string
  ) => {
    this.logger.logLocal([
      "Local client (=VC host) ",
      this.getUniqueClientID(),
      " sending <121informRequestorOfUnsuccessfulRequestToJoinActiveVC> to requesting client: ",
      _requestor,
      " >> rationale: ",
      _rationale,
    ]);
    this.sendVC121Message(
      _requestor,
      "121informRequestorOfUnsuccessfulRequestToJoinActiveVC",
      {
        rejectionRationale: _rationale,
        participantCountActiveVC:
          this.busLogic.getVCDataMgr().getVCPeers().length + 1,
      }
    );
  };

  // if host of active VC needs to reject a duplicative request of an external client to join the VC
  informRequestorOfDuplicativeRequestToJoinVC121 = (
    _requestor: string,
    _failedInvitee: string
  ) => {
    this.logger.logLocal([
      "Local client (=VC host) ",
      this.getUniqueClientID(),
      " sending <121informRequestorOfDuplicativeRequestToJoinVC> to requesting client: ",
      _requestor,
      " >> rationale: ",
      this.__rationale_DuplicativeRequest,
    ]);
    this.sendVC121Message(
      _requestor,
      "121informRequestorOfDuplicativeRequestToJoinVC",
      {
        rejectionRationale: this.__rationale_DuplicativeRequest,
        failedInvitee: _failedInvitee,
      }
    );
  };

  // client can request its VC host to merge with other active VC in order to connect participants with one another
  requestHostToMergeWithOtherActiveVC = (
    _otherActiveVCHost: string,
    _otherActiveVCParticipantCount: number
  ) => {
    this.busLogic
      .getVCDataMgr()
      .registerOutgoingMerge(
        this.getUniqueClientID(),
        _otherActiveVCHost,
        this.busLogic
          .getVCDataMgr()
          .getVCPeers()
          .concat([this.getUniqueClientID()])
      );
    //this.data.registerJoinOrMergeRequest();
    let _host = this.busLogic.getVCDataMgr().getVCCurrentHost();
    if (_host !== _otherActiveVCHost) {
      this.logger.logLocal([
        "Local client ",
        this.getUniqueClientID(),
        " sending <121requestToMergeWithOtherActiveVC> to current VC host ",
        _host,
        " >> other host: ",
        _otherActiveVCHost,
      ]);
      this.sendVC121Message(_host, "121requestToMergeWithOtherActiveVC", {
        hostOfActiveVC: _otherActiveVCHost,
        participantCountActiveVC: _otherActiveVCParticipantCount,
      });
    } else {
      this.logger.logWarning([
        "local client is dropping request to merge VC with other VC - both under the same host - this can occur when two VC merges happen at the same time, i.e. when a client is about to join a VC which at the same time wants to merge with the client's VC.",
      ]);
    }
  };

  // when a local client received a message from a peer that is not applicable to its situation (e.g. normal peer receives host-only message), it can respond to the sender, indicating mismatch
  respondToInadequateMessageVC121 = (
    peerID: string,
    messageType: string,
    messageAttributes,
    rationale: string
  ) => {
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending <121VCInadequateMessageResponse> to sender of inadequate message (",
      peerID,
      ") >> messageType / rationale: ",
      messageType,
      "/",
      rationale,
    ]);
    this.sendVC121Message(peerID, "121VCInadequateMessageResponse", {
      receiver: this.getUniqueClientID(),
      type: messageType,
      attributes: messageAttributes,
      rejectionRationale: rationale,
    });
  };

  //when local client wants to share its screen, this needs to be requested from host prior starting to connect with other - host needs to initiate connection attempt
  offerScreenSharingToVCHost121 = () => {
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending <121ScreenSharingOffer> to host ",
      this.busLogic.getVCDataMgr().getVCCurrentHost(),
    ]);
    this.sendVC121Message(
      this.busLogic.getVCDataMgr().getVCCurrentHost(),
      "121ScreenSharingOffer",
      null
    );
  };

  //when local client wants to share its screen, this needs to be requested from host prior starting to connect with other - host subsequently calls the below function to initiate connection attempt
  informParticipantsOfScreenSharingOffer = (peerID: string) => {
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending <121VCScreenSharingOfferToBeConnected> to all participants of VC hosted by ",
      this.busLogic.getVCDataMgr().getVCCurrentHost(),
    ]);
    for (let VCPeer of this.busLogic.getVCDataMgr().getVCPeers()) {
      //note: VC info to confirmed joiners is sent after them joining in track-added methos
      if (VCPeer != peerID) {
        //replaced by the below //sendVC121Message(VCPs[i], '121VCScreenSharingOfferToBeConnected', {ScreenSharePeer: peerID});
        this.informSingleParticipantsOfScreenSharingOffer(VCPeer, peerID);
      }
    }
    //sending to oneself appears to not work
    //sendVC121Message(this.getUniqueClientID(), '121VCScreenSharingOfferToBeConnected', {ScreenSharePeer: peerID});
    this.initiateConnectionToScreenSharePeer(peerID);
  };

  //inform a single participant of screen sharing offer
  informSingleParticipantsOfScreenSharingOffer = (
    peerIDToBeInformed: string,
    peerIDOfferingScreenShare: string
  ) => {
    this.sendVC121Message(
      peerIDToBeInformed,
      "121VCScreenSharingOfferToBeConnected",
      {
        ScreenSharePeer: peerIDOfferingScreenShare,
      }
    );
  };

  //when local client wants to share its screen, this needs to be requested from host prior starting to connect with other - host needs to initiate connection attempt
  informParticipantsOfScreenSharingEnd = (peerID: string) => {
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending <121VCScreenSharingEnded> to all participants of VC hosted by ",
      this.busLogic.getVCDataMgr().getVCCurrentHost(),
    ]);
    for (let VCPeer of this.busLogic.getVCDataMgr().getVCPeers()) {
      //no need to inform confirmed joiners as those have not been connected to screen share yet
      if (VCPeer != peerID) {
        this.sendVC121Message(VCPeer, "121VCScreenSharingEnded", {
          ScreenSharePeer: peerID,
        });
      }
    }
  };

  initiateConnectionToScreenSharePeer = (peer: string) => {
    if (peer !== this.getUniqueClientID()) {
      // request to connect to screenshare can be received by host and participants
      this.logger.logLocal([
        "Local client now connecting to peer ",
        peer,
        " to obtain screenshare stream",
      ]);
      this.uiMessenger.informUser(
        "Another user started to share his screen - connecting in a few seconds... try double-clicking for full-screen"
      );

      //cleanup remains of any previous screenshare, if any - not sure this is needed - just making sure
      if (this.data.existsPC(peer, SIGNALING_PURPOSE.SCREEN_SHARE))
        this.data.retirePC(peer, SIGNALING_PURPOSE.SCREEN_SHARE);
      if (this.data.existsPMS(peer, SIGNALING_PURPOSE.SCREEN_SHARE))
        this.data.retirePMS(peer, SIGNALING_PURPOSE.SCREEN_SHARE);
      this.uiHandler
        .getAppUIHTML()
        .getVCScreenShareHTML()
        .getRemoteScreenShareHTMLVideoElementVC().srcObject = null;
      this.uiHandler.resetVCUIPosition();
      this.getPeerConnectionsQueue().cancelPendingPeerConnectionsForPeer(
        peer,
        this.getActiveMeetingRoomID(),
        SIGNALING_PURPOSE.SCREEN_SHARE,
        true
      );

      this.busLogic.establishSuitableStreams(
        peer,
        SIGNALING_PURPOSE.SCREEN_SHARE
      );
      //this.uiHandler.showVCRemoteScreenShareVideoSectionOnUI(); //UI component is auto-activated when track is received
    }
  };

  //when local client wants VC to be upgraded from audio-only to a dedicated window, this needs to be requested from host prior starting to changing connections with VC peers - host needs to initiate connection changes
  requestVCUpgradeFromAudioToDedicatedWindowFromVCHost121 = (
    purpose: SIGNALING_PURPOSE
  ) => {
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending <121VCUpgradeToDedicatedWindowProposal> to host ",
      this.busLogic.getVCDataMgr().getVCCurrentHost(),
      " with purpose ",
      purpose,
    ]);
    this.sendVC121Message(
      this.busLogic.getVCDataMgr().getVCCurrentHost(),
      "121VCUpgradeToDedicatedWindowProposal",
      { targetPurpose: purpose }
    );
  };

  //when local client wants VC to be downgraded from dedicated window to audio-only, this needs to be requested from host prior starting to changing connections with VC peers - host needs to initiate connection changes
  requestVCDowngradeFromDedicatedWindowToSideBarFromVCHost121 = (
    purpose: SIGNALING_PURPOSE
  ) => {
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending <121VCDowngradeToSideBarProposal> to host ",
      this.busLogic.getVCDataMgr().getVCCurrentHost(),
      " with purpose ",
      purpose,
    ]);
    this.sendVC121Message(
      this.busLogic.getVCDataMgr().getVCCurrentHost(),
      "121VCDowngradeToSideBarProposal",
      { targetPurpose: purpose }
    );
  };

  informParticipantsOfVCUpgradeToDedicatedWindowRequest = (
    purpose: SIGNALING_PURPOSE = SIGNALING_PURPOSE.HR_VIDEO
  ) => {
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending <121VCUpgradeToDedicatedWindowRequest> to all participants of VC hosted by ",
      this.busLogic.getVCDataMgr().getVCCurrentHost(),
      " with purpose ",
      purpose,
    ]);
    for (let VCPeer of this.busLogic
      .getVCDataMgr()
      .getVCPeers()
      .concat(this.busLogic.getVCDataMgr().getConfirmedVCJoiners())) {
      this.sendVC121Message(VCPeer, "121VCUpgradeToDedicatedWindowRequest", {
        targetPurpose: purpose,
      });
    }
  };

  informParticipantsOfVCDowngradeToSidebarRequest = (
    purpose: SIGNALING_PURPOSE = SIGNALING_PURPOSE.AUDIO_ONLY
  ) => {
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending <121VCDowngradeToSidebarRequest> to all participants of VC hosted by ",
      this.busLogic.getVCDataMgr().getVCCurrentHost(),
      " with purpose ",
      purpose,
    ]);
    for (let VCPeer of this.busLogic
      .getVCDataMgr()
      .getVCPeers()
      .concat(this.busLogic.getVCDataMgr().getConfirmedVCJoiners())) {
      this.sendVC121Message(VCPeer, "121VCDowngradeToSidebarRequest", {
        targetPurpose: purpose,
      });
    }
  };

  // IDEA: request a peer to adopt the provided stream to new requirements of receiver - this communicates an intend setup / implementation is up to the peer's discression (local constraints)
  // CURRENT IMPLEMENTATION: request peer to provide new stream meeting stated requirements
  requestStreamChange121(peerID: string, purpose: SIGNALING_PURPOSE) {
    //TODO: signaling to only go via server if no direct p2p connection available
    this.cancelIncompleteConnectionsForIncompatiblePurposes(peerID, purpose);
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending stream change request to client ",
      peerID,
      ": ",
      purpose,
    ]);
    //informUser('Local client ' + this.soDa.getLocalSocketID() + ' sending stream change request to client ' + peerID + ': ' + purpose)
    //let _serial : Serializer = new Serializer();
    this.sendVC121Message(peerID, "121StreamChangeRequest", {
      targetUsage: /*_serial.serializeSignalingPurpose(*/ purpose /*)*/,
    });
  }

  cancelIncompleteConnectionsForIncompatiblePurposes = (
    _peerID: string,
    _newPurpose: SIGNALING_PURPOSE
  ) => {
    //todo: move this to data mgmt or bus logic?
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " reviewing stream change for client ",
      _peerID,
      ": ",
      _newPurpose,
      " - first checking whether conflicting connections are currently being established in order to cancel those",
    ]);
    //this needs to cancel incompatible connections only - i.e. connections that replace one another - not connections that can exist in parallel for a good reason
    if (_newPurpose == SIGNALING_PURPOSE.SCREEN_SHARE) return true; //screen share must not cancel any other connection types

    if (_newPurpose != SIGNALING_PURPOSE.BASE_CONNECTION)
      this.cancelConnectionIfIncomplete(
        _peerID,
        SIGNALING_PURPOSE.BASE_CONNECTION
      );
    if (_newPurpose != SIGNALING_PURPOSE.AUDIO_ONLY)
      this.cancelConnectionIfIncomplete(_peerID, SIGNALING_PURPOSE.AUDIO_ONLY);
    if (_newPurpose != SIGNALING_PURPOSE.HR_VIDEO)
      this.cancelConnectionIfIncomplete(_peerID, SIGNALING_PURPOSE.HR_VIDEO);
    if (_newPurpose != SIGNALING_PURPOSE.MR_VIDEO)
      this.cancelConnectionIfIncomplete(_peerID, SIGNALING_PURPOSE.MR_VIDEO);
    if (_newPurpose != SIGNALING_PURPOSE.LR_VIDEO)
      this.cancelConnectionIfIncomplete(_peerID, SIGNALING_PURPOSE.LR_VIDEO);
  };

  cancelConnectionIfIncomplete = (
    _peerID: string,
    _purposeForWhichConnectionIsToBeCancelledIfIncomplete: SIGNALING_PURPOSE
  ) => {
    if (
      this.data.existsPC(
        _peerID,
        _purposeForWhichConnectionIsToBeCancelledIfIncomplete
      )
    ) {
      let _PC = this.data.getPC(
        _peerID,
        _purposeForWhichConnectionIsToBeCancelledIfIncomplete
      );
      let _PCState = _PC.connectionState;
      if (
        _PCState != "connected" &&
        _PCState != "disconnected" &&
        _PCState != "failed" &&
        _PCState != "closed"
      ) {
        this.logger.logWarning([
          "local client cancelling incomplete connection with peer ",
          _peerID,
          " and purpose ",
          _purposeForWhichConnectionIsToBeCancelledIfIncomplete,
          " which is in state ",
          _PCState,
        ]);
        _PC.close();
      }
    }
  };

  configureSocketOnResponsesVC = (): void => {
    //when server response indicates that recipient did not receive message as temporarily not connected to server
    this.serverConnector
      .getSocketData()
      .getLocalSocket()
      .on(
        "121VCMessagePeerHasNoActiveAndAuthorizedConnectionToServer",
        (peerID, room, VCHost, messageType, messageAttributes) => {
          this.logger.logWarning([
            "Local client received server response indicating that peer did not receive 121VCmessage: ",
            "peer ",
            peerID,
            ", messageType ",
            messageType,
            ", messageAttributes ",
            messageAttributes,
          ]);
          this.uiMessenger.warningToUserSticky(
            "Network issue observed - one of your teammates did not respond to your latest request - you may want to try again in a few seconds",
            4500
          );
        }
      );

    // When this client receives a 121 message from another client..
    this.serverConnector
      .getSocketData()
      .getLocalSocket()
      .on(
        "121VCmessage",
        (peerID, room, VCHostID, messageType, messageAttributes) => {
          this.logger.logLocal([
            'Local client received "121VCmessage" element from client ',
            peerID,
            ":",
            messageType,
            messageAttributes,
          ]); //log any messages to console
          //when another client initiated an interaction by submitting an offer, create connection setup on this end and answer
          if (room != this.getActiveMeetingRoomID()) {
            console.error(
              "Received 121VCmessage from client ",
              peerID,
              " for wrong room: ",
              room,
              " >> thus ignoring the message"
            );
          } else {
            try {
              if (messageType === "121StreamChangeRequest") {
                //always process streamChangeRequests, independent of client (not) being in VC or (not) being the VC host
                this.busLogic.processStreamChangeRequest(
                  peerID,
                  messageAttributes
                ); //these two functions are not only used during VCs - one could consider migrating them elsewhere for higher consistency
              } else if (messageType === "121VCInadequateMessageResponse") {
                console.error(
                  "Local client was advised by peer ",
                  messageAttributes.receiver,
                  " that message submitted by local client was not applicable to peer situation and thus not acted upon by peer >> messageType: ",
                  messageAttributes.type,
                  ", rationale: ",
                  messageAttributes.rejectionRationale
                );

                ///////////// IF LOCAL CLIENT IS PART OF A VC
              } else if (this.busLogic.getVCDataMgr().isVCOpen()) {
                if (messageType === "121VCInvite") {
                  //invites in VCs always fail
                  this.logger.logLocal([
                    "Local client not joining VC hosted by ",
                    VCHostID,
                    "as already in another VC - submitting response to host",
                  ]);
                  this.respondLocalClientNotJoiningVC121(
                    peerID,
                    this.__rationale_InOtherVC
                  );
                } else if (messageType === "121NewHost") {
                  // host reset can be emitted by both host and coHost
                  this.logger.logLocal([
                    "Received new host and coHost for current VC, effective immediately: ",
                    messageAttributes.host,
                    " / ",
                    messageAttributes.coHost,
                    " - sent by ",
                    peerID,
                  ]);
                  if (
                    peerID == this.busLogic.getVCDataMgr().getVCCurrentHost() ||
                    peerID == this.busLogic.getVCDataMgr().getVCCurrentCoHost()
                  ) {
                    //if sender entitled to overwrite host/coHost info
                    this.busLogic
                      .getVCDataMgr()
                      .setVCCurrentHost(messageAttributes.host);
                    this.busLogic
                      .getVCDataMgr()
                      .setVCCurrentCoHost(messageAttributes.coHost);
                  } else {
                    this.respondToInadequateMessageVC121(
                      peerID,
                      messageType,
                      messageAttributes,
                      this.__notAuthorizedToChangeVCHost
                    );
                  }
                } else if (
                  messageType === "121VCScreenSharingOfferToBeConnected" &&
                  messageAttributes.ScreenSharePeer !== this.getUniqueClientID()
                ) {
                  // request to connect to screenshare can be received by host and participants
                  this.initiateConnectionToScreenSharePeer(
                    messageAttributes.ScreenSharePeer
                  );
                  /*logLocal(['Local client now connecting to peer ', messageAttributes.ScreenSharePeer, ' to obtain screenshare stream']);
                this.uiMessenger.informUser('Local client now connecting to peer ' + messageAttributes.ScreenSharePeer + ' to obtain screenshare stream');
                requestStreamChange121(messageAttributes.ScreenSharePeer,this.data.__Purpose_ScreenShare);
                this.uiHandler.showVCRemoteScreenShareVideoSectionOnUI();           */
                } else if (
                  messageType === "121VCScreenSharingEnded" &&
                  messageAttributes.ScreenSharePeer !== this.getUniqueClientID()
                ) {
                  // request to connect to screenshare can be received by host and participants
                  this.uiHandler.hideVCRemoteScreenShareVideoSectionOnUI();
                  this.data.retirePC(
                    messageAttributes.ScreenSharePeer,
                    SIGNALING_PURPOSE.SCREEN_SHARE
                  );

                  ////////////// IF LOCAL CLIENT IS HOST OF CURRENT VC
                } else if (
                  this.busLogic.getVCDataMgr().localClientIsVCCurrentHost()
                ) {
                  if (
                    messageType === "121VCClientJoined" &&
                    this.busLogic.getVCDataMgr().isVCInvitePending(peerID)
                  ) {
                    //request is only valid if there is a pending vc invite registered with host
                    if (
                      this.busLogic.getVCDataMgr().getVCCurrentType() !=
                      SIGNALING_PURPOSE.AUDIO_ONLY
                    ) {
                      this.uiHandler.showVCUI();
                    }
                    this.busLogic
                      .getVCDataMgr()
                      .registerConfirmedVCJoiner(peerID);
                    this.adviseVCParticipantsOfNewParticipant121(peerID);
                    //this.data.unregisterPendingVCInvite(peerID);
                    if (
                      this.busLogic.getVCDataMgr().getVCCurrentCoHost() == null
                    ) {
                      //if no coHost yet, make new peer the coHost
                      this.busLogic.getVCDataMgr().setVCCurrentCoHost(peerID);
                      this.adviseVCParticipantsOfNewHost121(
                        this.busLogic.getVCDataMgr().getVCCurrentHost(),
                        this.busLogic.getVCDataMgr().getVCCurrentCoHost()
                      ); //inform all participants
                    } else {
                      //else inform only new participant about current host / cohost
                      this.adviseIndividualVCParticipantOfHost121(
                        peerID,
                        this.busLogic.getVCDataMgr().getVCCurrentHost(),
                        this.busLogic.getVCDataMgr().getVCCurrentCoHost()
                      );
                    }
                  } else if (messageType === "121VCClientLeft") {
                    //uses peerID of client that emitted message - thus no further checks required whether message is legitimate
                    let tmpSkipNewConnection: boolean = this.help.BOOL2boolean(
                      messageAttributes.skipNewConnection
                    );
                    this.adviseVCParticipantsOfRemovedParticipant121(
                      peerID,
                      messageAttributes.leaverRationale,
                      tmpSkipNewConnection
                    );
                    if (this.busLogic.getVCDataMgr().isPartOfVC(peerID)) {
                      this.busLogic.removePeerFromExistingVC(
                        peerID,
                        false,
                        tmpSkipNewConnection
                      );
                      if (
                        this.busLogic
                          .getVCDataMgr()
                          .isIncomingMergeOngoingForPeer(peerID)
                      )
                        this.busLogic
                          .getVCDataMgr()
                          .unregisterIncomingMerge(peerID);
                    }
                    let _potentialVCPeers = this.busLogic
                      .getVCDataMgr()
                      .getVCPeers()
                      .concat(
                        this.busLogic.getVCDataMgr().getConfirmedVCJoiners()
                      );
                    if (_potentialVCPeers.length == 0) {
                      this.busLogic.closeVC(true, tmpSkipNewConnection, true);
                    }
                    //if (this.busLogic.getVCDataMgr().getVCPeers().length == 0) { closeVC();}
                    //todo: display rationale on UI
                  } else if (
                    messageType === "121VCClientNotJoining" &&
                    this.busLogic.getVCDataMgr().isVCInvitePending(peerID)
                  ) {
                    //request is only valid if there is a pending vc invite registered with host
                    //inform inviteoriginator and remove client from VC data structures
                    this.informParticipantsOfUnsuccessfulInviteVC121(
                      peerID,
                      messageAttributes.rejectionRationale,
                      false,
                      messageAttributes.hostOfActiveVC,
                      messageAttributes.participantCountActiveVC
                    );
                    //removePeerFromExistingVC(peerID); //### needed? are there situations where it has been added at this stage?
                    let _inviteOriginator = this.busLogic
                      .getVCDataMgr()
                      .getPendingVCInviteOriginator(peerID);

                    //if you're the inviteoriginator, check whether you can and want to join the active VC of your invitee
                    if (
                      this.getUniqueClientID() === _inviteOriginator &&
                      messageAttributes.rejectionRationale ===
                        this.__rationale_InOtherVC
                    ) {
                      if (messageAttributes.participantCountActiveVC >= 2) {
                        this.busLogic.tryToJoinPeersActiveVCOrMergeVCsIfPossible(
                          peerID,
                          messageAttributes.hostOfActiveVC,
                          messageAttributes.participantCountActiveVC,
                          messageAttributes.rejectionRationale
                        );
                      } else
                        this.uiMessenger.informUser(
                          "Request dropped: " + this.__rationale_AboutToJoinAVC
                        );
                    } else if (this.getUniqueClientID() === _inviteOriginator)
                      this.uiMessenger.informUser(
                        "Client not joining: " +
                          messageAttributes.rejectionRationale
                      );

                    //unregister pending vc invite if no requests ongoing
                    if (
                      !this.busLogic
                        .getVCDataMgr()
                        .isOutgoingMergeOngoingForPeer(
                          messageAttributes.hostOfActiveVC
                        )
                    ) {
                      this.busLogic
                        .getVCDataMgr()
                        .unregisterPendingVCInvite(peerID);
                    }
                    //close VC if empty and no further invites pending! + unregisterpendingvcinvite
                    this.busLogic.closeVCIfEmptyAndNothingPending();
                  } else if (messageType === "121VCInviteSuggestion") {
                    if (
                      !this.busLogic
                        .getVCDataMgr()
                        .isVCInvitePending(messageAttributes.peerToInvite)
                    ) {
                      this.busLogic
                        .getVCDataMgr()
                        .registerPendingVCInvite(
                          messageAttributes.peerToInvite,
                          peerID
                        );
                      //for now auto-accept all invite suggestions - this may change in the future if VC access model is made stricter (e.g. host to confirm suggestions or such)
                      this.busLogic.addPeerToExistingVC(
                        messageAttributes.peerToInvite
                      ); //checks whether person is in VC and whether it's the same as host or local client happen in called function
                    } else {
                      this.logger.logWarning([
                        "local host rejected an invitesuggestion as a duplicative request was already being progressed",
                      ]);
                      this.informRequestorOfDuplicativeRequestToJoinVC121(
                        peerID,
                        messageAttributes.peerToInvite
                      );
                    }
                  } else if (
                    messageType === "121ScreenSharingOffer" &&
                    this.busLogic.getVCDataMgr().isPartOfVC(peerID)
                  ) {
                    //for now, allow for all screen sharing offers to be passed to participants - could be limited going forward, allowing to e.g. grant presenter rights only to specific clients
                    this.informParticipantsOfScreenSharingOffer(peerID);
                    this.busLogic.downgradeVCToSidebarIfOnDedicatedWindow();
                  } else if (
                    messageType === "121VCUpgradeToDedicatedWindowProposal" &&
                    this.busLogic.getVCDataMgr().isPartOfVC(peerID)
                  ) {
                    //for now, allow for all VC participants to trigger upgrade to dedicated window - could be limited going forward, either by local settings of peers or by host not passing this on
                    this.informParticipantsOfVCUpgradeToDedicatedWindowRequest(
                      messageAttributes.targetPurpose
                    );
                    this.busLogic.processIncomingVCTypeChange(
                      messageAttributes.targetPurpose
                    );
                  } else if (
                    messageType === "121VCDowngradeToSideBarProposal" &&
                    this.busLogic.getVCDataMgr().isPartOfVC(peerID)
                  ) {
                    //for now, allow for all VC participants to trigger downgrade to sidebar - could be limited going forward, either by local settings of peers or by host not passing this on
                    this.busLogic.downgradeVCToSidebarIfOnDedicatedWindow();
                  } else if (
                    messageType === "121requestAccessToActiveVC" &&
                    !this.busLogic.getVCDataMgr().isPartOfVC(peerID) &&
                    this.busLogic
                      .getVCDataMgr()
                      .isIncomingMergeOngoingForPeer(peerID)
                  ) {
                    //message is only valid if peer not yet part of VC
                    //client from outside of current VC requests access to current VC from host of current VC (which is the local client)
                    let _arrayOfPeersToJoinLocalVC: string[] = [
                      ...new Set(messageAttributes.peersRequestingAccess),
                    ] as string[]; // make unique

                    if (
                      !this.busLogic.getVCDataMgr().isOutgoingMergeOngoing()
                    ) {
                      for (let newPeer of _arrayOfPeersToJoinLocalVC) {
                        if (
                          !this.busLogic
                            .getVCDataMgr()
                            .isVCInvitePending(newPeer)
                        ) {
                          //is this still relevant / used / properly triggered
                          this.busLogic
                            .getVCDataMgr()
                            .registerPendingVCInvite(newPeer, peerID);
                          this.busLogic.addPeerToExistingVC(newPeer);
                        } else {
                          this.logger.logWarning([
                            "local host rejected an invitesuggestion (requesttoaccessactiveVC) as a duplicative request was already being progressed",
                          ]);
                          //informRequestorOfDuplicativeRequestToJoinVC121(peerID, newPeer); //no need to inform as one of many / as anyways being added
                        }
                      }
                      //no longer block VC capacity for future merge requests, but prevent 2-way merges for a few more seconds
                      this.busLogic
                        .getVCDataMgr()
                        .unregisterIncomingMerge(peerID);
                      this.busLogic
                        .getVCDataMgr()
                        .registerIncomingMerge(peerID, []);
                      setTimeout(() => {
                        this.busLogic
                          .getVCDataMgr()
                          .unregisterIncomingMerge(peerID);
                      }, 5000);
                    } else {
                      this.logger.logWarning([
                        "local host rejected an 121requestAccessToActiveVC as a conflicting outgoing merge appears to be WIP",
                      ]);
                      this.informRequestorOfDuplicativeRequestToJoinVC121(
                        peerID,
                        peerID
                      );
                      //todo: validate creating more individualized failure notifications
                    }
                  } else if (
                    messageType === "121requestToMergeWithOtherActiveVC" &&
                    this.busLogic.getVCDataMgr().isPartOfVC(peerID)
                  ) {
                    //message is only valid if peer part of VC
                    //if client asks to merge with other VC and this appears feasible in light of max user count, then merge - in the future, this is likely to be subject to limitations like locked rooms, etc
                    if (
                      this.busLogic.getVCDataMgr().getVCPeers().length > 0 && //if local client hosts a VC and both VCs could be merged
                      messageAttributes.participantCountActiveVC +
                        this.busLogic.getVCDataMgr().getVCPeers().length +
                        this.busLogic.getVCDataMgr().getConfirmedVCJoiners()
                          .length +
                        1 <=
                        this.busLogic.getVCDataMgr().getVCMaxUserLimit()
                    ) {
                      if (
                        !this.busLogic
                          .getVCDataMgr()
                          .isOutgoingMergeOngoing() &&
                        !this.busLogic
                          .getVCDataMgr()
                          .isIncomingMergeOngoingForPeer(
                            messageAttributes.hostOfActiveVC
                          )
                      ) {
                        let _clientsInMyVC = this.busLogic
                          .getVCDataMgr()
                          .getVCPeers()
                          .concat([this.getUniqueClientID()])
                          .concat(
                            this.busLogic.getVCDataMgr().getConfirmedVCJoiners()
                          );
                        this.requestPreValidationOfJoiningActiveVC121(
                          peerID,
                          messageAttributes.hostOfActiveVC,
                          _clientsInMyVC
                        );
                      } else {
                        this.logger.logWarning([
                          "local host rejected an 121requestToMergeWithOtherActiveVC as a conflicting outgoing merge request appears to be WIP",
                        ]);
                        this.informRequestorOfDuplicativeRequestToJoinVC121(
                          peerID,
                          messageAttributes.hostOfActiveVC
                        );
                        //todo: more individualized denial-states/messaging
                      }
                    } else {
                      throw (
                        ("Local client is host of a VC and received request to merge with other active VC, but not possible >> participants in other VC: " +
                          messageAttributes.participantCountActiveVC +
                          " >> users in local VC: " +
                          (this.busLogic.getVCDataMgr().getVCPeers.length + 1),
                        " >> further confirmed invites: ",
                        this.busLogic.getVCDataMgr().getConfirmedVCJoiners()
                          .length)
                      );
                    }
                  } else if (
                    messageType === "121preValidationOfRequestToJoinActiveVC" &&
                    !this.busLogic.getVCDataMgr().isPartOfVC(peerID) &&
                    peerID != this.getUniqueClientID()
                  ) {
                    let _originatingVCHost = peerID;
                    let _incomingPeers =
                      messageAttributes.peersRequestingAccess;
                    if (
                      !this.busLogic
                        .getVCDataMgr()
                        .currentVCCanAccomodateIncomingMerge(_incomingPeers)
                    ) {
                      //too many people
                      this.logger.logWarning([
                        "local host rejected a prevalidation of another host joining local VC as there is insufficient space in local VC",
                      ]);
                      this.informRequestorOfDuplicativeRequestToJoinVC121(
                        peerID,
                        peerID
                      ); //todo: more individualized messaging - this is about space and not duplicate requests!!!
                    } else if (
                      this.busLogic.getVCDataMgr().isOutgoingMergeOngoing()
                    ) {
                      //no join if merge/exit already ongoing
                      this.logger.logWarning([
                        "local host rejected a prevalidation of another host joining local VC as there is already an outgoing merge WIP",
                      ]);
                      this.informRequestorOfDuplicativeRequestToJoinVC121(
                        peerID,
                        peerID
                      ); //todo: more individualized messaging
                    } else if (
                      this.busLogic
                        .getVCDataMgr()
                        .isIncomingMergeOngoingForPeer(_originatingVCHost)
                    ) {
                      //do nothing if there is already incoming join for same host
                      this.logger.logWarning([
                        "local host rejected a prevalidation of another host joining local VC as there is already an incoming join for the same host ",
                        _originatingVCHost,
                      ]);
                      this.informRequestorOfDuplicativeRequestToJoinVC121(
                        peerID,
                        peerID
                      ); //todo: more individualized messaging
                    } else {
                      //confirm that this is possible + register incoming request... both happening in below call
                      this.confirmPreValidationOfJoiningActiveVC121(
                        _originatingVCHost,
                        _incomingPeers,
                        [...new Set(_incomingPeers)].length
                      );
                    }
                  } else if (
                    messageType ===
                      "121preAuthorizationGrantedToMergeWithActiveVC" &&
                    !this.busLogic.getVCDataMgr().isPartOfVC(peerID) &&
                    peerID ==
                      this.busLogic
                        .getVCDataMgr()
                        .getActiveHostOfOutgoingMerge()
                  ) {
                    //message is only valid if peer part of VC
                    let clientsInMyVC = this.busLogic
                      .getVCDataMgr()
                      .getVCPeers()
                      .concat([this.getUniqueClientID()])
                      .concat(
                        this.busLogic.getVCDataMgr().getConfirmedVCJoiners()
                      );
                    this.busLogic.closeVC(true); //tell others to leave
                    this.requestAccessToActiveVC121(peerID, clientsInMyVC);
                  } else if (
                    messageType ===
                    "121informRequestorOfDuplicativeRequestToJoinVC"
                  ) {
                    this.logger.logWarning([
                      "Local client was advised that one of its requests to add users to a VC was rejected by VC host as it was duplicative of an earlier request already being processed",
                    ]);
                    if (
                      !this.busLogic
                        .getVCDataMgr()
                        .isOutgoingMergeOngoingForPeer(
                          messageAttributes.failedInvitee
                        )
                    ) {
                      this.busLogic
                        .getVCDataMgr()
                        .unregisterPendingVCInvite(
                          messageAttributes.failedInvitee
                        );
                    }
                    this.busLogic.closeVCIfEmptyAndNothingPending();
                    //TODO: if request came from VC participant via VC invite suggestion, forward denial to requestor!!
                  } else if (
                    messageType === "121VCChatMessageForDistributionToTeam"
                  ) {
                    this.chatSig.process121VCMessageChatMessageDistributionRequestToHost(
                      peerID,
                      room,
                      VCHostID,
                      messageType,
                      messageAttributes
                    );
                  } else {
                    this.respondToInadequateMessageVC121(
                      peerID,
                      messageType,
                      messageAttributes,
                      this.__notApplicableToVCHost
                    );
                    throw (
                      "Local client is host of a VC, but received 121VCMessage of a type not applicable to this setup: " +
                      messageType
                    );
                    //informUser ('Local client is host of a VC, but received 121VCMessage of a type not applicable to this setup: ' + messageType);
                  }
                  ////////////// IF LOCAL CLIENT IS NORMAL PARTICIPANT (NON-HOST) OF CURRENT VC
                } else {
                  if (
                    messageType === "121VCNewPeerToBeAdded" &&
                    peerID === this.busLogic.getVCDataMgr().getVCCurrentHost()
                  ) {
                    if (
                      !(
                        this.busLogic
                          .getVCDataMgr()
                          .isVCInvitePending(messageAttributes.newPeer) &&
                        this.busLogic
                          .getVCDataMgr()
                          .getPendingVCInviteOriginator(
                            messageAttributes.newPeer
                          ) == this.getUniqueClientID()
                      )
                    ) {
                      this.busLogic
                        .getVCDataMgr()
                        .registerPendingVCInvite(
                          messageAttributes.newPeer,
                          peerID
                        );
                    }
                    this.busLogic.addPeerToExistingVC(
                      messageAttributes.newPeer
                    );
                  } else if (
                    messageType === "121VCPeerToBeRemoved" &&
                    peerID === this.busLogic.getVCDataMgr().getVCCurrentHost()
                  ) {
                    let tmpSkipNewConnection: boolean = this.help.BOOL2boolean(
                      messageAttributes.skipNewConnection
                    );
                    if (
                      this.busLogic.getVCDataMgr().getVCPeers().length > 1 &&
                      this.busLogic
                        .getVCDataMgr()
                        .isPartOfVC(messageAttributes.peerToBeRemoved)
                    ) {
                      this.busLogic.removePeerFromExistingVC(
                        messageAttributes.peerToBeRemoved,
                        false,
                        tmpSkipNewConnection
                      );
                    } else if (
                      this.busLogic
                        .getVCDataMgr()
                        .isPartOfVC(messageAttributes.peerToBeRemoved)
                    ) {
                      this.busLogic.closeVC(true, tmpSkipNewConnection, true);
                    }
                    //todo: display rationale on UI
                  } else if (
                    messageType === "121VCToBeClosed" &&
                    peerID === this.busLogic.getVCDataMgr().getVCCurrentHost()
                  ) {
                    let tmpSkipNewConnection: boolean = this.help.BOOL2boolean(
                      messageAttributes.skipNewConnection
                    );
                    this.busLogic.closeVC(true, tmpSkipNewConnection, true); //if in ext meeting room, stay in that room, even through VC ends due to lack of participants
                    this.logger.logLocal([
                      "Note: The video conference has been closed by the initiator / host.",
                    ]);
                    //todo: display rationale on UI
                  } else if (
                    messageType === "121VCUnsuccessfulInvite" &&
                    peerID === this.busLogic.getVCDataMgr().getVCCurrentHost()
                  ) {
                    this.logger.logLocal([
                      "Received notification about unsuccessful invite to client ",
                      messageAttributes.rejectedPeer,
                      ", originated by client ",
                      messageAttributes.inviteOriginator,
                      " >> rationale: ",
                      messageAttributes.rejectionRationale,
                    ]);
                    //if you're the inviteoriginator, check whether you can and want to join the active VC of your invitee
                    if (
                      this.getUniqueClientID() ===
                        messageAttributes.inviteOriginator &&
                      messageAttributes.rejectionRationale ===
                        this.__rationale_InOtherVC
                    ) {
                      if (messageAttributes.participantCountActiveVC >= 2) {
                        this.busLogic.tryToJoinPeersActiveVCOrMergeVCsIfPossible(
                          messageAttributes.rejectedPeer,
                          messageAttributes.hostOfActiveVC,
                          messageAttributes.participantCountActiveVC,
                          messageAttributes.rejectionRationale
                        );
                      } else {
                        this.uiMessenger.informUser(
                          "Request dropped: " + this.__rationale_AboutToJoinAVC
                        );
                        this.busLogic
                          .getVCDataMgr()
                          .unregisterPendingVCInvite(
                            messageAttributes.rejectedPeer
                          );
                      }
                    } else if (
                      this.getUniqueClientID() ===
                      messageAttributes.inviteOriginator
                    ) {
                      this.uiMessenger.informUser(
                        "Client not joining: " +
                          messageAttributes.rejectionRationale
                      );
                      this.busLogic
                        .getVCDataMgr()
                        .unregisterPendingVCInvite(
                          messageAttributes.rejectedPeer
                        );
                      // if local client is not the initiator, ignore message type for now
                    }
                  } else if (
                    messageType === "121VCUpgradeToDedicatedWindowRequest" &&
                    peerID === this.busLogic.getVCDataMgr().getVCCurrentHost()
                  ) {
                    this.logger.logLocal([
                      "Note: Local client processing request from host to upgrade VC to dedicated window",
                    ]);
                    this.busLogic.processIncomingVCTypeChange(
                      messageAttributes.targetPurpose
                    );
                  } else if (
                    messageType === "121VCDowngradeToSidebarRequest" &&
                    peerID === this.busLogic.getVCDataMgr().getVCCurrentHost()
                  ) {
                    this.logger.logLocal([
                      "Note: Local client processing request from host to downgrade VC to sidebar",
                    ]);
                    this.busLogic.processIncomingVCTypeChange(
                      messageAttributes.targetPurpose
                    );
                  } else if (
                    messageType ===
                    "121informRequestorOfDuplicativeRequestToJoinVC"
                  ) {
                    this.logger.logWarning([
                      "Local client was advised that one of its requests to add users to an active VC was rejected by VC host as it was duplicative of an earlier request already being processed",
                    ]);
                    if (
                      !this.busLogic
                        .getVCDataMgr()
                        .isOutgoingMergeOngoingForPeer(
                          messageAttributes.failedInvitee
                        )
                    ) {
                      this.busLogic
                        .getVCDataMgr()
                        .unregisterPendingVCInvite(
                          messageAttributes.failedInvitee
                        );
                    }
                    setTimeout(() => {
                      if (
                        !this.busLogic
                          .getVCDataMgr()
                          .isPartOfVC(messageAttributes.failedInvitee)
                      ) {
                        this.uiMessenger.informUser(
                          "Note: Connection could not be established due to multiple parallel requests to connect with your colleague. You can now try again."
                        );
                      }
                    }, 2000);
                    //closeVCIfEmptyAndNothingPending();
                    //TODO: if request came from VC participant via VC invite suggestion, forward denial to requestor!!
                  } else if (messageType === "121VCChatMessage") {
                    this.chatSig.process121VCMessageChatMessageToClientInVC(
                      peerID,
                      room,
                      VCHostID,
                      messageType,
                      messageAttributes
                    );
                  } else {
                    this.respondToInadequateMessageVC121(
                      peerID,
                      messageType,
                      messageAttributes,
                      this.__notApplicableToVCParticipant
                    );
                    throw (
                      "Local client is normal participant (non-host) of a VC, but received 121VCMessage of a type not applicable to this setup: " +
                      messageType
                    );
                  }
                }
                ////////////// IF LOCAL CLIENT IS NOT PART OF A VC
              } else {
                if (messageType === "121VCInvite") {
                  this.logger.logLocal([
                    "Local client now joining VC hosted by ",
                    VCHostID,
                    " - type is ",
                    messageAttributes.type,
                    " - submitting response to host",
                  ]);
                  this.busLogic.initiateVC(VCHostID, messageAttributes.type);
                  this.confirmLocalClientJoinedVC121();
                  this.busLogic.addPeerToExistingVC(VCHostID);
                  if (
                    this.busLogic.getVCDataMgr().getVCCurrentType() !=
                    SIGNALING_PURPOSE.AUDIO_ONLY
                  ) {
                    this.uiHandler.showVCUI();
                  }
                } else {
                  this.respondToInadequateMessageVC121(
                    peerID,
                    messageType,
                    messageAttributes,
                    this.__notApplicableToClientsNotInVC
                  );
                  throw (
                    "Local client is not in a VC, but received 121VCMessage of a type applicable only to users in VC: " +
                    messageType
                  );
                }
              }
            } catch (err) {
              console.error(
                "Error processing incoming 121VCmessage: ",
                err.toString(),
                " >> processing of message aborted / continuing overall execution despite message failure"
              );
            }
          }
        }
      ); //binding function to signalingVC object so that "this" resolves to signalingVC and not to the Socket upon which the function is defined
  };

  getChatSignaling = (): SignalingChat => {
    return this.chatSig;
  };

  triggerHostReassignmentIfHostDisconnected(clientIDThatLeft: string): void {
    if (this.busLogic.getVCDataMgr().getVCCurrentHost() != clientIDThatLeft)
      return;
    if (this.busLogic.getVCDataMgr().localClientIsVCCurrentCoHost()) {
      this.busLogic.cohostToReassignHostPrivilegesIfHostLeaves(
        clientIDThatLeft
      );
    } else {
      let coHostID: string = this.busLogic.getVCDataMgr().getVCCurrentCoHost();
      this.sendVC121Message(coHostID, "HostNotReachable", {
        idThatLeft: clientIDThatLeft,
      });
    }
  }
}
