import { UIHandler } from "frontend/UIHandler";
import { SoundEffects } from "media/SoundEffects";
import { SignalingCore } from "signaling/SignalingCore";
import { Logger } from "utils/Logger";
import { UIMessenger } from "utils/UIMessenger";
import { I_IncomingQueueMessageHandler } from "./I_IncomingQueueMessageHandler";
import { Queue } from "./Queue";
import { QueueHandlingRejectionByServerMessage } from "./messages/delete_QueueHandlingRejectionByServerMessage";
import { QueueOwnerRegistrationConfirmationByServerMessage } from "./messages/QueueOwnerRegistrationConfirmationByServerMessage";
import { QueueVisitorInfoToOwnerMessage } from "./messages/QueueVisitorInfoToOwnerMessage";
import { QueueVisitorPassToRoomMessage } from "./messages/QueueVisitorPassToRoomMessage";
import { QueueVisitorRegistrationConfirmationByServerMessage } from "./messages/QueueVisitorRegistrationConfirmationByServerMessage";
import { QueueHandlingRejectionByServerMessageNotAVisitor } from "./messages/QueueHandlingRejectionByServerMessageNoOwnerAccess copy";
import { QueueHandlingRejectionByServerMessageNotAnOwner } from "./messages/QueueHandlingRejectionByServerMessageNotAnOwner";
import { QueueVisitorRegistrationMessage } from "./messages/QueueVisitorRegistrationMessage";
import { QueueOwnerRegistrationMessage } from "./messages/QueueOwnerRegistrationMessage";
import { QueueVisitorInfoRequestMessage } from "./messages/QueueVisitorInfoRequestMessage";
import { QueueUnregisterUserFromAllQueuesMessage } from "./messages/QueueUnregisterUserFromAllQueuesMessage";
import { QueueUnregisterUserMessage } from "./messages/QueueUnregisterUserMessage";
import { QueueAllowVisitorInMessage } from "./messages/QueueAllowVisitorInMessage";
import { RoomMgr } from "buslogic/meetingRoom/RoomMgr";
import { Helpers } from "utils/Helpers";

export class QueueMgr implements I_IncomingQueueMessageHandler {
  private logger: Logger;
  private help: Helpers;
  private signaling: SignalingCore;
  private uiMessenger: UIMessenger;
  private uiHandler: UIHandler;
  private sound: SoundEffects;

  private roomMgr: RoomMgr;

  private queuesWhereUserIsVisitor: Map<string, Queue>;
  private queuesWhereUserIsOwner: Map<string, Queue>;

  private personalQueueID: string;

  constructor(
    _logger: Logger,
    _help: Helpers,
    _signaling: SignalingCore,
    _uiMessenger: UIMessenger,
    _uiHandler: UIHandler,
    _sound: SoundEffects
  ) {
    this.logger = _logger;
    this.help = _help;
    this.signaling = _signaling;
    this.uiMessenger = _uiMessenger;
    this.uiHandler = _uiHandler;
    this.sound = _sound;

    this.queuesWhereUserIsVisitor = new Map();
    this.queuesWhereUserIsOwner = new Map();

    this.personalQueueID = null;
  }

  initialize = (_roomMgr: RoomMgr): void => {
    this.signaling.getQueueSignaling().registerIncomingMessageHandler(this);
    this.roomMgr = _roomMgr;
  };

  handleIncomingVisitorRegistrationConfirmationMessage = (
    incomingMsg: QueueVisitorRegistrationConfirmationByServerMessage
  ): void => {
    try {
      if (incomingMsg == null) return;
      let hashedID: string = incomingMsg.getHashedQueueID();
      if (!this.queuesWhereUserIsVisitor.has(hashedID))
        this.queuesWhereUserIsVisitor.set(hashedID, new Queue(hashedID));
      this.logger.logLocal([
        "Local client received confirmation that server registered it as visitor in queue ",
        hashedID,
      ]);
      this.uiMessenger.informUser(
        "You're now registered with the meeting room. Please wait for the host to let you in."
      );
    } catch (e) {
      this.logger.logError([
        "Error handling incoming visitor registration confirmation message: ",
        e,
      ]);
    }
  };

  handleIncomingOwnerRegistrationConfirmationMessage = (
    incomingMsg: QueueOwnerRegistrationConfirmationByServerMessage
  ): void => {
    try {
      if (incomingMsg == null) return;
      let hashedID = incomingMsg.getHashedQueueID();
      if (!this.queuesWhereUserIsOwner.has(hashedID))
        this.queuesWhereUserIsOwner.set(hashedID, new Queue(hashedID));
      if (incomingMsg.getQueueName() == this.getPersonalQueueName())
        this.personalQueueID = incomingMsg.getHashedQueueID();
      this.logger.logLocal([
        "Local client received confirmation that server registered it as owner of queue ",
        hashedID,
      ]);
      this.uiMessenger.informUser(
        "You have successfully registered your personal meeting queue with the server."
      );
    } catch (e) {
      this.logger.logError([
        "Error handling incoming owner registration confirmation message: ",
        e,
      ]);
    }
  };

  handleIncomingVisitorInfoToOwnerMessage = (
    incomingMsg: QueueVisitorInfoToOwnerMessage
  ): void => {
    try {
      if (incomingMsg == null) return;
      let hashedQueueID = incomingMsg.getHashedQueueID();
      if (this.queuesWhereUserIsOwner.has(hashedQueueID)) {
        let myQueue = this.queuesWhereUserIsOwner.get(hashedQueueID);
        myQueue.setQueuedVisitors(incomingMsg.getVisitors());
        myQueue.setQueuedVisitorsTime(incomingMsg.getVisitorsJoinTime());
        this.logger.logLocal([
          "Local client received queue visitor information for queue ",
          hashedQueueID,
          ", visitors: ",
          JSON.stringify(Array.from(incomingMsg.getVisitors())),
          ", with user labels: ",
          Object.fromEntries(incomingMsg.getVisitorsUserLabels()),
        ]);
        this.uiMessenger.informUser(
          "TMP LOG: visitor information received: " +
            Object.fromEntries(incomingMsg.getVisitorsUserLabels())
        );

        //Auto-approve visitors to own meeting room for now
        let visitors = Array.from(incomingMsg.getVisitors());
        visitors.forEach((visitor: string) => {
          this.allowVisitorIn(
            incomingMsg.getHashedQueueID(),
            this.roomMgr.getPersonalRoomName(),
            visitor
          );
        });
        this.uiMessenger.informUser(
          "auto-approving visitors that joined personal meeting queue..."
        );
      } else {
        this.logger.logError([
          "Error handling incoming visitor info to owner message - received message for queue that user is not owning: ",
          hashedQueueID,
        ]);
      }
    } catch (e) {
      this.logger.logError([
        "Error handling incoming visitor info to owner message: ",
        e,
      ]);
    }
  };

  handleIncomingVisitorPassToRoomMessage = (
    incomingMsg: QueueVisitorPassToRoomMessage
  ): void => {
    try {
      if (incomingMsg == null) return;
      else if (
        !this.queuesWhereUserIsVisitor.has(incomingMsg.getQueueIDHash())
      ) {
        this.logger.logError([
          "Error handling incoming visitor pass message - user is not part of queue for which he received pass: ",
          incomingMsg.getQueueIDHash(),
        ]);
        return;
      } else {
        this.logger.logLocal([
          "Local client received pass to room information for room ",
          incomingMsg.getRoomID(),
          " - now sending request to server to actually enter the room",
        ]);

        this.uiMessenger.informUser("Now entering the meeting room...");
        //TODO - initiate local client to join room with received visitor pass
        this.roomMgr.registerAsRoomVisitor(
          incomingMsg.getRoomID(),
          incomingMsg.getPass()
        );
        //what else needs to be done?
      }
    } catch (e) {
      this.logger.logError([
        "Error handling incoming visitor pass message: ",
        e,
      ]);
    }
  };

  handleIncomingHandlingRejectionByServerMessageNotAnOwner = (
    incomingMsg: QueueHandlingRejectionByServerMessageNotAnOwner
  ): void => {
    try {
      if (incomingMsg == null) return;

      //TODO - take action where applicable

      this.logger.logWarning([
        "Server advised local client that it has no owner access to queue - thus rejected request ",
        incomingMsg.getQueueID(),
      ]);
      this.uiMessenger.informUser(
        "TMP LOG: Could not perform owner request on queue : " +
          incomingMsg.getQueueID()
      );
    } catch (e) {
      this.logger.logError([
        "Error handling incoming rejection by server message: ",
        e,
      ]);
    }
  };

  handleIncomingHandlingRejectionByServerMessageNotAVisitor = (
    incomingMsg: QueueHandlingRejectionByServerMessageNotAVisitor
  ): void => {
    try {
      if (incomingMsg == null) return;

      //TODO - take action where applicable

      this.logger.logWarning([
        "Server advised local client that it has no active visitor registration with queue - thus rejected request ",
        incomingMsg.getQueueID(),
      ]);
      this.uiMessenger.informUser(
        "TMP LOG: Could not perform visitor request on queue : " +
          incomingMsg.getQueueID()
      );
    } catch (e) {
      this.logger.logError([
        "Error handling incoming rejection by server message: ",
        e,
      ]);
    }
  };

  registerAsQueueVisitor = (queueID: string, userLabel: string): void => {
    this.signaling
      .getQueueSignaling()
      .requestToServerToJoinQueueAsVisitor(
        new QueueVisitorRegistrationMessage(queueID, userLabel)
      );
  };

  registerAsQueueOwner = (queueName: string, userLabel: string): void => {
    this.signaling
      .getQueueSignaling()
      .requestToServerToJoinQueueAsOwner(
        new QueueOwnerRegistrationMessage(queueName, userLabel)
      );
  };

  requestQueueVisitorInfo = (queueID: string): void => {
    this.signaling
      .getQueueSignaling()
      .requestVisitorInfoFromServer(
        new QueueVisitorInfoRequestMessage(queueID)
      );
  };

  unregisterFromAllQueues = (): void => {
    this.signaling
      .getQueueSignaling()
      .requestToServerToUnregisterUserFromAllQueues(
        new QueueUnregisterUserFromAllQueuesMessage()
      );
  };

  unregisterFromQueue = (queueID: string): void => {
    this.signaling
      .getQueueSignaling()
      .requestToServerToUnregisterUserFromQueue(
        new QueueUnregisterUserMessage(queueID)
      );
  };

  allowVisitorIn = (
    queueID: string,
    roomName: string,
    peerUID: string
  ): void => {
    this.signaling
      .getQueueSignaling()
      .instructServerToAllowVisitorIn(
        new QueueAllowVisitorInMessage(queueID, roomName, peerUID)
      );
  };

  getPersonalQueueName = (): string => {
    return "PQ" + this.signaling.getUniqueClientID();
  };

  getPersonalQueueID = (): string => {
    return this.personalQueueID;
  };

  copyPersonalQueueLinkToClipboard = (
    queueID: string = this.personalQueueID
  ): void => {
    if (this.help.isDevEnv)
      navigator.clipboard.writeText(
        "https://meet-dev.visav.is/?meetid=" + queueID
      );
    else
      navigator.clipboard.writeText("https://meet.visav.is/?meetid=" + queueID);
  };
}
