import { UIHandler } from "frontend/UIHandler";
import { SoundEffects } from "media/SoundEffects";
import { SignalingCore } from "signaling/SignalingCore";
import { Logger } from "utils/Logger";
import { UIMessenger } from "utils/UIMessenger";
import { I_IncomingRoomMessageHandler } from "./I_IncomingRoomMessageHandler";
import { Room } from "./Room";
import { RoomOwnerRegistrationConfirmationByServerMessage } from "./messages/RoomOwnerRegistrationConfirmationByServerMessage";
import { RoomVisitorRegistrationConfirmationByServerMessage } from "./messages/RoomVisitorRegistrationConfirmationByServerMessage";
import { RoomHandlingRejectionByServerMessageNotAVisitor } from "./messages/RoomHandlingRejectionByServerMessageNotAVisitor";
import { RoomHandlingRejectionByServerMessageNotAnOwner } from "./messages/RoomHandlingRejectionByServerMessageNotAnOwner";
import { RoomVisitorRegistrationMessage } from "./messages/RoomVisitorRegistrationMessage";
import { RoomOwnerRegistrationMessage } from "./messages/RoomOwnerRegistrationMessage";
import { RoomUnregisterUserFromAllRoomsMessage } from "./messages/RoomUnregisterUserFromAllRoomsMessage";
import { RoomUnregisterUserMessage } from "./messages/RoomUnregisterUserMessage";
import { RoomClosureConfirmationMessage } from "./messages/RoomClosureConfirmationMessage";
import { RoomHandlingRejectionByServerMessagePeerIsNotAVisitor } from "./messages/RoomHandlingRejectionByServerMessagePeerIsNotAVisitor";
import { RoomYouWereKickedMessage } from "./messages/RoomYouWereKickedMessage";
import { RoomClosureRequestMessage } from "./messages/RoomClosureRequestMessage";
import { RoomRemoveVisitorRequestMessage } from "./messages/RoomRemoveVisitorRequestMessage";
import { RoomPleaseAddPeerToVCIfHostMessage } from "./messages/RoomPleaseAddPeerToVCIfHostMessage";
import { RoomPleaseOpenVCMessage } from "./messages/RoomPleaseOpenVCMessage";
import { BusLogicVC } from "buslogic/BusLogicVC";
import { SIGNALING_PURPOSE } from "data/enums/SIGNALING_PURPOSE";

export class RoomMgr implements I_IncomingRoomMessageHandler {
  private logger: Logger;
  private signaling: SignalingCore;
  private uiMessenger: UIMessenger;
  private uiHandler: UIHandler;
  private sound: SoundEffects;

  private busLogic: BusLogicVC;

  private roomsWhereUserIsVisitor: Map<string, Room>;
  private roomsWhereUserIsOwner: Map<string, Room>;

  constructor(
    _logger: Logger,
    _signaling: SignalingCore,
    _uiMessenger: UIMessenger,
    _uiHandler: UIHandler,
    _sound: SoundEffects
  ) {
    this.logger = _logger;
    this.signaling = _signaling;
    this.uiMessenger = _uiMessenger;
    this.uiHandler = _uiHandler;
    this.sound = _sound;

    this.roomsWhereUserIsVisitor = new Map();
    this.roomsWhereUserIsOwner = new Map();
  }

  initialize = (): void => {
    this.signaling.getRoomSignaling().registerIncomingMessageHandler(this);
  };

  setBusLogicReference = (_busLogic: BusLogicVC): void => {
    this.busLogic = _busLogic;
  };

  handleIncomingVisitorRegistrationConfirmationMessage = (
    incomingMsg: RoomVisitorRegistrationConfirmationByServerMessage
  ): void => {
    try {
      if (incomingMsg == null) return;
      let hashedID: string = incomingMsg.getHashedRoomID();
      if (!this.roomsWhereUserIsVisitor.has(hashedID))
        this.roomsWhereUserIsVisitor.set(hashedID, new Room(hashedID));
      this.signaling.getRooms().setActiveMeetingRoom(hashedID);
      this.logger.logLocal([
        "Local client received confirmation that it is entering the meeting room ",
        hashedID,
      ]);
      //this.uiMessenger.informUser("You're now entering the meeting room...");
    } catch (e) {
      this.logger.logError([
        "Error handling incoming visitor registration confirmation message: ",
        e,
      ]);
    }
  };

  handleIncomingOwnerRegistrationConfirmationMessage = (
    incomingMsg: RoomOwnerRegistrationConfirmationByServerMessage
  ): void => {
    try {
      if (incomingMsg == null) return;
      let hashedID = incomingMsg.getHashedRoomID();
      if (!this.roomsWhereUserIsOwner.has(hashedID))
        this.roomsWhereUserIsOwner.set(hashedID, new Room(hashedID));
      this.signaling.getRooms().setActiveMeetingRoom(hashedID);
      this.logger.logLocal([
        "Local client received confirmation that server registered it as owner of room ",
        hashedID,
      ]);
      this.uiMessenger.informUser(
        "You are now entering your personal meeting room..."
      );
    } catch (e) {
      this.logger.logError([
        "Error handling incoming owner registration confirmation message: ",
        e,
      ]);
    }
  };

  handleIncomingHandlingRejectionByServerMessageNotARoomOwner = (
    incomingMsg: RoomHandlingRejectionByServerMessageNotAnOwner
  ): void => {
    try {
      if (incomingMsg == null) return;

      //TODO - take action where applicable

      this.logger.logWarning([
        "Server advised local client that it has no owner access to room - thus rejected request ",
        incomingMsg.getRoomID(),
      ]);
      this.uiMessenger.informUser(
        "TMP LOG: Could not perform owner request on room : " +
          incomingMsg.getRoomID()
      );
    } catch (e) {
      this.logger.logError([
        "Error handling incoming rejection by server message: ",
        e,
      ]);
    }
  };

  handleIncomingHandlingRejectionByServerMessageNotARoomVisitor = (
    incomingMsg: RoomHandlingRejectionByServerMessageNotAVisitor
  ): void => {
    try {
      if (incomingMsg == null) return;

      //TODO - take action where applicable

      this.logger.logWarning([
        "Server advised local client that it has no active visitor registration with room - thus rejected request ",
        incomingMsg.getRoomID(),
      ]);
      this.uiMessenger.informUser(
        "TMP LOG: Could not perform visitor request on room : " +
          incomingMsg.getRoomID()
      );
    } catch (e) {
      this.logger.logError([
        "Error handling incoming rejection by server message: ",
        e,
      ]);
    }
  };

  handleIncomingHandlingRejectionByServerMessagePeerIsNotARoomVisitor = (
    incomingMsg: RoomHandlingRejectionByServerMessagePeerIsNotAVisitor
  ): void => {
    try {
      if (incomingMsg == null) return;

      //TODO - take action where applicable

      this.logger.logWarning([
        "Server advised local client that it a peer referenced in its request has no active visitor registration with room - thus rejected request ",
        incomingMsg.getRoomID(),
      ]);
      this.uiMessenger.informUser(
        "TMP LOG: Could not complete request as user not registered with the server" +
          incomingMsg.getRoomID()
      );
    } catch (e) {
      this.logger.logError([
        "Error handling incoming rejection by server message: ",
        e,
      ]);
    }
  };

  handleIncomingMeetingRoomClosureConfirmation = (
    incomingMsg: RoomClosureConfirmationMessage
  ): void => {
    try {
      if (incomingMsg == null) return;
      let hashedID: string = incomingMsg.getRoomIDHash();
      if (this.roomsWhereUserIsOwner.has(hashedID))
        this.roomsWhereUserIsOwner.delete(hashedID);
      if (this.roomsWhereUserIsVisitor.has(hashedID))
        this.roomsWhereUserIsVisitor.delete(hashedID);

      //todo: any other steps when disconnecting from a room
      this.signaling.getRooms().resetActiveMeetingRoom();

      this.logger.logLocal([
        "Local client received confirmation that room closed - thus leaving it: ",
        hashedID,
      ]);
      this.uiMessenger.informUser("Meeting room closed - leaving it...");
    } catch (e) {
      this.logger.logError([
        "Error handling incoming room closure confirmation message: ",
        e,
      ]);
    }
  };

  handleIncomingYouWereKickedFromMeetingRoomMessage = (
    incomingMsg: RoomYouWereKickedMessage
  ): void => {
    try {
      if (incomingMsg == null) return;
      let hashedID: string = incomingMsg.getRoomIDHash();
      if (this.roomsWhereUserIsOwner.has(hashedID))
        this.roomsWhereUserIsOwner.delete(hashedID);
      if (this.roomsWhereUserIsVisitor.has(hashedID))
        this.roomsWhereUserIsVisitor.delete(hashedID);

      //todo: any other steps when disconnecting from a room
      this.signaling.getRooms().resetActiveMeetingRoom();

      this.logger.logLocal([
        "Local client received information that it has been kicked from room - thus leaving it: ",
        hashedID,
      ]);
      this.uiMessenger.informUser(
        "The host removed you from the meeting room - leaving..."
      );
    } catch (e) {
      this.logger.logError([
        "Error handling incoming you-were-kicked confirmation message: ",
        e,
      ]);
    }
  };

  handleIncomingPleaseOpenVCRoomMessage = (
    incomingMsg: RoomPleaseOpenVCMessage
  ): void => {
    try {
      if (incomingMsg == null) return;
      let roomID: string = incomingMsg.getRoomID();
      let peerID: string = incomingMsg.getPeerID();
      this.logger.logLocal([
        "Local client received request to open VC in active meeting room / with peer: ",
        roomID,
        " / ",
        peerID,
      ]);

      //actually open VC via buslogic
      if (!this.busLogic.getVCDataMgr().isVCOpen()) {
        this.busLogic.connectViaVC(peerID, SIGNALING_PURPOSE.AUDIO_ONLY);
        //this.busLogic.initiateVC(this.signaling.getUniqueClientID(),SIGNALING_PURPOSE.LR_VIDEO);
      }
    } catch (e) {
      this.logger.logError([
        "Error handling incoming please-open-vc request message: ",
        e,
      ]);
    }
  };

  handleIncomingPleaseAddPeerToVCIfHostRoomMessage = (
    incomingMsg: RoomPleaseAddPeerToVCIfHostMessage
  ): void => {
    try {
      if (incomingMsg == null) return;
      let roomID: string = incomingMsg.getRoomID();
      let peerID: string = incomingMsg.getPeerID();
      this.logger.logLocal([
        "Local client received request to add peer to VC if local client was VC host in meeting room: ",
        roomID,
        " ",
        peerID,
      ]);

      this.logger.logLocal([
        "Local client received request to add peer to VC if local client was VC host in meeting room - pre-checks re isVCOpen and localClientIsHost and activeMeetingRoomID: ",
        this.busLogic.getVCDataMgr().isVCOpen(),
        " ",
        this.busLogic.getVCDataMgr().localClientIsVCCurrentHost(),
        " ",
        this.signaling.getRooms().getActiveMeetingRoomID(),
      ]); //todo: actually add peer to VC via buslogic - only if local client is host of VC
      if (
        this.busLogic.getVCDataMgr().isVCOpen() &&
        this.busLogic.getVCDataMgr().localClientIsVCCurrentHost() &&
        this.signaling.getRooms().getActiveMeetingRoomID() == roomID
      ) {
        this.logger.logLocal([
          "Local client received request to add peer to VC if local client was VC host in meeting room - now adding client ",
        ]);
        this.busLogic.connectViaVC(peerID, SIGNALING_PURPOSE.AUDIO_ONLY);
      }
    } catch (e) {
      this.logger.logError([
        "Error handling incoming please-add-peer-if-you-are-VC-host message: ",
        e,
      ]);
    }
  };

  registerAsRoomVisitor = (roomID: string, sessionPass: string): void => {
    this.signaling
      .getRoomSignaling()
      .requestToServerToJoinRoomAsVisitor(
        new RoomVisitorRegistrationMessage(roomID, sessionPass)
      );
  };

  registerAsRoomOwner = (roomName: string): void => {
    this.signaling
      .getRoomSignaling()
      .requestToServerToJoinRoomAsOwner(
        new RoomOwnerRegistrationMessage(roomName)
      );
  };

  leaveAllRooms = (): void => {
    this.signaling
      .getRoomSignaling()
      .requestToServerToLeaveAllRooms(
        new RoomUnregisterUserFromAllRoomsMessage()
      );
  };

  leaveRoom = (roomID: string): void => {
    this.signaling
      .getRoomSignaling()
      .requestToServerToLeaveRoom(new RoomUnregisterUserMessage(roomID));
  };

  closeRoom = (roomID: string): void => {
    this.signaling
      .getRoomSignaling()
      .requestToServerToCloseRoom(new RoomClosureRequestMessage(roomID));
  };

  kickPeerFromRoom = (roomID: string, peerUID: string): void => {
    this.signaling
      .getRoomSignaling()
      .requestToServerToRemoveVisitorFromRoom(
        new RoomRemoveVisitorRequestMessage(roomID, peerUID)
      );
  };

  getPersonalRoomName = (): string => {
    return (
      "PERSONAL_3G7MS2" + this.signaling.getUniqueClientID() + "823F176BN13"
    );
    //TODO: going forward, pre- and post-fox should use random elements that get stored in local settings
  };

  joinPersonalMeetingRoom = (): void => {
    this.registerAsRoomOwner(this.getPersonalRoomName());
  };
}
