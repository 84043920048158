/* COPYRIGHT 2021 Michael Maur - any form of reuse requires written consent by the author */
import interact from "interactjs";
import { BusLogicVC } from "../buslogic/BusLogicVC";
import { Config } from "../config/Config";
import { DataMgmt } from "../data/DataMgmt";
import { BrowserMediaHandler } from "../media/BrowserMediaHandler";
import { SettingStorage } from "../data/SettingStorage";
import { EnvironmentSwitcher } from "../infra/EnvironmentSwitcher";
import { UINwjsHandler } from "./nwjs/UINwjsHandler";
import { Helpers } from "../utils/Helpers";
import { Logger } from "../utils/Logger";
import { UIMessenger } from "../utils/UIMessenger";
import { SIGNALING_PURPOSE } from "../data/enums/SIGNALING_PURPOSE";
import { Tester } from "../utils/Tester";
import { AppUIHTML } from "./html/AppUIHTML";
import { BUTTON_SECTION } from "../data/enums/BUTTON_SECTION";
import { BUTTON_OBJECTIVE } from "../data/enums/BUTTON_OBJECTIVE";
import { SignalingVC } from "../signaling/SignalingVC";
import { CSSClassModifier } from "./CSSModifierOverarching";
import { UIMessengerCustom } from "../utils/UIMessengerCustom";
import { I_StateMgr } from "../infra/I_StageMgr";
import { DisconnectMgr } from "../infra/DisconnectMgr";
import { I_HTMLReferenceUser } from "./html/I_HTMLReferenceUser";
import { AppContextHandler } from "infra/AppContextHandler";
import { APP_CONTEXT } from "data/enums/APP_CONTEXT";
import { UIHandlerWebMeet } from "./webMeet/UIHandlerWebMeet";
import { I_UITechHandler } from "./decoupler/I_UITechHandler";

export class UIHandler implements I_StateMgr {
  private help: Helpers;
  private context: AppContextHandler;
  private config: Config;
  private settings: SettingStorage;
  private logger: Logger;
  private data: DataMgmt;
  private uiMessenger: UIMessenger;
  private signaling: SignalingVC;

  private busLogic: BusLogicVC;
  private media: BrowserMediaHandler;

  private appUI: AppUIHTML;

  private uiTechHandler: I_UITechHandler;
  private devEnvLoader: EnvironmentSwitcher;
  private cssModifier: CSSClassModifier;
  private disconnectMgr: DisconnectMgr;

  private _manualZoom: boolean;

  //variables to store drag&drop shift of UI elements - TODO: migrate these position shifts to individual variables per draggable object - currently, all draggable objects share same variables which causes them to start with same delta shift when being dragged
  private VCUIShift = { x: 0, y: 0 }; //only used in web client - app uses webkit-drag feature in chrome..
  private sideBarShift = { x: 0, y: 0 };

  private supportFormURL: string;
  private feedbackFormURL: string;
  private inviteTeamURL: string;
  private webRTCInternalsURL: string;
  private downloadsURL: string;

  private screenShareDialogueAlreadyOpen: boolean;

  constructor(
    _help: Helpers,
    _context: AppContextHandler,
    _config: Config,
    _settings: SettingStorage,
    _logging: Logger,
    _uiMessenger: UIMessenger,
    _data: DataMgmt,
    _signal: SignalingVC
  ) {
    console.log("Local client loading extension: UI Actions");

    this.help = _help;
    this.context = _context;
    this.config = _config;
    this.settings = _settings;
    this.logger = _logging;
    this.data = _data;
    this.uiMessenger = _uiMessenger;
    this.signaling = _signal;

    this._manualZoom = false;

    this.uiTechHandler = null;
    if (this.help.appIsActive())
      this.uiTechHandler = new UINwjsHandler(
        this.help,
        this.config,
        this.logger,
        this.uiMessenger
      );
    else if (this.help.isWebApp())
      this.uiTechHandler = new UIHandlerWebMeet(
        this.help,
        this.config,
        this.logger,
        this.uiMessenger
      );
    this.appUI = new AppUIHTML(
      this.config,
      this.logger,
      this.help,
      this.uiMessenger,
      this.uiTechHandler
    );
    this.cssModifier = new CSSClassModifier(this.help);
    this.devEnvLoader = new EnvironmentSwitcher(
      this.help,
      this.config,
      this.cssModifier,
      this.uiTechHandler,
      this.uiMessenger
    );
    this.disconnectMgr = new DisconnectMgr(this.data, this.logger);
  }

  initialize = (
    _busLogic: BusLogicVC,
    _media: BrowserMediaHandler
  ): Promise<void> => {
    this.logger.logLocal(["starting to initialize UIHandler"]);
    this.appUI.getSideBarHTML().setZoomFactor(1);

    return new Promise((resolve, reject) => {
      this.setBusLogicReference(_busLogic);
      this.setMediaReference(_media);
      this.initializeURLs();
      this.screenShareDialogueAlreadyOpen = false;
      this.uiTechHandler
        .initialize(this.appUI, this.cssModifier)
        .catch((e) => {
          this.logger.logError([
            "Error initializing NWJSHandler as part of UIHandler initialization, ",
            e,
          ]);
        })
        .then(this.appUI.initialize)
        .catch((e) => {
          this.logger.logError([
            "Error initializing appUI as part of UIHandler initialization, ",
            e,
          ]);
        })
        .then(() => {
          try {
            /*this.logger.logWarning(["Prior error, the key parameters are the following, ",this.appUI.getEntireApp(),
              ", ",this.appUI.getEntireAppElementOfVCScreen(), //missing
              ", ",window.document,window.document.querySelector("html"),", ",
              this.appUI.getVCScreenHTML().getVCWindowDocument(), //missing
            ]);*/
            let VC_HTML_root = null;
            let UIM_HTML_root = null;
            if (this.help.appIsActive()) {
              VC_HTML_root = this.appUI
                .getVCScreenHTML()
                .getVCWindowDocument()
                .querySelector("html");
            }
            if (this.uiMessenger instanceof UIMessengerCustom)
              UIM_HTML_root = (
                this.uiMessenger as UIMessengerCustom
              ).getRootHTML();
            this.cssModifier.initialize(
              this.appUI.getEntireApp(),
              this.appUI.getEntireAppElementOfVCScreen(),
              window.document.querySelector("html"),
              //window.document.querySelector("html"),
              VC_HTML_root,
              UIM_HTML_root
            );
          } catch (e) {
            this.logger.logError([
              "Error initializing cssModifier as part of UIHandler initialization",
              e,
            ]);
          }
          try {
            this.devEnvLoader.initialize(this.busLogic);
          } catch (e) {
            this.logger.logError([
              "Error initializing devEnvLoader as part of UIHandler initialization",
              e,
            ]);
          }
          try {
            this.disconnectMgr.initialize();
          } catch (e) {
            this.logger.logError([
              "Error initializing disconnectMgr as part of UIHandler initialization",
              e,
            ]);
          }

          this.initializeUI(); //broad setup like event listeners for device-turn (horizontal/vertical) and adding draggable properties
          this.setupUIButtons(); //linking actions to ui buttons
          this.appUI
            .getSideBarHTML()
            .getContactBenchUI()
            .classList.add("zoom100");
        })
        .catch((e) => {
          this.logger.logError([
            "Error initializing UIHandler and/or AppUI, ",
            e,
          ]);
        })
        .finally(() => {
          this.uiTechHandler.getSidebarWindow().focusMainApp();
          this.logger.logLocal(["finished initializing UIHandler"]);
          resolve();
          return;
        });
    });
  };

  private initializeURLs = (): void => {
    this.downloadsURL = this.config.downloadsURL;
    this.supportFormURL = this.config.supportFormURL;
    this.feedbackFormURL = this.config.feedbackFormURL;
    this.inviteTeamURL = this.config.inviteTeamURL;
    this.webRTCInternalsURL = this.config.webRTCInternalsURL;
  };

  private setBusLogicReference = (_busLogic: BusLogicVC) => {
    this.busLogic = _busLogic;
    this.uiTechHandler.setBusLogicReference(_busLogic);
  };

  private setMediaReference = (_media: BrowserMediaHandler) => {
    this.media = _media;
  };

  getAppUIHTML = (): AppUIHTML => {
    return this.appUI;
  };

  getCSSClassModifier = (): CSSClassModifier => {
    return this.cssModifier;
  };

  getUITechHandler = (): I_UITechHandler => {
    return this.uiTechHandler;
  };

  initializationClosure = () => {
    this.uiTechHandler.initializationClosure();
  };

  //////////////////////////////////////////////////////////////////
  // MULTI-PEER DATA STRUCTURES
  //////////////////////////////////////////////////////////////////

  configureCompressionCanvasToMatchVideoAspectRatio = () => {
    /*this.data._compressionCanvasL.width = this.getCompressionCanvasWidthBasedOnLocalVideoSize(_videoConstraintsSimulcast['L'].video.width.ideal,_videoConstraintsSimulcast['L'].video.height.ideal,this.data.getLocalHTMLVideoElement().videoWidth, this.data.getLocalHTMLVideoElement().videoHeight);
		this.data._compressionCanvasL.height = this.getCompressionCanvasHeightBasedOnLocalVideoSize(_videoConstraintsSimulcast['L'].video.width.ideal,_videoConstraintsSimulcast['L'].video.height.ideal,this.data.getLocalHTMLVideoElement().videoWidth, this.data.getLocalHTMLVideoElement().videoHeight);
		this.data._compressionCanvasM.width = this.getCompressionCanvasWidthBasedOnLocalVideoSize(_videoConstraintsSimulcast['M'].video.width.ideal,_videoConstraintsSimulcast['M'].video.height.ideal,this.data.getLocalHTMLVideoElement().videoWidth, this.data.getLocalHTMLVideoElement().videoHeight);
		this.data._compressionCanvasM.height = this.getCompressionCanvasHeightBasedOnLocalVideoSize(_videoConstraintsSimulcast['M'].video.width.ideal,_videoConstraintsSimulcast['M'].video.height.ideal,this.data.getLocalHTMLVideoElement().videoWidth, this.data.getLocalHTMLVideoElement().videoHeight);*/
    this.media.getVideoDownScaler().startCompressionOfLocalVideoStream();
    this.media.getVideoDownScaler().stopCompressionOfLocalVideoStream();
    //logLocal(['new compressioncanvas LWidth is ',this.data._compressionCanvasL.width,', Lheight is ',this.data._compressionCanvasL.height,', mWidth is ', this.data._compressionCanvasM.width,', mHeight ',this.data._compressionCanvasM.height]);
  };

  //Contact Bench: provide data structure to access UI video elements and show/hide them
  showPeerVideoSectionOnUI = (peerID: string) => {
    (
      this.appUI.getSideBarHTML().getPeerVS(peerID) as HTMLDivElement
    ).classList.add("active");
    //this.data.getPeerVS(peerID).style.display = "block"; //inline //new for cropping: block - flex needed for center / grid instead of flex as flex did not pick up newly computed sizes properly on Chrome on Android
    this.autoZoomVideoBar();
    this.uiTechHandler
      .getSidebarWindow()
      .updateSideBarWindowSizeAndPositionIfOnApp();
  };
  hidePeerVideoSectionOnUI = (peerID: string) => {
    this.appUI.getSideBarHTML().getPeerVS(peerID).classList.remove("active");
    //this.data.getPeerVS(peerID).style.display = "none";
    this.autoZoomVideoBar();
    this.uiTechHandler
      .getSidebarWindow()
      .updateSideBarWindowSizeAndPositionIfOnApp();
  };
  showPeerVideoSectionOnUIByPosition = (elementIDonUI: number) => {
    //TODO: check whether this function is at all required - and if so - implement proper video scaling equivalent to the above
    this.appUI
      .getSideBarHTML()
      .getPeerVSbyUIPos(elementIDonUI)
      .classList.add("active"); //inline //new for cropping:block - flex needed for center / grid instead of flex as flex did not pick up newly computed sizes properly on Chrome on Android
    //    this.data.getPeerVSbyUIPos(elementIDonUI).style.display = "block"; //inline //new for cropping:block - flex needed for center / grid instead of flex as flex did not pick up newly computed sizes properly on Chrome on Android
    this.appUI.getSideBarHTML().getPeerVSbyUIPos(elementIDonUI).style.height =
      "120px";
    this.appUI.getSideBarHTML().getPeerVSbyUIPos(elementIDonUI).style.width =
      "120px";
    /* SIZING TO BE IMPLEMENTED IN LINE WITH THE ABOVE
		this.data.getPeerHTMLVideoElement(1).style.width = '160px';
		this.data.getPeerHTMLVideoElement(2).style.width = '160px';
		this.data.getPeerHTMLVideoElement(3).style.width = '160px';*/
    //no effect: this.cropVideoElementInVideoSection(this.data.getPeerHTMLVideoElement(peerID), this.data._peerVideoSectionsUIinUse.get(peerID));
    this.autoZoomVideoBar();
    this.uiTechHandler
      .getSidebarWindow()
      .updateSideBarWindowSizeAndPositionIfOnApp();
  };
  hidePeerVideoSectionOnUIByPosition = (elementIDonUI: number) => {
    this.appUI
      .getSideBarHTML()
      .getPeerVSbyUIPos(elementIDonUI)
      .classList.remove("active");
    //this.data.getPeerVSbyUIPos(elementIDonUI).style.display =      "none";
    this.autoZoomVideoBar();
    this.uiTechHandler
      .getSidebarWindow()
      .updateSideBarWindowSizeAndPositionIfOnApp();
  };
  hideAllPeerVideoSectionsOnUI = () => {
    for (let i = 1; i <= 20; i++) {
      this.hidePeerVideoSectionOnUIByPosition(i);
    }
    this.autoZoomVideoBar();
    this.uiTechHandler
      .getSidebarWindow()
      .updateSideBarWindowSizeAndPositionIfOnApp();
  };
  showLocalVideoSectionOnUI = () => {
    this.appUI.getSideBarHTML().getLocalVS().classList.add("active"); //inline //new for cropping:block - flex needed for center / grid instead of flex as flex did not pick up newly computed sizes properly on Chrome on Android
    //this.data.getLocalVS().style.display = "block"; //inline //new for cropping:block - flex needed for center / grid instead of flex as flex did not pick up newly computed sizes properly on Chrome on Android
    this.uiTechHandler
      .getSidebarWindow()
      .updateSideBarWindowSizeAndPositionIfOnApp();
  };
  hideLocalVideoSectionOnUI = () => {
    this.appUI.getSideBarHTML().getLocalVS().classList.remove("active");
    //this.data.getLocalVS().style.display = "none";
    this.uiTechHandler
      .getSidebarWindow()
      .updateSideBarWindowSizeAndPositionIfOnApp();
  };

  //Video Conference: provide data structure to access UI video elements and show/hide them
  showVCUI = () => {
    if (this.help.appIsActive())
      this.cssModifier.addClassOnEntireApp("VCOnVCUI");
    //this.appUI.getEntireApp().classList.add("VCOnVCUI");
    this.uiTechHandler.getVCWindow().showDedicatedVCWindowIfOnApp();
    this.resetVCUIPosition();
    //ensure adjusted CSS for separate window
  };
  hideVCUI = () => {
    this.cssModifier.removeClassOnEntireApp("VCOnVCUI");
    //this.appUI.getEntireApp().classList.remove("VCOnVCUI");
    this.uiTechHandler.getVCWindow().hideDedicatedVCWindowIfOnApp();
    //ensure adjusted CSS for separate window
  };
  VCUIIsActive = (): boolean => {
    return this.cssModifier.containsClassOnEntireApp("VCOnVCUI");
  };
  showVCPeerVideoSectionOnUI = (peerID: string) => {
    if (!this.appUI.getVCScreenHTML().getPeerVSVC(peerID)) return;
    this.appUI.getVCScreenHTML().getPeerVSVC(peerID).classList.add("active"); //inline-block
    //this.appUI.getVCScreenHTML().getPeerVSVC(peerID).style.display =      "initial"; //inline-block
    this.appUI.getVCScreenHTML().getPeerVSVC(peerID).style.height = "240px"; //360
    this.appUI.getVCScreenHTML().getPeerVSVC(peerID).style.width = "320px"; //480
  };
  hideVCPeerVideoSectionOnUI = (peerID: string) => {
    if (!this.appUI.getVCScreenHTML().getPeerVSVC(peerID)) return;
    this.appUI.getVCScreenHTML().getPeerVSVC(peerID).classList.remove("active");
    //this.appUI.getVCScreenHTML().getPeerVSVC(peerID).style.display ="none";
  };
  hideAllVCPeerVideoSectionsOnUI = (): void => {
    if (this.help.appIsActive())
      for (let i = 0; i < 20; i++) {
        this.hideVCPeerVideoSectionOnUIByPosition(i);
      }
  };
  showVCPeerVideoSectionOnUIByPosition = (elementIDonUI: number) => {
    if (!this.appUI.getVCScreenHTML().getPeerVSVCbyUIPos(elementIDonUI)) return;
    this.appUI
      .getVCScreenHTML()
      .getPeerVSVCbyUIPos(elementIDonUI)
      .classList.add("active");
    //this.appUI.getVCScreenHTML().getPeerVSVCbyUIPos(elementIDonUI).style.display = "initial";
  };
  hideVCPeerVideoSectionOnUIByPosition = (elementIDonUI: number) => {
    if (!this.appUI.getVCScreenHTML().getPeerVSVCbyUIPos(elementIDonUI)) return;
    this.appUI
      .getVCScreenHTML()
      .getPeerVSVCbyUIPos(elementIDonUI)
      .classList.remove("active");
    //this.appUI.getVCScreenHTML().getPeerVSVCbyUIPos(elementIDonUI).style.display = "none";
  };
  showVCLocalVideoSectionOnUI = () => {
    if (!this.appUI.getVCScreenHTML().getLocalVSVC()) return;
    this.appUI.getVCScreenHTML().getLocalVSVC().classList.add("active");
    //this.appUI.getVCScreenHTML().getLocalVSVC().style.display = "initial"; //inline-block
    this.appUI.getVCScreenHTML().getLocalVSVC().style.height = "240px"; //360
    this.appUI.getVCScreenHTML().getLocalVSVC().style.width = "320px"; //480
  };
  hideVCLocalVideoSectionOnUI = () => {
    if (!this.appUI.getVCScreenHTML().getLocalVSVC()) return;
    this.appUI.getVCScreenHTML().getLocalVSVC().classList.remove("active");
    //this.appUI.getVCScreenHTML().getLocalVSVC().style.display = "none";
  };
  showVCLocalScreenShareVideoSectionOnUI = () => {
    if (!this.appUI.getVCScreenHTML().getLocalScreenShareVSVC()) return;
    this.appUI
      .getVCScreenHTML()
      .getLocalScreenShareVSVC()
      .classList.add("active");
    //this.data.getLocalScreenShareVSVC().style.display = "initial";
  };
  hideVCLocalScreenShareVideoSectionOnUI = () => {
    if (!this.appUI.getVCScreenHTML().getLocalScreenShareVSVC()) return;
    this.appUI
      .getVCScreenHTML()
      .getLocalScreenShareVSVC()
      .classList.remove("active");
    //this.data.getLocalScreenShareVSVC().style.display = "none";
  };
  showVCRemoteScreenShareVideoSectionOnUI = () => {
    this.resetVCUIPosition();
    if (this.appUI.getVCScreenShareHTML().getRemoteScreenShareVSVC())
      this.appUI
        .getVCScreenShareHTML()
        .getRemoteScreenShareVSVC()
        .classList.add("active");
    this.cssModifier.addClassOnEntireApp("remoteScreenShareActive");
    this.uiTechHandler.getVCWindow().showDedicatedVCWindowIfOnApp();
    //this.data.getRemoteScreenShareVSVC().style.display = "initial";
    //this.data.getRemoteScreenShareHTMLVideoElementVC.height = '480px'; not working
    //this.data.getRemoteScreenShareHTMLVideoElementVC.width = '640px';
  };
  hideVCRemoteScreenShareVideoSectionOnUI = () => {
    if (this.appUI.getVCScreenShareHTML().getRemoteScreenShareVSVC())
      this.appUI
        .getVCScreenShareHTML()
        .getRemoteScreenShareVSVC()
        .classList.remove("active");
    this.uiTechHandler.getVCWindow().hideDedicatedVCWindowIfOnApp();
    this.cssModifier.removeClassOnEntireApp("remoteScreenShareActive");
    //this.data.getRemoteScreenShareVSVC().style.display = "none";
    this.resetMinimizedRemoteScreenShare(); // when closing remote screenshare, remove the "minimized" tag, in case it was added
  };
  minimizeRemoteScreenShare = () => {
    this.cssModifier.addClassOnEntireApp("remoteScreenShareMinimized");
    this.resetVCUIPosition();
    this.uiTechHandler.getVCWindow().hideDedicatedVCWindowIfOnApp();
  };
  resumeRemoteScreenShare = () => {
    this.cssModifier.removeClassOnEntireApp("remoteScreenShareMinimized");
    this.uiTechHandler.getVCWindow().showDedicatedVCWindowIfOnApp();
  };
  resetMinimizedRemoteScreenShare = () => {
    this.cssModifier.removeClassOnEntireApp("remoteScreenShareMinimized");
  };

  /*setStatusOnUI = (message: string) => {
    this._UIstatusBar.innerHTML = message;
  };*/
  //todo:hide/unhide

  //prompt user to provide name of room which he wants to join - prepopulated with a defaultRoom
  /*  getRoomNameFromUser = (defaultRoom: string) => {
    var room = defaultRoom;
    if (!this.help.isWebAppMeeting()) {
      var roomCapture = prompt("Enter custom room name (optional):", defaultRoom);
      if (roomCapture != undefined && roomCapture != "") {
        room = roomCapture;
      }
    }
    return room;
  };*/

  markButtonsAsPressed = (
    buttons: HTMLCollectionOf<HTMLButtonElement> | HTMLButtonElement[],
    pressed: boolean,
    pressedClass: string = "pressedButtonOnVideo"
  ) => {
    Array.from(buttons).forEach((but: HTMLButtonElement) => {
      if (but == null || but == undefined) {
        //if no button, do nothing
      } else if (pressed) but.classList.add(pressedClass);
      else but.classList.remove(pressedClass);
    });
  };

  markVCButtonsAsPressed = (peerID: string, pressed: boolean) => {
    let _button = null;
    let _positionOnScreen = this.appUI
      .getSideBarHTML()
      .getUISectionForPeer(peerID);
    //retrieve button
    _button = this.appUI.getUIPeerButtonByPos(
      BUTTON_SECTION.CONTACT_BENCH,
      _positionOnScreen,
      BUTTON_OBJECTIVE.START_VC
    );
    this.markButtonsAsPressed([_button], pressed);
  };

  markTalkButtonsAsPressed = (peerID: string, pressed: boolean) => {
    let _button = null;
    let _positionOnScreen = this.appUI
      .getSideBarHTML()
      .getUISectionForPeer(peerID);
    //retrieve button
    _button = this.appUI.getUIPeerButtonByPos(
      BUTTON_SECTION.CONTACT_BENCH,
      _positionOnScreen,
      BUTTON_OBJECTIVE.TALK
    );
    this.markButtonsAsPressed([_button], pressed);
  };

  /*markRaiseHandButtonsAsPressed = (peerID: string, pressed: boolean) => {
    let _button = null;
    let _positionOnScreen = this.appUI
      .getSideBarHTML()
      .getUISectionForPeer(peerID);
    //retrieve button
    _button = this.appUI.getUIPeerButtonByPos(
      BUTTON_SECTION.CONTACT_BENCH,
      _positionOnScreen,
      BUTTON_OBJECTIVE.RAISE_HAND
    );
    this.markButtonsAsPressed([_button], pressed);
  };*/

  resetAllTalkButtons = () => {
    this.resetAllButtonsAsNotPressedForSectionAndObjective(
      BUTTON_SECTION.CONTACT_BENCH,
      BUTTON_OBJECTIVE.TALK
    );
    /*let _button = null;
    let pressed = false;
    for (
      let _positionOnScreen = 1;
      _positionOnScreen <= 20;
      _positionOnScreen++
    ) {
      _button = this.appUI.getUIPeerButtonByPos(
        BUTTON_SECTION.CONTACT_BENCH,
        _positionOnScreen,
        BUTTON_OBJECTIVE.TALK
      );
      this.markButtonsAsPressed([_button], pressed);
    }*/
  };

  resetAllRaiseHandButtons = () => {
    this.resetAllButtonsAsNotPressedForSectionAndObjective(
      BUTTON_SECTION.CONTACT_BENCH,
      BUTTON_OBJECTIVE.RAISE_HAND
    );
    /*let _button = null;
    let pressed = false;
    for (
      let _positionOnScreen = 1;
      _positionOnScreen <= 20;
      _positionOnScreen++
    ) {
      _button = this.appUI.getUIPeerButtonByPos(
        BUTTON_SECTION.CONTACT_BENCH,
        _positionOnScreen,
        BUTTON_OBJECTIVE.RAISE_HAND
      );
      this.markButtonsAsPressed([_button], pressed);
    }*/
  };

  resetAllRemoteMuteButtons = () => {
    this.resetAllButtonsAsNotPressedForSectionAndObjective(
      BUTTON_SECTION.CONTACT_BENCH,
      BUTTON_OBJECTIVE.REMOTE_MUTE
    );
    if (this.help.appIsActive())
      this.resetAllButtonsAsNotPressedForSectionAndObjective(
        BUTTON_SECTION.VIDEO_CONF,
        BUTTON_OBJECTIVE.REMOTE_MUTE
      );
  };

  private resetAllButtonsAsNotPressedForSectionAndObjective = (
    section: BUTTON_SECTION,
    objective: BUTTON_OBJECTIVE
  ): void => {
    let _button = null;
    let pressed = false;
    for (
      let _positionOnScreen = 1;
      _positionOnScreen <= 20;
      _positionOnScreen++
    ) {
      _button = this.appUI.getUIPeerButtonByPos(
        section,
        _positionOnScreen,
        objective
      );
      this.markButtonsAsPressed([_button], pressed);
    }
  };

  markAudioMutedButtonsAsPressed = (pressed: boolean): void => {
    this.markButtonsAsPressed(
      this.appUI.getSideBarHTML().getUIMuteLocalButtons(),
      pressed
    );
    if (this.help.appIsActive())
      this.markButtonsAsPressed(
        this.appUI.getVCScreenHTML().getUIMuteLocalButtons(),
        pressed
      );
    if (this.help.isWebApp())
      this.markButtonsAsPressed(
        [
          this.appUI
            .getSideBarHTML()
            .getWebClientSideBarEnhancementsHTML()
            .getInVCMuteButton(),
        ],
        pressed,
        "pressed"
      );
  };

  markVideoPausedButtonsAsPressed = (pressed: boolean): void => {
    this.markButtonsAsPressed(
      this.appUI.getSideBarHTML().getUIPauseLocalButtons(),
      pressed
    );
    if (this.help.appIsActive())
      this.markButtonsAsPressed(
        this.appUI.getVCScreenHTML().getUIPauseLocalButtons(),
        pressed
      );
    if (this.help.isWebApp())
      this.markButtonsAsPressed(
        [
          this.appUI
            .getSideBarHTML()
            .getWebClientSideBarEnhancementsHTML()
            .getInVCPauseVideoButton(),
        ],
        pressed,
        "pressed"
      );
  };

  requestFullscreenForElement = (element) => {
    this.logger.logLocal(["Requesting full screen UI for element: ", element]);
    if (element.requestFullscreen) element.requestFullscreen();
    else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen();
    else if (element.mozRequestFullscreen) element.mozRequestFullscreen();
    else if (element.msRequestFullscreen) element.msRequestFullscreen();
    //element.classList.toggle("customFullScreen");
  };

  switchAppBetweenLeftAndRightSideOfScreen = () => {
    this.cssModifier.toggleClassOnEntireApp("rightScreenSide");
    this.cssModifier.toggleClassOnEntireApp("leftScreenSide");
    this.cssModifier.toggleClassOnRootHTML("rightScreenSide");
    this.cssModifier.toggleClassOnRootHTML("leftScreenSide");
    this.appUI.refreshAppScreenSide();
    this.uiTechHandler
      .getSidebarWindow()
      .updateSideBarWindowSizeAndPositionIfOnApp();
    this.uiTechHandler
      .getUIMessengerWindow()
      .switchUIMessengerWindowToScreenSide(this.appUI.getAppScreenSide());
  };

  hideApp = () => {
    this.cssModifier.toggleClassOnEntireApp("appHidden");
    if (!this.cssModifier.containsClassOnEntireApp("appHidden"))
      this.uiTechHandler
        .getSidebarWindow()
        .updateSideBarWindowSizeAndPositionIfOnApp();
  };

  setVCActive = (active: boolean) => {
    if (active) {
      this.cssModifier.addClassOnEntireApp("VCIsActive");
      this.cssModifier.addClassOnEntireApp("afterFirstMeeting");
    } else {
      this.cssModifier.removeClassOnEntireApp("VCIsActive");
    }
    this.uiTechHandler
      .getSidebarWindow()
      .updateSideBarWindowSizeAndPositionIfOnApp();
  };

  resetVCUI = () => {
    //screen share elements apply to both app and web client
    this.hideVCRemoteScreenShareVideoSectionOnUI();
    this.appUI
      .getVCScreenShareHTML()
      .getRemoteScreenShareHTMLVideoElementVC().srcObject = null;
    //rest is skipped if not on app
    if (!this.help.appIsActive()) return;
    this.hideVCUI();
    this.resetVCUIButtons();
    this.hideVCLocalVideoSectionOnUI();
    this.appUI.getVCScreenHTML().getLocalHTMLVideoElementVC().srcObject = null;
    this.hideVCLocalScreenShareVideoSectionOnUI();
    this.appUI
      .getVCScreenHTML()
      .getLocalScreenShareHTMLVideoElementVC().srcObject = null;
    for (let i = 0; i < 20; i++) {
      this.hideVCPeerVideoSectionOnUIByPosition(i + 1);
      this.appUI
        .getVCScreenHTML()
        .getPeerHTMLVideoElementVCOnUIByPosition(i + 1).srcObject = null;
    }
    this.resetVCUIPosition();
  };

  autoZoomVideoBar = () => {
    if (this._manualZoom || this.help.isWebApp()) return false; //if zoom buttons were used already, skip auto-zoom

    let barHeight = this.appUI
      .getSideBarHTML()
      .getContactBenchUI().offsetHeight;
    let screenHeight = screen.availHeight;
    /*if (this.help.appIsActive()) {
      screenHeight = this.nwjsHandler.getSideBarWindowHeightIfOnApp();
    }*/
    /*this.logger.logLocal([
      "auto-zoom with input height levels ",
      screenHeight,
      ", ",
      barHeight,
    ]);*/
    let _ratio = screenHeight / barHeight;

    //first, clear zoom level
    let classes = this.appUI.getSideBarHTML().getContactBenchUI().classList;
    if (classes.contains("zoom100")) {
      classes.remove("zoom100");
    } else if (classes.contains("zoom50")) {
      classes.remove("zoom50");
    } else if (classes.contains("zoom60")) {
      classes.remove("zoom60");
    } else if (classes.contains("zoom70")) {
      classes.remove("zoom70");
    } else if (classes.contains("zoom80")) {
      classes.remove("zoom80");
    } else if (classes.contains("zoom90")) {
      classes.remove("zoom90");
    } else if (classes.contains("zoom110")) {
      classes.remove("zoom110");
    } else if (classes.contains("zoom120")) {
      classes.remove("zoom120");
    } else if (classes.contains("zoom130")) {
      classes.remove("zoom130");
    }

    if (_ratio < 0.6) {
      classes.add("zoom50");
      this.appUI.getSideBarHTML().setZoomFactor(0.5);
    } else if (_ratio < 0.7) {
      classes.add("zoom60");
      this.appUI.getSideBarHTML().setZoomFactor(0.6);
    } else if (_ratio < 0.8) {
      classes.add("zoom70");
      this.appUI.getSideBarHTML().setZoomFactor(0.7);
    } else if (_ratio < 0.9) {
      classes.add("zoom80");
      this.appUI.getSideBarHTML().setZoomFactor(0.8);
    } else if (_ratio < 1.0) {
      classes.add("zoom90");
      this.appUI.getSideBarHTML().setZoomFactor(0.9);
    } else {
      classes.add("zoom100");
      this.appUI.getSideBarHTML().setZoomFactor(1);
    }
  };

  initializeUI = () => {
    //manage UI status bar at bottom of UI
    //this._UIstatusBar = document.getElementById("statusBar") as HTMLElement;

    window.addEventListener(
      "orientationchange",
      () => {
        // any actions to be performed when someone turns the device from horizontal to vertical orientation or vice versa
        if ((screen.orientation as unknown) != 0) {
          this.configureCompressionCanvasToMatchVideoAspectRatio();
          setTimeout(() => {
            this.autoZoomVideoBar();
            this.uiTechHandler
              .getSidebarWindow()
              .updateSideBarWindowSizeAndPositionIfOnApp(false, true, true);
          }, 500);
        }
      }, //binding had to be done for typescript compatibility - potential source of error if problems arise
      false
    );

    //on closing the window, send respective signal to others in the room (via server)
    window.onbeforeunload = () => {
      this.logger.logLocal([
        "Local client detected that page is about to be unloaded - initiating a full local hangup to close all connections properly",
      ]);
      this.busLogic.fullLocalHangupOnExit(
        "ClosureAsPartOfWindowOnBeforeUnloadFunction"
      );
    };

    //add css class that indicates whether app or web client is in use - this e.g. enables automated hiding of app download button when in app client - button to only show when in web client
    if (this.config.isAppInUse()) {
      this.cssModifier.addClassOnEntireApp("appClient");
      this.cssModifier.removeClassOnEntireApp("webClient");
      this.cssModifier.addClassOnRootHTML("appClient");
      this.cssModifier.removeClassOnRootHTML("webClient");
      this.getAppUIHTML()
        .getEntireApp() //this is added on main window to deactivate legacy VC subwindow
        .classList.add("legacyVCWindowDeactivated");
      this.getAppUIHTML()
        .getVCScreenHTML()
        .getVCWindowDocument()
        .querySelector("html")
        .classList.add("VCRoot");
      window.document.querySelector("html").classList.add("sideBarRoot");
    } else {
      this.cssModifier.addClassOnEntireApp("webClient");
      this.cssModifier.removeClassOnEntireApp("appClient");
      this.cssModifier.addClassOnRootHTML("webClient");
      this.cssModifier.removeClassOnRootHTML("appClient");
    }
    if (this.help.isWebAppGO()) {
      this.cssModifier.addClassOnEntireApp("webClientGO");
      this.cssModifier.removeClassOnEntireApp("webClientMeet");
      this.cssModifier.addClassOnRootHTML("webClientGO");
      this.cssModifier.removeClassOnRootHTML("webClientMeet");
    } else if (this.help.isWebAppMeeting()) {
      this.cssModifier.addClassOnEntireApp("webClientMeet");
      this.cssModifier.removeClassOnEntireApp("webClientGO");
      this.cssModifier.addClassOnRootHTML("webClientMeet");
      this.cssModifier.removeClassOnRootHTML("webClientGO");
    }

    //////////////////////////////////////////////////////////////////
    // DRAG / DROP / RESIZE FUNCTIONALITY
    //////////////////////////////////////////////////////////////////

    // note that there is additional CSS for the .draggable class

    //##### ATTENTION ####### draggable code currently relies on single position constant which causes interferences when used on multiple elements, i.e. dragging one element messes with the anchor point of the others

    // first, make relevant elements draggable / resizeable
    let draggableElements = [
      this.appUI.getVCScreenHTML().getVCUI(),
      /*this.appUI.getVCScreenHTML().getVCWindowDocument().body,*/
    ];
    let vOnlyDraggableElements = [
      document.getElementById(
        "mainScreen"
      ) /*, document.getElementById("VCOnlyButtonGroup")*/,
    ];
    //let resizableElements = [this.data.getVCUI()];
    if (this.help.appIsActive) {
      draggableElements = [];
      vOnlyDraggableElements = [];
    }

    Array.from(draggableElements).forEach((dragEl) => {
      if (!dragEl.classList.contains("draggable")) {
        dragEl.classList.toggle("draggable");
      }
    });
    Array.from(vOnlyDraggableElements).forEach((VODragEl) => {
      if (!VODragEl.classList.contains("draggableVOnly")) {
        VODragEl.classList.toggle("draggableVOnly");
      }
    });
    /*Array.from(resizableElements).forEach(function(resEl) {
    if (!(resEl.classList.contains("resizable"))) {
      resEl.classList.toggle("resizable");
    }
  });*/

    // second, determine what should happen when dragged
    //const position = { x: 0, y: 0 }; //TODO: migrate these position shifts to individual variables per draggable object - currently, all draggable objects share same variables which causes them to start with same delta shift when being dragged

    interact(".draggable").draggable({
      listeners: {
        start: (event) => {
          this.logger.logLocal([event.type, event.target]);
        },
        move: (event) => {
          this.VCUIShift.x += event.dx;
          this.VCUIShift.y += event.dy;

          event.target.style.transform = `translate(${this.VCUIShift.x}px, ${this.VCUIShift.y}px)`;
        },
      },
    });

    //const positionDVO = { x: 0, y: 0 };

    interact(".draggableVOnly").draggable({
      listeners: {
        start: (event) => {
          this.logger.logLocal([event.type, event.target]);
        },
        move: (event) => {
          //positionDVO.x += event.dx;
          this.sideBarShift.y += event.dy;

          event.target.style.transform = `translate(${this.sideBarShift.x}px, ${this.sideBarShift.y}px)`;
        },
      },
      lockAxis: "y",
      /*ignoreFrom: 'div.verticalContactVideoBox'*/
    });

    /*interact('.resizable').resizable({
    margin: 10,
      edges: { top: true, left: true, bottom: true, right: true },
      listeners: {
        move: function (event) {
          let { x, y } = event.target.dataset

          x = (parseFloat(x) || 0) + event.deltaRect.left
          y = (parseFloat(y) || 0) + event.deltaRect.top

          Object.assign(event.target.style, {
            width: `${event.rect.width}px`,
            height: `${event.rect.height}px`,
            transform: `translate(${x}px, ${y}px)`
          })

          Object.assign(event.target.dataset, { x, y })
        }
      },
    modifiers: [
      interact.modifiers.snap({
        targets: [ 
        { x: 350, y: 288 },
        { x: 670, y: 288 },
        { x: 1020, y: 288 },
        { x: 350, y: 545 },
        { x: 670, y: 545 },
        { x: 1020, y: 545 },
        { x: 350, y: 800 },
        { x: 670, y: 800 },
        { x: 1020, y: 800 }
      ],
        relativePoints: [
        { x: 1  , y: 0   }   // snap relative to the element's top-left,
        ]
      })
      ]
    });*/
  };

  resetVCUIPosition = () => {
    if (this.help.appIsActive()) {
      //TODO
    } else if (this.help.isWebApp()) {
      //TODO
    } else {
      this.appUI.getVCScreenHTML().getVCUI().style.transform = "initial";
      this.VCUIShift = { x: 0, y: 0 };
    }
  };

  //////////////////////////////////////////////////////////////////
  // VC button setup
  //////////////////////////////////////////////////////////////////

  buttonDecode_connectViaTalk = (peerButtonPosOnUI: number) => {
    this.busLogic.connectViaVC(
      this.appUI.getSideBarHTML().getPeerIDForVS(peerButtonPosOnUI),
      SIGNALING_PURPOSE.AUDIO_ONLY
    );
  };

  buttonDecode_raiseHand = (peerButtonPosOnUI: number) => {
    this.busLogic
      .getRaiseHand()
      .toggleHandForPeer(
        this.appUI.getSideBarHTML().getPeerIDForVS(peerButtonPosOnUI)
      );
  };

  buttonDecode_remoteMute = (
    section: BUTTON_SECTION,
    peerButtonPosOnUI: number
  ) => {
    if (section == BUTTON_SECTION.CONTACT_BENCH) {
      this.busLogic
        .getRemoteMute()
        .requestPeerToBeMuted(
          this.appUI.getSideBarHTML().getPeerIDForVS(peerButtonPosOnUI)
        );
    } else if (section == BUTTON_SECTION.VIDEO_CONF) {
      this.busLogic
        .getRemoteMute()
        .requestPeerToBeMuted(
          this.appUI.getVCScreenHTML().getPeerIDForVSVC(peerButtonPosOnUI)
        );
    }
  };

  //link all buttons on UI to their respective onClick etc. methods
  setupUIButtons = () => {
    try {
      this.appUI
        .getSideBarHTML()
        .getReloadButton()
        .addEventListener("click", () => {
          this.reloadVISAVIS();
        });
    } catch (e) {
      this.logger.logError(["Error setting up button actions: ", e]);
    }
    try {
      for (let i = 1; i <= 20; i++) {
        //this.data.getUIPeerButtonByPos(this.data.__button_section_CB,i,this.data.__button_objective_startVC).addEventListener("click", function(){buttonDecode_connectViaVC(i)});
        this.appUI
          .getUIPeerButtonByPos(
            BUTTON_SECTION.CONTACT_BENCH,
            i,
            BUTTON_OBJECTIVE.TALK
          )
          .addEventListener("click", () => {
            if (this.areAllPeersProperlyConnectedElseWarnAndSkip())
              this.buttonDecode_connectViaTalk(i);
          });
        this.appUI
          .getUIPeerButtonByPos(
            BUTTON_SECTION.CONTACT_BENCH,
            i,
            BUTTON_OBJECTIVE.TALK
          )
          .addEventListener("dblclick", () => {
            if (this.areAllPeersProperlyConnectedElseWarnAndSkip()) {
              this.uiMessenger.informUser(
                "Connectiong to user... Note that by double-clicking a colleague you automatically enter any active conversation that he or she may be a part of"
              );
              this.busLogic
                .getVCDataMgr()
                .preRegisterAuthorizationToJoinOrMergeOtherActiveVC(i);
              this.buttonDecode_connectViaTalk(i);
            }
          });
        if (this.help.appIsActive())
          this.appUI
            .getUIPeerButtonByPos(
              BUTTON_SECTION.VIDEO_CONF,
              i,
              BUTTON_OBJECTIVE.FULL_SCREEN_VC_VIDEO
            )
            .addEventListener("click", () => {
              this.buttonDecode_peerVideoFullscreenVC(i);
            });
        this.appUI
          .getUIPeerButtonByPos(
            BUTTON_SECTION.CONTACT_BENCH,
            i,
            BUTTON_OBJECTIVE.RAISE_HAND
          )
          .addEventListener("click", () => {
            if (this.areAllPeersProperlyConnectedElseWarnAndSkip())
              this.buttonDecode_raiseHand(i);
          });
        this.appUI
          .getUIPeerButtonByPos(
            BUTTON_SECTION.CONTACT_BENCH,
            i,
            BUTTON_OBJECTIVE.REMOTE_MUTE
          )
          .addEventListener("click", () => {
            if (this.areAllPeersProperlyConnectedElseWarnAndSkip())
              this.buttonDecode_remoteMute(BUTTON_SECTION.CONTACT_BENCH, i);
          });
        if (this.help.appIsActive())
          this.appUI
            .getUIPeerButtonByPos(
              BUTTON_SECTION.VIDEO_CONF,
              i,
              BUTTON_OBJECTIVE.REMOTE_MUTE
            )
            .addEventListener("click", () => {
              if (this.areAllPeersProperlyConnectedElseWarnAndSkip())
                this.buttonDecode_remoteMute(BUTTON_SECTION.VIDEO_CONF, i);
            });
      }
    } catch (e) {
      this.logger.logError([
        "Error setting up button actions (talk + fullscreen + raise hand + remote mute buttons): ",
        e,
      ]);
    }
    try {
      for (
        let i = 0;
        i < this.appUI.getSideBarHTML().getUIPauseLocalButtons().length;
        i++
      ) {
        this.appUI
          .getSideBarHTML()
          .getUIPauseLocalButtons()
          [i].addEventListener("click", this.pauseLocalVideoButtonClick);
      } //pauseCompressionButtonClick)}
      if (this.help.appIsActive())
        for (
          let i = 0;
          i < this.appUI.getVCScreenHTML().getUIPauseLocalButtons().length;
          i++
        ) {
          this.appUI
            .getVCScreenHTML()
            .getUIPauseLocalButtons()
            [i].addEventListener("click", this.pauseLocalVideoButtonClick);
        } //pauseCompressionButtonClick)}
      if (this.help.isWebApp())
        this.appUI
          .getSideBarHTML()
          .getWebClientSideBarEnhancementsHTML()
          .getInVCPauseVideoButton()
          .addEventListener("click", this.pauseLocalVideoButtonClick);
    } catch (e) {
      this.logger.logError([
        "Error setting up button actions (pause local video): ",
        e,
      ]);
    }
    try {
      if (this.help.appIsActive())
        this.appUI
          .getVCScreenHTML()
          .getUILocalClientLeaveVCButton()
          .addEventListener("click", () => {
            if (this.areAllPeersProperlyConnectedElseWarnAndSkip())
              this.busLogic.leaveVC(false);
          });
      if (this.help.appIsActive())
        this.appUI
          .getVCScreenHTML()
          .getUITopCornerLeaveVCButton()
          .addEventListener("click", () => {
            if (this.areAllPeersProperlyConnectedElseWarnAndSkip())
              this.busLogic.leaveVC(false);
          });
      if (this.help.isWebApp())
        this.appUI
          .getSideBarHTML()
          .getWebClientSideBarEnhancementsHTML()
          .getInVCLeaveButton()
          .addEventListener("click", () => {
            if (this.areAllPeersProperlyConnectedElseWarnAndSkip())
              this.busLogic.leaveVC(false);
          });
      this.appUI
        .getSideBarHTML()
        .getSideBarVCCloseVCButton()
        .addEventListener("click", () => {
          if (this.areAllPeersProperlyConnectedElseWarnAndSkip())
            this.busLogic.leaveVC(false);
        });
    } catch (e) {
      this.logger.logError(["Error setting up button actions (leaveVC): ", e]);
    }
    try {
      if (this.help.appIsActive())
        this.appUI
          .getVCScreenHTML()
          .getUILocalClientShareScreenButton()
          .addEventListener("click", () => {
            if (this.areAllPeersProperlyConnectedElseWarnAndSkip())
              this.shareLocalScreenButtonClick();
          });
      this.appUI
        .getSideBarHTML()
        .getSideBarVCResumeRemoteScreenShareButton()
        .addEventListener("click", this.resumeRemoteScreenShare);
      /*this.data
        .getUILocalClientShareScreenCloseButton()
        .addEventListener("click", this.shareLocalScreenCloseButtonClick);
      this.data
        .getUILocalClientShareScreenPauseButton()
        .addEventListener("click", this.shareLocalScreenPauseButtonClick);*/
    } catch (e) {
      this.logger.logError([
        "Error setting up button actions (share screen + resume remote scs): ",
        e,
      ]);
    }
    try {
      Array.from(this.appUI.getSideBarHTML().getUIMuteLocalButtons()).forEach(
        (but) => {
          but.addEventListener("click", () => {
            this.muteLocalMicrophoneButtonClick();
          });
        }
      );
      if (this.help.appIsActive())
        Array.from(
          this.appUI.getVCScreenHTML().getUIMuteLocalButtons()
        ).forEach((but) => {
          but.addEventListener("click", () => {
            this.muteLocalMicrophoneButtonClick();
          });
        });
      if (this.help.isWebApp())
        this.appUI
          .getSideBarHTML()
          .getWebClientSideBarEnhancementsHTML()
          .getInVCMuteButton()
          .addEventListener("click", () => {
            this.muteLocalMicrophoneButtonClick();
          });
    } catch (e) {
      this.logger.logError([
        "Error setting up button actions (mute local mic): ",
        e,
      ]);
    }
    try {
      this.appUI
        .getVCScreenShareHTML()
        .getUIPeerScreenShareFullScreenButton()
        .addEventListener("click", () => {
          this.requestFullscreenForElement(
            this.appUI
              .getVCScreenShareHTML()
              .getRemoteScreenShareHTMLVideoElementVC()
          );
        });
    } catch (e) {
      this.logger.logError([
        "Error setting up button actions (peer ScS full screen): ",
        e,
      ]);
    }
    try {
      this.appUI
        .getSideBarHTML()
        .getUISettingsButtonL()
        .addEventListener("click", this.settingsButtonClick);
      this.appUI
        .getSideBarHTML()
        .getUISettingsButtonR()
        .addEventListener("click", this.settingsButtonClick);
      this.appUI
        .getSideBarHTML()
        .getSwitchSidesButton()
        .addEventListener("click", this.switchSidesButtonClick);
      this.appUI
        .getSideBarHTML()
        .getUIZoomInButton()
        .addEventListener("click", this.zoomInButtonClick);
      this.appUI
        .getSideBarHTML()
        .getUIZoomOutButton()
        .addEventListener("click", this.zoomOutButtonClick);
    } catch (e) {
      this.logger.logError([
        "Error setting up button actions (settings, switch sides, zoom): ",
        e,
      ]);
    }
    try {
      /*      this.appUI
        .getSideBarHTML()
        .getSwitchRoomsButton()
        .addEventListener("click", this.switchRoomsButtonClick);*/
      this.appUI
        .getSideBarHTML()
        .getSettingsDialogueButton()
        .addEventListener("click", this.settingsDialogueButtonClick);
      if (this.help.isWebApp())
        this.appUI
          .getSideBarHTML()
          .getWebClientSideBarEnhancementsHTML()
          .getSettingsDialogueButton()
          .addEventListener("click", this.settingsDialogueButtonClick);
      /*      this.appUI
        .getSideBarHTML()
        .getUIChangeCameraButton()
        .addEventListener("click", this.chooseCameraButtonClick);
      this.appUI
        .getSideBarHTML()
        .getUIChangeMicrophoneButton()
        .addEventListener("click", this.chooseMicrophoneButtonClick);*/
      this.appUI
        .getSideBarHTML()
        .getUITestButton()
        .addEventListener("click", this.testFeatureClick);
    } catch (e) {
      this.logger.logError([
        "Error setting up button actions (room cam mic test): ",
        e,
      ]);
    }
    try {
      if (this.help.isWebApp())
        this.appUI
          .getSideBarHTML()
          .getWebClientSideBarEnhancementsHTML()
          .getUILogoutButton()
          .addEventListener("click", this.logoutButtonClick);
    } catch (e) {
      this.logger.logError(["Error setting up button actions (logout): ", e]);
    }
    try {
      this.appUI
        .getSideBarHTML()
        .getFeedbackButton()
        .addEventListener("click", this.feedbackButtonClick);
      this.appUI
        .getSideBarHTML()
        .getInviteTeamButton()
        .addEventListener("click", this.inviteTeamButtonClick);
      this.appUI
        .getSideBarHTML()
        .getHelpButton()
        .addEventListener("click", this.helpButtonClick);
      this.appUI
        .getSideBarHTML()
        .getDownloadWindowsButton()
        .addEventListener("click", this.downloadWindowsAppButtonClick);
      this.appUI
        .getSideBarHTML()
        .getQuitButton()
        .addEventListener("click", this.closeVISAVIS);
      this.appUI
        .getSideBarHTML()
        .getPauseVISAVISButton()
        .addEventListener("click", this.pauseVISAVIS);
    } catch (e) {
      this.logger.logError([
        "Error setting up button actions (feedback help download quit pause): ",
        e,
      ]);
    }
    if (this.help.isWebApp())
      try {
        this.appUI
          .getSideBarHTML()
          .getWebClientSideBarEnhancementsHTML()
          .getUIPauseLeaveButton()
          .addEventListener("click", this.pauseVISAVIS);
      } catch (e) {
        this.logger.logError([
          "Error setting up button actions for web client top bar buttons: ",
          e,
        ]);
      }
    try {
      this.appUI
        .getSideBarHTML()
        .getHideAppButton()
        .addEventListener("click", () => {
          this.devEnvLoader.prodEnvTriggerClick();
          this.hideAppButtonClick();
        });
    } catch (e) {
      this.logger.logError(["Error setting up button actions (hide app): ", e]);
    }
    try {
      this.appUI
        .getSideBarHTML()
        .getSideBarVCtoVCUIButton()
        .addEventListener("click", () => {
          if (this.areAllPeersProperlyConnectedElseWarnAndSkip())
            this.busLogic.switchVCToDedicatedWindow();
        });
      this.appUI
        .getSideBarHTML()
        .getSideBarVCtoSideBarButton()
        .addEventListener("click", () => {
          if (this.areAllPeersProperlyConnectedElseWarnAndSkip())
            this.busLogic.switchVCToSideBar();
        });
      if (this.help.appIsActive())
        this.appUI
          .getVCScreenHTML()
          .getUITopCornerVCToSideBarButton()
          .addEventListener("click", () => {
            if (this.areAllPeersProperlyConnectedElseWarnAndSkip())
              this.busLogic.switchVCToSideBar();
          });
    } catch (e) {
      this.logger.logError([
        "Error setting up button actions (VC to sidebar, sidebar to VC window): ",
        e,
      ]);
    }
    try {
      this.appUI // click on heading no longer working on new UI due to webkit-drag usage
        .getSideBarHTML()
        .getContactBenchTopHeadingsUI()
        .addEventListener("click", this.devEnvLoader.devEnvTriggerClick);
      this.appUI // .. thus now using local video as dev-env-trigger
        .getSideBarHTML()
        .getLocalHTMLVideoElement()
        .addEventListener("click", this.devEnvLoader.devEnvTriggerClick);
    } catch (e) {
      this.logger.logError([
        "Error setting up button actions (dev env trigger): ",
        e,
      ]);
    }
    try {
      if (this.help.appIsActive())
        this.appUI
          .getVCScreenHTML()
          .getUITopCornerMinimizeRemoteScreenShareButton()
          .addEventListener("click", this.minimizeRemoteScreenShare);
      this.appUI
        .getSideBarHTML()
        .getSideBarVCScreenShareButton()
        .addEventListener("click", () => {
          //this.busLogic.switchVCToDedicatedWindow();
          if (this.areAllPeersProperlyConnectedElseWarnAndSkip())
            this.shareLocalScreenButtonClick();
        });
    } catch (e) {
      this.logger.logError([
        "Error setting up button actions (share local screen, minimize screen share): ",
        e,
      ]);
    }
    try {
      //todo: add any other future button types

      //cause all VC videos to maximize when clicked upon
      this.appUI
        .getVCScreenShareHTML()
        .getRemoteScreenShareHTMLVideoElementVC()
        .addEventListener("dblclick", () => {
          this.requestFullscreenForElement(
            this.appUI
              .getVCScreenShareHTML()
              .getRemoteScreenShareHTMLVideoElementVC()
          );
        });
      if (this.help.appIsActive())
        for (let i = 1; i <= 20; i++) {
          this.appUI
            .getVCScreenHTML()
            .getPeerHTMLVideoElementVCOnUIByPosition(i)
            .addEventListener("dblclick", () => {
              this.requestFullscreenForElement(
                this.appUI
                  .getVCScreenHTML()
                  .getPeerHTMLVideoElementVCOnUIByPosition(i)
              );
            });
        }
    } catch (e) {
      this.logger.logError([
        "Error setting up button actions (full screen dblclick on vcvideo or screen share): ",
        e,
      ]);
    }
    try {
      this.appUI
        .getSideBarHTML()
        .getHideOwnVideoButton()
        .addEventListener("click", this.hideOwnVideoButtonClick);
    } catch (e) {
      this.logger.logError([
        "Error setting up button actions (hide own video): ",
        e,
      ]);
    }
    try {
      if (this.help.appIsActive())
        this.appUI
          .getVCScreenHTML()
          .getShowVCChatButton()
          .addEventListener("click", () => {
            this.showVCChat();
          });
      if (this.help.isWebApp()) {
        this.appUI
          .getSideBarHTML()
          .getWebClientSideBarEnhancementsHTML()
          .getInVCChatButton()
          .addEventListener("click", () => {
            this.toggleVCChat();
          });
      }

      if (this.help.appIsActive())
        this.appUI
          .getVCScreenHTML()
          .getHideVCChatButton()
          .addEventListener("click", () => {
            this.hideVCChat();
          });
    } catch (e) {
      this.logger.logError([
        "Error setting up button actions (show / hide VCChat): ",
        e,
      ]);
    }
  };

  resetUIButtons = () => {
    //reset buttons in case UI is refreshed by app, e.g. on media device change
    let newAudioMutedStatus = false;
    let newVideoPausedStatus = false;
    if (this.settings.existsAudioMutedSetting())
      newAudioMutedStatus = this.settings.getStatusAudioMuted();
    if (this.settings.existsVideoPausedSetting())
      newVideoPausedStatus = this.settings.getStatusVideoPaused();
    this.media.setLocalAudioMute(newAudioMutedStatus);
    this.media.setLocalVideoPause(newVideoPausedStatus);
    this.markAudioMutedButtonsAsPressed(newAudioMutedStatus);
    this.markVideoPausedButtonsAsPressed(newVideoPausedStatus);
    this.cssModifier.setClassOnEntireAppBasedOnBoolean(
      "localVideoPaused",
      newVideoPausedStatus
    );

    /*this.appUI
      .getSideBarHTML()
      .getLocalHTMLVideoElement()
      .addEventListener("play", () => {
        this.media.reapplyCurrentAudioMuteSetting();
        this.media.reapplyCurrentVideoPauseSetting();
      });*/

    //todo: add further buttons once those get marked active / inactive upon usage
    if (this.help.appIsActive()) this.resetVCUIButtons();
  };

  resetVCUIButtons = () => {
    this.resetAllTalkButtons();
    //this.markButtonsAsPressed([this.data.getUILocalClientShareScreenButton()],false); //should be properly set based on connection state instead

    //todo: add further buttons once those get marked active / inactive upon usage
  };

  /*function pauseCompressionButtonClick() {
      this.data.setCompressionPaused(!this.data.isCompressionPaused()); //pause if unpaused, unpause if paused
      this.logger.logLocal(['Switching pause/unpause flag for local video stream']);
    }*/

  muteLocalMicrophoneButtonClick = (toBeMuted: boolean = null) => {
    if (!this.data.isLocalMSPresent()) {
      this.uiMessenger.warningToUser(
        "audio could not be muted - visavis is not yet able to access your camera and microphone"
      );
      this.logger.logWarning([
        "user tried to mute audio prior to local mediastream being accessible",
      ]);
      return;
    }
    this.logger.logLocal([
      "Switching mute/unmute microphone flag for local stream",
    ]);
    if (toBeMuted == null) {
      toBeMuted = this.media.isLocalAudioActive(); //toggle it to opposite variant of status quo
    }
    this.media.setLocalAudioMute(toBeMuted);
    this.markAudioMutedButtonsAsPressed(toBeMuted);
    this.settings.setStatusAudioMuted(toBeMuted);
    this.busLogic
      .getRemoteMute()
      .informPeersAboutLocalMuteStateChange(toBeMuted);
    if (this.media.isLocalAudioMuted())
      this.uiMessenger.confirmToUser("Microphone muted");
    else this.uiMessenger.confirmToUser("Microphone unmuted");
  };

  isLocalAudioActive = (): boolean => {
    return this.media.isLocalAudioActive();
  };

  pauseLocalVideoButtonClick = () => {
    if (!this.data.isLocalMSPresent()) {
      this.uiMessenger.warningToUser(
        "video could not be paused - visavis is not yet able to access your camera and microphone"
      );
      this.logger.logWarning([
        "user tried to pause video prior to local mediastream being accessible",
      ]);
      return;
    }
    this.logger.logLocal([
      "Switching enable/disable camera flag for local stream",
    ]);
    let toBePaused = this.media.isLocalVideoActive();
    this.media.setLocalVideoPause(toBePaused);
    this.markVideoPausedButtonsAsPressed(toBePaused);
    this.settings.setStatusVideoPaused(toBePaused);
    this.cssModifier.setClassOnEntireAppBasedOnBoolean(
      "localVideoPaused",
      toBePaused
    );
    if (this.media.isLocalVideoPaused()) {
      /*if (this.media.isLocalAudioActive())
        this.uiMessenger.informUser("Note: Your microphone is still active");*/
      this.uiMessenger.confirmToUser(
        "Video submission paused - note that team visibility is limited while on pause"
      );
    } else {
      this.uiMessenger.confirmToUser("Video submission resumed");
    }
  };

  //alert user of unimplemented feature
  alertNotImplementedYet = () => {
    this.uiMessenger.informUser(
      "Note: This feature is yet to be fully implemented."
    );
  };

  registerActiveScreenShareDialogue = (isActive: boolean) => {
    if (this.screenShareDialogueAlreadyOpen && isActive)
      this.logger.logError([
        "2nd screen share dialogue was registered with UIHandler while previous one was still considered active",
      ]);
    this.screenShareDialogueAlreadyOpen = isActive;
  };

  //start screen share if none is active, else stop it
  shareLocalScreenButtonClick = () => {
    if (this.screenShareDialogueAlreadyOpen) {
      this.uiMessenger.warningToUser(
        "screen share dialogue already open - if you no longer want to share your screen, click 'cancel' in the dialogue"
      );
      return;
    }
    if (this.busLogic.localScreenShareIsActive()) {
      this.busLogic.endLocalScreenShare();
      this.uiTechHandler
        .getDialogueBackgroundWindow()
        .closeMaximizedScreenShareBackgroundWindow(); //this is important as background needs to remain open in hidden form as long as screen share active - screen share is initiated on background window - thus ends when the same is closed
      this.uiTechHandler.getVCWindow().refreshDedicatedVCWindowAlwaysOnTop();
      /*this.nwjsHandler
        .getVCWindowNWJS()
        .unhideDedicatedVCWindowAfterTMPHideForScSDialogueIfOnApp(false);*/
      this.uiTechHandler.getVCWindow().hideDedicatedVCWindowIfOnApp();

      //this.markButtonsAsPressed([this.data.getUILocalClientShareScreenButton()],false);
      //confirmToUser('You are no longer sharing your screen');
    } else if (this.busLogic.remoteScreenShareIsActive()) {
      this.uiMessenger.informUser(
        "Another user is already sharing a screen - please ask for existing screen share to be closed first"
      );
    } else {
      this.uiTechHandler.getSidebarWindow().refreshSideBarAlwaysOnTop();
      this.busLogic
        .startLocalScreenShare()
        .then(() => {
          //this.markButtonsAsPressed([this.data.getUILocalClientShareScreenButton()],true);
          //confirmToUser('You are now sharing your screen');
        })
        .catch((e) => {
          this.logger.logLocal([
            "Error processing screen share request: ",
            e.message,
          ]);
          /* do nothing*/
        });
    }
  };

  //close local screen share in ongoing VC
  shareLocalScreenCloseButtonClick = () => {
    this.shareLocalScreenButtonClick();
    /*logLocal(['Ending screen share']);
      this.uiMessenger.informUser('Closing screen share');
      this.data.getLocalMSScreenShare().stop;
      this.hideVCLocalScreenShareVideoSectionOnUI();*/
  };

  /*pause local screen share in ongoing VC
  shareLocalScreenPauseButtonClick = () => {
    //logLocal(['Pausing screen share']);
    //informUser('Pausing screen share');

    //pauseLocalVideoButtonClick();

    this.alertNotImplementedYet(); //TODO: actually pause any ongoing screen share - it may be an option to temporarily switch back to video transmission until unpaused
  };*/

  buttonDecode_peerVideoFullscreenVC = (peerButtonPosOnUI: number) => {
    this.requestFullscreenForElement(
      this.appUI
        .getVCScreenHTML()
        .getPeerHTMLVideoElementVC(
          this.appUI.getVCScreenHTML().getPeerIDForVSVC(peerButtonPosOnUI)
        )
    );
  };

  settingsButtonClick = () => {
    //only in dev env, enable settings button to open webRTCinternals site within chrome
    if (this.help.isDevEnv()) {
      if (this.help.appIsActive()) {
        this.uiTechHandler
          .getURLBrowserWindows()
          .openwebRTCInternalsWindow(this.webRTCInternalsURL);
      } else if (this.help.chromiumIsActive()) {
        //works only in chrome, not in other browsers
        this.openURLInNewTab(this.webRTCInternalsURL);
      }
    }
    /*informUser('Use settings buttons to switch to another camera or microphone, or to more visav.is between left and right side of the screen');*/
  };

  switchSidesButtonClick = () => {
    this.switchAppBetweenLeftAndRightSideOfScreen();
    /*informUserSticky('Now switching visav.is bar to the other side');
      setTimeout(function(){ this.switchAppBetweenLeftAndRightSideOfScreen(); }, 1000);*/
  };

  getRoomNameFromUserOnStartup = () => {
    let manualRoomInput = prompt(
      "Welcome to visav.is!\n\nTo get started, please choose a name for your team room. \n\nNote that all teammates need to join the same room in order to see each other. " +
        "You can switch rooms at any point in time by accessing the settings dialogue in the lower right corner of the app.\n\nPlease take me to team room:",
      this.signaling.getMainRoomID()
    );
    if (manualRoomInput != null) {
      this.settings.setMainRoom(manualRoomInput);
      this.settings.deleteMainRoomPass();
      this.data.registerTemporaryPermissionToDisconnectFromSignalingWithoutWarning();
      this.reloadVISAVIS("ReloadingAppAfterCapturingRoomNameFromUserOnStartup");
    } else {
      this.uiMessenger.informUser(
        "visav.is is connecting you to the default team room: " +
          this.signaling.getMainRoomID()
      );
    }
  };

  hideOwnVideoButtonClick = () => {
    this.cssModifier.toggleClassOnEntireApp("ownVideoHidden");
    this.autoZoomVideoBar();
    this.uiTechHandler
      .getSidebarWindow()
      .updateSideBarWindowSizeAndPositionIfOnApp();
  };

  zoomInButtonClick = () => {
    if (!this._manualZoom)
      this.uiMessenger.informUser(
        "Switching to manual zoom - reloading the app will reactivate automatic zooming"
      );
    this._manualZoom = true;
    let classes = this.appUI.getSideBarHTML().getContactBenchUI().classList;
    if (classes.contains("zoom100")) {
      classes.remove("zoom100");
      classes.add("zoom110");
      this.appUI.getSideBarHTML().setZoomFactor(1.1);
    } else if (classes.contains("zoom50")) {
      classes.remove("zoom50");
      classes.add("zoom60");
      this.appUI.getSideBarHTML().setZoomFactor(0.6);
    } else if (classes.contains("zoom60")) {
      classes.remove("zoom60");
      classes.add("zoom70");
      this.appUI.getSideBarHTML().setZoomFactor(0.7);
    } else if (classes.contains("zoom70")) {
      classes.remove("zoom70");
      classes.add("zoom80");
      this.appUI.getSideBarHTML().setZoomFactor(0.8);
    } else if (classes.contains("zoom80")) {
      classes.remove("zoom80");
      classes.add("zoom90");
      this.appUI.getSideBarHTML().setZoomFactor(0.9);
    } else if (classes.contains("zoom90")) {
      classes.remove("zoom90");
      classes.add("zoom100");
      this.appUI.getSideBarHTML().setZoomFactor(1);
    } else if (classes.contains("zoom110")) {
      classes.remove("zoom110");
      classes.add("zoom120");
      this.appUI.getSideBarHTML().setZoomFactor(1.2);
    } else if (classes.contains("zoom120")) {
      classes.remove("zoom120");
      classes.add("zoom130");
      this.appUI.getSideBarHTML().setZoomFactor(1.3);
    } else if (classes.contains("zoom130")) {
      this.uiMessenger.informUser(
        "max zoom level reached - cannot zoom in further"
      );
    }
    this.uiTechHandler
      .getSidebarWindow()
      .updateSideBarWindowSizeAndPositionIfOnApp();
  };

  zoomOutButtonClick = () => {
    if (!this._manualZoom)
      this.uiMessenger.informUser(
        "Switching to manual zoom - reloading the app will reactivate automatic zooming"
      );
    this._manualZoom = true;
    let classes = this.appUI.getSideBarHTML().getContactBenchUI().classList;
    if (classes.contains("zoom100")) {
      classes.remove("zoom100");
      classes.add("zoom90");
      this.appUI.getSideBarHTML().setZoomFactor(0.9);
    } else if (classes.contains("zoom50")) {
      this.uiMessenger.informUser(
        "min zoom level reached - cannot zoom out further"
      );
    } else if (classes.contains("zoom60")) {
      classes.remove("zoom60");
      classes.add("zoom50");
      this.appUI.getSideBarHTML().setZoomFactor(0.5);
    } else if (classes.contains("zoom70")) {
      classes.remove("zoom70");
      classes.add("zoom60");
      this.appUI.getSideBarHTML().setZoomFactor(0.6);
    } else if (classes.contains("zoom80")) {
      classes.remove("zoom80");
      classes.add("zoom70");
      this.appUI.getSideBarHTML().setZoomFactor(0.7);
    } else if (classes.contains("zoom90")) {
      classes.remove("zoom90");
      classes.add("zoom80");
      this.appUI.getSideBarHTML().setZoomFactor(0.8);
    } else if (classes.contains("zoom110")) {
      classes.remove("zoom110");
      classes.add("zoom100");
      this.appUI.getSideBarHTML().setZoomFactor(1);
    } else if (classes.contains("zoom120")) {
      classes.remove("zoom120");
      classes.add("zoom110");
      this.appUI.getSideBarHTML().setZoomFactor(1.1);
    } else if (classes.contains("zoom130")) {
      classes.remove("zoom130");
      classes.add("zoom120");
      this.appUI.getSideBarHTML().setZoomFactor(1.2);
    }
    this.uiTechHandler
      .getSidebarWindow()
      .updateSideBarWindowSizeAndPositionIfOnApp();
  };

  helpButtonClick = () => {
    if (this.help.appIsActive()) {
      //this.nwjsHandler.openURLInNewBrowserWindow(this.supportFormURL);
      this.uiTechHandler
        .getURLBrowserWindows()
        .openSupportFormWindow(this.supportFormURL);
    } else {
      this.openURLInNewTab(this.supportFormURL);
      /*this.uiMessenger.informUserSticky(
        "Need help? Please drop us a line to support" +
        String("^visa#is").replace("^", "@").replace("#", "v.") +
        " - we're here to help you along the way"
      );
      this.uiMessenger.informUser(
        "Attempting to launch your local email client (if any installed)"
      );
      let lnk =
        String("mai#:support^visav.is").replace("^", "@").replace("#", "lto") +
        String("?subject=need some help around visav.is").replaceAll(" ", "%20") +
        String(
          "&body=Hello visav.is,%0d%0dI'm trying to ... - Would you mind giving me a quick hint re how I can do this?%0d%0dRegards ..."
        ).replaceAll(" ", "%20");
      setTimeout(() => {
        this.openEmailDialogue(lnk);
      }, 3500);*/
    }
  };

  feedbackButtonClick = () => {
    if (this.help.appIsActive()) {
      //this.nwjsHandler.openURLInNewBrowserWindow(this.feedbackFormURL);
      this.uiTechHandler
        .getURLBrowserWindows()
        .openFeedbackFormWindow(this.feedbackFormURL);
    } else {
      this.openURLInNewTab(this.feedbackFormURL);
      /*this.uiMessenger.informUserSticky(
        "We'd love to hear your thoughts and ideas - please send us an email to feedback" +
          String("^visa#is").replace("^", "@").replace("#", "v.")
      );
      this.uiMessenger.informUser(
        "Attempting to launch your local email client (if any installed)"
      );
      let lnk =
        String("mai#:feedback^visav.is").replace("^", "@").replace("#", "lto") +
        String("?subject=my visav.is experience / improvement idea").replaceAll(
          " ",
          "%20"
        ) +
        String(
          "&body=Hello visav.is,%0d%0dwhen using your platform, the following thoughts / ideas crossed my mind ...%0d%0dRegards ..."
        ).replaceAll(" ", "%20");
      setTimeout(() => {
        this.openEmailDialogue(lnk);
      }, 3500);*/
    }
  };

  inviteTeamButtonClick = () => {
    let urlParameters: string = "?rand=" + Math.random() * 10000;
    if (this.settings.existsMainRoomSetting())
      urlParameters =
        urlParameters + "&roomname=" + this.settings.getMainRoom();
    if (this.settings.existsUserEmail())
      urlParameters =
        urlParameters + "&senderEmail=" + this.settings.getUserEmail();
    if (this.settings.existsUserUILabel())
      urlParameters =
        urlParameters + "&senderName=" + this.settings.getUserUILabel();
    if (urlParameters == "?") urlParameters = "";
    if (this.help.appIsActive()) {
      //this.nwjsHandler.openURLInNewBrowserWindow(this.feedbackFormURL);
      this.uiTechHandler
        .getURLBrowserWindows()
        .openFeedbackFormWindow(this.inviteTeamURL + urlParameters);
    } else {
      this.openURLInNewTab(this.inviteTeamURL + urlParameters);
    }
  };

  openEmailDialogue = (mailtoLink: string) => {
    let mail = document.createElement("a");
    mail.href = mailtoLink;
    mail.target = "hidden-mailto-iframe";
    mail.click();
  };

  openURLInNewTab = (targetURL: string) => {
    window.open(targetURL, "_system"); //should use system default browser, but seeps to open only new tab in current browser
  };

  reloadVISAVIS = (
    reloadTrigger: string = "ReloadingVisavisAsUserClickedReloadButton",
    customUIMessageToAnnounceReload: string = "now reloading the visav.is app - we'll be back in a few seconds",
    customReloadTimingMessageDurationWithReload500MSLater: number = 1500
  ) => {
    if (!this.help.isDeviceConnectedToTheInternet()) {
      this.uiMessenger.informUserSticky(
        "app reload was interrupted as device appears to be disconnected from the internet - to apply any changes, please reload the app manually, once your internet connection has been reestablished"
      );
      return;
    }
    this.uiMessenger.informUserSticky(
      customUIMessageToAnnounceReload,
      customReloadTimingMessageDurationWithReload500MSLater
    );
    this.busLogic.fullLocalHangupOnExit(reloadTrigger);
    this.hideAllPeerVideoSectionsOnUI();
    //hide UI
    setTimeout(() => {
      this.uiTechHandler.closeAllWindowsExceptSideBar();
    }, customReloadTimingMessageDurationWithReload500MSLater + 200);
    setTimeout(() => {
      this.devEnvLoader.reloadCurrentEnvWithParameters();
    }, customReloadTimingMessageDurationWithReload500MSLater + 500);
    //window.location.replace(window.location.href + '&v=' + Math.floor(Math.random() * 10000)); //throws error when used locally / not tried yet via webserver
  };

  downloadWindowsAppButtonClick = () => {
    this.uiMessenger.confirmToUserSticky("opening visav.is download site...");
    let lnk = this.downloadsURL;
    setTimeout(() => {
      this.openURLInNewTab(lnk);
    }, 3000);
  };

  pauseVISAVIS = () => {
    if (this.cssModifier.containsClassOnEntireApp("VISAVISPaused")) {
      this.cssModifier.removeClassOnEntireApp("VISAVISPaused");
      //reloadButtonClick();
      //re-launch media access and connectivity with updated constraints
      this.uiMessenger.informUserSticky(
        "... reconnecting you to your team - please ensure camera is not in use / reload if needed"
      );
      this.busLogic.launchMediaAccessAndServerConnectivity();
    } else {
      if (this.help.isMacApp() || this.help.isWebApp()) {
        this.uiMessenger.informUserSticky(
          "... now pausing visav.is - click the button again to continue interacting with your team at a later stage"
        );
      } else {
        this.uiMessenger.informUserSticky(
          "... now pausing visav.is, allowing other apps to access camera and microphone - click pause button again to continue interacting with your team"
        );
      }
      this.cssModifier.addClassOnEntireApp("VISAVISPaused");
      //first leave any active conversations
      //if (this.busLogic.getVCDataMgr().isVCOpen()) {
      this.busLogic.leaveVC(false, false, true, false); //check whether vc open happens in leaveVC function
      //}
      //then stop all active tracks of relevant media streams
      this.data.retireMSByStoppingAllTracks(this.data.getLocalMS());
      /*if (this.data.getLocalMS() != null) {
        for (let track of this.data.getLocalMS().getTracks()) {
          track.stop();
          //track.enabled=false;
        }
      }*/
      this.busLogic.fullLocalHangupOnExit("PausingVisavis");
      this.hideAllPeerVideoSectionsOnUI();
      this.hideLocalVideoSectionOnUI();
      this.resetAllRaiseHandButtons();
      this.resetAllRemoteMuteButtons();
      this.signaling.resetReadinessIndicators(true);
    }
  };

  closeVISAVIS = () => {
    let exit = confirm(
      "Do you really want to close visavis? \n\nWe suggest putting the app on pause instead as this allows you to reconnect with your team at the click of a button.\n\nConfirm with OK to close the app.\nSelect Cancel to pause visavis instead."
    );
    if (exit) {
      this.signaling.informServerAboutIntentToDisconnect(
        "UserClickedTopCornerQuitButton"
      );
      if (this.help.appIsActive()) {
        //@ts-ignore
        nw.App.closeAllWindows();
      }
    } else {
      this.pauseVISAVIS();
    }
    /*    this.uiMessenger.informUserSticky(
      "To quit the visav.is app, please click button twice within 5 seconds - see you soon!"
    );
    window.close();*/
  };

  hideAppButtonClick = () => {
    this.hideApp();
  };

  showVCChat = (): void => {
    this.cssModifier.addClassOnEntireApp("VCChatIsActive");
    this.busLogic.getVCChat().registerManualShowChat();
    this.updateVCWindowSizeBasedOnVCParticipantsAndChat();
    setTimeout(() => {
      this.cssModifier.removeClassOnEntireApp("NewChatMessages");
    }, 500);
    this.getAppUIHTML().getVCChatHTML().getChatSectionInputFieldHTML().focus();
    if (this.help.isWebApp())
      this.markButtonsAsPressed(
        [
          this.appUI
            .getSideBarHTML()
            .getWebClientSideBarEnhancementsHTML()
            .getInVCChatButton(),
        ],
        true,
        "pressed"
      );
  };

  hideVCChat = (): void => {
    this.cssModifier.removeClassOnEntireApp("VCChatIsActive");
    this.busLogic.getVCChat().registerManualHideChat();
    this.updateVCWindowSizeBasedOnVCParticipantsAndChat();
    if (this.help.isWebApp())
      this.markButtonsAsPressed(
        [
          this.appUI
            .getSideBarHTML()
            .getWebClientSideBarEnhancementsHTML()
            .getInVCChatButton(),
        ],
        false,
        "pressed"
      );
  };

  isVCChatActive = (): boolean => {
    return this.cssModifier.containsClassOnEntireApp("VCChatIsActive");
  };

  toggleVCChat = (): void => {
    if (this.isVCChatActive()) this.hideVCChat();
    else this.showVCChat();
    if (this.help.isWebApp() && this.isVCChatActive())
      this.uiMessenger.informUser(
        "Opening chat - lowering video size while chat is displayed."
      );
  };

  updateVCWindowSizeBasedOnVCParticipantsAndChat = (
    resetWindowPosition: boolean = false
  ) => {
    this.getUITechHandler()
      .getVCWindow()
      .setVCWindowSizeForNumberOfParticipantsIfOnApp(
        this.busLogic.getVCDataMgr().getVCPeers().length + 1,
        this.isVCChatActive(),
        resetWindowPosition
      );
  };

  indicateNewVCChatMessageToUser = (): void => {
    //if (!this.cssModifier.containsClassOnEntireApp("VCChatIsActive"))
    this.cssModifier.addClassOnEntireApp("NewChatMessages");
  };

  refreshUIToStayAlive = (): void => {
    if (!this.help.appIsActive()) return;
    let fullscreenBackgroundActive = this.getUITechHandler()
      .getDialogueBackgroundWindow()
      .isMaximizedScreenShareBackgroundWindowOpen();
    if (!fullscreenBackgroundActive) {
      this.logger.logLocal([
        "performing sidebar refresh as part of stay-alive activities",
      ]);
      this.getUITechHandler().getSidebarWindow().refreshSideBarAlwaysOnTop();
      //this.getUINWJSHandler().getSidebarWindowNWJS().focusMainApp();
    }
  };

  areAllPeersProperlyConnectedElseWarnAndSkip = (): boolean => {
    if (!this.signaling.getServerConnector().isClientRegisteredWithServer()) {
      this.uiMessenger.warningToUserSticky(
        "Your request could not been performed due to a network disruption. Please wait the connection to recover and try again.",
        6000
      );
      this.logger.logWarning([
        "User request on UI blocked due to local server disconnect",
      ]);
      return false;
    } else if (this.disconnectMgr.isAtLeastOnePeerFrozen()) {
      this.uiMessenger.warningToUserSticky(
        "Your request has not been sent as one of your teammates was just (re)connecting to you. Please try again in 1-2 seconds.",
        6000
      );
      this.logger.logWarning([
        "User request on UI blocked due to frozen peer video",
      ]);
      return false;
    } else {
      return true;
    }
  };

  settingsDialogueButtonClick = () => {
    //this.getAppUIHTML().getConfHTML().openConfWindow();
    this.getAppUIHTML().getConfHTML().toggleConfWindow();
  };

  logoutButtonClick = () => {
    this.settings.deleteUserUILabel();
    this.settings.deleteMainRoomSetting();
    this.settings.deleteMainRoomPass();
    this.settings.deleteWebClientAutoLoginFlag();
    let newURL =
      "./ui/webgo-login.html?rand=" + Math.ceil(Math.random() * 10000);
    window.open(newURL, "_self");
  };

  testFeatureClick = () => {
    let test: Tester = new Tester(
      this.help,
      this.data,
      this.uiMessenger,
      this.media,
      this.busLogic,
      this.uiTechHandler,
      this
    );
    test.testFeatureClick();
  };
}
