/* COPYRIGHT 2021 Michael Maur - any form of reuse requires written consent by the author */
import { DataMgmt } from "./data/DataMgmt";
import { UIHandler } from "./frontend/UIHandler";
import { Config } from "./config/Config";
import { SoundEffects } from "./media/SoundEffects";
import { BrowserMediaHandler } from "./media/BrowserMediaHandler";
import { BusLogicVC } from "./buslogic/BusLogicVC";
import { SignalingVC } from "./signaling/SignalingVC";
import { SettingStorage } from "./data/SettingStorage";
import { Logger } from "./utils/Logger";
import { UIMessenger } from "./utils/UIMessenger";
import { Helpers } from "./utils/Helpers";
import { UIMessengerCustom } from "./utils/UIMessengerCustom";
import { AppContextHandler } from "./infra/AppContextHandler";
import { ConfigHandler } from "./configMgmt/ConfigHandler";
import { APP_CONTEXT } from "data/enums/APP_CONTEXT";
import { UIMessengerNotifyJS } from "./utils/UIMessengerNotifyJS";
import { MobileWakeLock } from "./infra/MobileWakeLock";
import { RoomDataManager } from "./data/RoomDataManager";

export class App {
  private help: Helpers;
  private context: AppContextHandler;
  private config: Config;
  private settings: SettingStorage;
  private logger: Logger;
  private uiMessenger: UIMessenger;
  private data: DataMgmt;
  private rooms: RoomDataManager;
  private sound: SoundEffects;
  private media: BrowserMediaHandler;
  private signaling: SignalingVC;
  private uiHandler: UIHandler;
  private busLogic: BusLogicVC;
  private confHandler: ConfigHandler;
  private wakeLocker: MobileWakeLock;

  private cancelAppLoadDueToBrowserUsage: boolean;

  constructor() {
    console.log("Local client loading main module: App");

    this.help = new Helpers();

    //if app code accessed directly from browser, forward to homepate instead of loading app
    this.context = new AppContextHandler(this.help);
    this.cancelAppLoadDueToBrowserUsage =
      this.context.forwardToHomepageIfAppAccessedInadequatelyViaBrowser(); //initiate forwarding and allow other execution threads to stop based on cancel-flat
    this.context.warnIfOnIncompatibleOSVersion(); //Win7 warning

    if (!this.cancelAppLoadDueToBrowserUsage) {
      this.settings = new SettingStorage(this.help);
      this.logger = new Logger(this.help, this.settings);
      this.config = new Config(this.help, this.logger);
      if (this.help.appIsActive())
        this.uiMessenger = new UIMessengerCustom(this.config, this.logger);
      //UIMessengerNative(); //UIMessengerNotifyJS();
      else this.uiMessenger = new UIMessengerNotifyJS();
      this.data = new DataMgmt(this.logger);
      this.rooms = new RoomDataManager(
        this.help,
        this.context,
        this.settings,
        this.logger,
        this.config
      );
      this.signaling = new SignalingVC(
        this.help,
        this.context,
        this.config,
        this.settings,
        this.logger,
        this.uiMessenger,
        this.data,
        this.rooms
      );
      this.uiHandler = new UIHandler(
        this.help,
        this.context,
        this.config,
        this.settings,
        this.logger,
        this.uiMessenger,
        this.data,
        this.signaling
      );
      this.sound = new SoundEffects(
        this.help,
        this.logger,
        this.uiHandler.getAppUIHTML()
      );
      this.wakeLocker = new MobileWakeLock();
      this.busLogic = new BusLogicVC(
        this.help,
        this.context,
        this.config,
        this.settings,
        this.logger,
        this.uiMessenger,
        this.data,
        this.signaling,
        this.sound,
        this.uiHandler,
        this.wakeLocker
      );
      this.media = new BrowserMediaHandler(
        this.config,
        this.help,
        this.settings,
        this.logger,
        this.uiMessenger,
        this.data,
        this.signaling,
        this.uiHandler
      );
      this.confHandler = new ConfigHandler(
        this.help,
        this.context,
        this.logger,
        this.settings,
        this.uiMessenger,
        this.media,
        this.uiHandler
      );
    }
  }

  startup = () => {
    // Bootstraping - this is the starting point for the app execution post creating all instances
    if (!this.cancelAppLoadDueToBrowserUsage)
      this.initialize().then(this.busLogic.launchApp);
  };

  initialize = () => {
    return new Promise((resolve, reject) => {
      this.help
        .initialize(this.config)
        .catch((e) => {
          this.logger.logError(["Error initializing helpers object, ", e]);
        })
        .then(this.settings.initialize)
        .then(this.logger.initialize)
        .catch((e) => {
          this.logger.logError([
            "Error initializing either settings storage or logger object, ",
            e,
          ]);
        })
        .then(this.config.initialize)
        .catch((e) => {
          this.logger.logError(["Error initializing config object, ", e]);
        })
        .then(this.uiMessenger.initialize)
        .catch((e) => {
          this.logger.logError(["Error initializing UI Messaging object, ", e]);
        })
        .then(() => {
          this.data.initialize();
        })
        .catch((e) => {
          this.logger.logError(["Error initializing data mgmt object, ", e]);
        })
        .then(() => {
          this.rooms.initialize();
        })
        .catch((e) => {
          this.logger.logError(["Error initializing room mgmt object, ", e]);
        })
        .then(() => {
          this.signaling.initialize(this.busLogic, this.uiHandler);
        })
        .catch((e) => {
          this.logger.logError(["Error initializing signaling object, ", e]);
        })
        .then(() => this.uiHandler.initialize(this.busLogic, this.media))
        .catch((e) => {
          this.logger.logError(["Error initializing uiHandler object, ", e]);
        })
        .then(() => {
          this.sound.initialize(this.logger);
        })
        .catch((e) => {
          this.logger.logError(["Error initializing sound object, ", e]);
        })
        .then(() => {
          this.wakeLocker.initialize();
        })
        .catch((e) => {
          this.logger.logError(["Error initializing mobile wake locker, ", e]);
        })
        .then(() => {
          this.busLogic.initialize(this.media);
        })
        .catch((e) => {
          this.logger.logError(["Error initializing busLogic object, ", e]);
        })
        .then(() => {
          this.media.initialize(this.busLogic);
        })
        .catch((e) => {
          this.logger.logError(["Error initializing media object, ", e]);
        })
        .then(() => {
          this.confHandler.initialize();
        })
        .catch((e) => {
          this.logger.logError(["Error initializing confHandler object, ", e]);
        })
        .then(() => {
          this.initializationClosure();
        })
        .catch((e) => {
          this.logger.logError(["Error initializing closure procedure, ", e]);
        })
        .then(() => {
          resolve(true);
        })
        .catch((e) => {
          this.logger.logError(["Error initializing app, ", e]);
        });
      this.logger.logLocal(["Initialization of main app components completed"]);
    });
  };

  initializationClosure = () => {
    this.uiHandler.initializationClosure();
  };
}
