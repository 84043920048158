export class QueueOwnerRegistrationConfirmationByServerMessage {
  private queueName: string;
  private hashedQueueID: string;

  constructor(_queueName: string, _hashedQueueID: string) {
    this.queueName = _queueName;
    this.hashedQueueID = _hashedQueueID;
  }

  initialize = (): void => {};

  getHashedQueueID = (): string => this.hashedQueueID;

  getQueueName = (): string => this.queueName;
}
