export class QueueOwnerRegistrationMessage {
  private queueIDClearText: string;
  private userLabel: string;

  constructor(_queueIDClear: string, _userLabel: string) {
    this.queueIDClearText = _queueIDClear;
    this.userLabel = _userLabel;
  }

  initialize = (): void => {};

  getQueueIDClearText = (): string => this.queueIDClearText;

  getUserLabel = (): string => this.userLabel;
}
