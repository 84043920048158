export class QueueVisitorPassToRoomMessage {
  private queueHashID: string;

  private roomID: string;

  private pass: string;

  constructor(_queueIDHash: string, _roomNameHash: string, _pass: string) {
    this.queueHashID = _queueIDHash;
    this.roomID = _roomNameHash;
    this.pass = _pass;
  }

  initialize = (): void => {};

  getQueueIDHash = (): string => this.queueHashID;

  getRoomID = (): string => this.roomID;

  getPass = (): string => this.pass;
}
